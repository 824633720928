#root .page_header .mainmenu li:before {
    left: 3px !important;

}

#root .page_header .mainmenu li:hover,
#root .page_header .mainmenu a.active,
#root .page_header .mainmenu a:hover {
    background-color: #4bb0a9 ;
}


