footer.page_footer {
    background-color: #91d0cc !important;
    color: white !important;
}

footer.page_footer li a {
    color: white !important;
}

footer.page_footer li a:hover {
    text-decoration: underline !important;
}
