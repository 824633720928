@charset "UTF-8";

@import url("https://fonts.googleapis.com/css?family=Muli:300,700|Playfair+Display:400,700");
/**
*** Table Of Contents
*
* Bootstrap Overrides
  - columns
  - container
  - breadcrumbs
  - pagination
  - forms
  - carousel
  - accordion
  - progressbar
  - navs and tabs
  - tables
  - etc.
* General Styles for HTML tags
* Styled Lists
* Buttons
* JQuery Plugins
  - Owl Carousel
  - FlexSlider
  - prettyPhoto
  - Easy pieChart
  - Time Table
  - toTop
  - Countdown
* Vertical Margins and Paddings
* Common Sections Styles
  - parallax
  - page preloader
  - animated elements
  - boxed layout
* Helper Utilites
  - layout
  - borders, colors and backgrouns
  - dividers, margins and paddings
  - alignment
  - media items
* Header
* Side Header
  - side header layout
  - side menu styles
  - sidebar scroll plugin
* Light Section - .ls
* Grey Section - .ls.ms
* Dark Sections - .ds
* Color Section - .cs
* Template Specific Styles
  - topline styles
  - toplogo styles
  - title section (breadcrumbs) styles
  - template parallax sections
  - footer styles
  - copyrights styles
  - google map
  - intro slider inside content
* Intro Section Styles
* Main Menu
  - Desktop menu
  - Mobile menu
  - Mobile menu toggler
  - Mega Menu
* Subpages
  - 404
  - about
  - blog
  - single post
  - gallery
  - post formats
* Widgets
  - common widgets styles
  - search_mailchimp
  - nav_recent_categories
  - tags
  - popular
  - recent_posts
  - calendar
  - banner
  - slider
  - flickr
  - instagram
  - twitter
  - post_tabs
  - rss
  - access_press
* Shortcodes
  - teasers
  - testimonials
  - thumbnail
  - price tables
  - contact forms
  - partners_carousel
* Social Icons
  - layout
  - corporate colors
*/
/*
** Template colors
*

Accent Colors:
1: #91d0cc
2: #d9be93
3: #e7003e
4: #f4a115

Light section (.ls) colors:
Text color in light section: #787878
Background color for light section and font color in dark section: #ffffff
Color for headings in light section and for background in dark section (.ds): #444444
Background color for light muted section (grey - .ls.ms): #f2f2f2
Background color for dark muted section (.ds.ms): #1a1a1a
Border colors for elements in light section (and .with_border elements): #ebebeb, rgba(0, 0, 0, 0.1)
Background color for elements in light section (.with_background): #f5f5f5

Dark section (.ds) colors:
Text color in dark section: #b4b4b4
Border colors for elements in dark section: rgba(255, 255, 255, 0.15)
Background color for elements in dark section (.with_background): rgba(255, 255, 255, 0.06)

Color section (.cs) colors:
Border colors for elements in color section: rgba(255, 255, 255, 0.3)

*/
/*
** Bootstrap Overrides
*/
/* columns with 20 and 40 percents width */
.col-xs-20,
.col-sm-20,
.col-md-20,
.col-lg-20 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-xs-20 {
	width: 20%;
	float: left;
}

@media (min-width: 768px) {
	.col-sm-20 {
		width: 20%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-20 {
		width: 20%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-20 {
		width: 20%;
		float: left;
	}
}

.col-xs-40,
.col-sm-40,
.col-md-40,
.col-lg-40 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-xs-40 {
	width: 40%;
	float: left;
}

@media (min-width: 768px) {
	.col-sm-40 {
		width: 40%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-40 {
		width: 40%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-40 {
		width: 40%;
		float: left;
	}
}

/* half on .container in .container-fluid */
.container-left-half,
.container-right-half {
	padding-right: 15px;
	padding-left: 15px;
}

.container-left-half {
	margin-right: 0;
	margin-left: auto;
}

.container-right-half {
	margin-right: auto;
	margin-left: 0;
}

@media (min-width: 768px) {
	.container-left-half,
  .container-right-half {
		width: 375px;
	}
}

@media (min-width: 992px) {
	.container-left-half,
  .container-right-half {
		width: 485px;
	}
}

@media (min-width: 1200px) {
	.container-left-half,
  .container-right-half {
		width: 585px;
	}
}

/* bootstrap breadcrumbs*/
.breadcrumb {
	margin: 0;
	padding: 0;
	background-color: transparent;
	border-radius: 0;
	font-family: 'Playfair Display', serif;
	color: inherit;
	font-weight: 700;
	font-size: 18px;
}

.breadcrumb > .active {
	color: inherit;
}

.breadcrumb > li + li:before {
	padding: 0;
	content: "";
}

/* pagination */
.pagination {
	margin: 0;
}

.pagination > li {
	display: inline-block;
}

.pagination > li > a,
.pagination > li > span {
	text-align: center;
	padding: 11px 0 12px;
	border: none;
	color: #787878;
	background-color: transparent;
	border: none;
	min-width: 40px;
	border-radius: 1px;
	font-size: 14px;
	font-weight: 700;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border-radius: 0;
	padding-top: 10px;
	padding-bottom: 11px;
}

.pagination > li:first-child > a i,
.pagination > li:first-child > span i,
.pagination > li:last-child > a i,
.pagination > li:last-child > span i {
	font-size: 20px;
}

@media (min-width: 992px) {
	.pagination > li:first-child > a,
  .pagination > li:first-child > span {
		margin-right: 20px;
	}

	.pagination > li:last-child > a,
  .pagination > li:last-child > span {
		margin-left: 20px;
	}
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	background-color: transparent;
	color: #91d0cc;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: transparent;
	color: #91d0cc;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
	background-color: transparent;
	opacity: 0.5;
}

/* forms */
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
textarea,
select,
.form-control {
	height: 42px;
	border-color: rgba(0, 0, 0, 0.1);
	background-color: transparent;
	border-radius: 0;
	border-width: 0 0 1px 0;
	color: #1a1a1a;
	padding-left: 0;
	padding-right: 0;
	padding-top: 9px;
	padding-bottom: 9px;
	font-size: 16px;
}

textarea,
textarea.form-control {
	height: auto;
}

.form-group {
	position: relative;
}

/* select styling */
.select-group {
	position: relative;
	border-radius: 10px;
	padding: 0;
}

.select-group .input-group {
	display: block;
	position: relative;
	background-color: transparent !important;
}

.select-group .input-group .form-control {
	float: none;
	display: block;
}

.select-group .input-group:after {
	content: "\f107";
	font-family: FontAwesome;
	position: absolute;
	top: 7px;
	right: 15px;
	font-size: 18px;
	color: #91d0cc;
}

.select-group .input-group:hover:after {
	opacity: 0.5;
}

.select-group select {
	position: relative;
	width: 100%;
	cursor: pointer;
	z-index: 1;
	padding-right: 30px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent !important;
}

.select-group select::-ms-expand {
	display: none;
}

.select-group .empty {
	color: #b4b4b4;
}

.select-group option {
	font-weight: 300;
}

.select-group option[data-default] {
	color: #b4b4b4;
}

.form-control option {
	color: #444444;
	background-color: #ffffff;
}

.form-control-unstyled {
	border: none;
	background-color: transparent;
}

.form-control, .form-control:focus {
	box-shadow: none;
}

.form-control.invalid {
	box-shadow: 0 0 0 2px #91d0cc;
}

.form-control:focus {
	border-color: #91d0cc;
}

.form-control:-moz-placeholder {
	color: #b4b4b4;
}

.form-control::-moz-placeholder {
	color: #b4b4b4;
}

.form-control:-ms-input-placeholder {
	color: #b4b4b4;
}

.form-control::-webkit-input-placeholder {
	color: #b4b4b4;
}

.form-control:focus:-moz-placeholder {
	color: transparent;
}

.form-control:focus::-moz-placeholder {
	color: transparent;
}

.form-control:focus:-ms-input-placeholder {
	color: transparent;
}

.form-control:focus::-webkit-input-placeholder {
	color: transparent;
}

/*icons for forms */
form label + [class*="icon2-"],
form label + [class*="fa-"] {
	position: absolute;
	font-size: 16px;
	left: 15px;
	top: 13px;
	width: 1em;
	text-align: center;
}

form label + [class*="icon2-"] + .form-control,
form label + [class*="fa-"] + .form-control {
	padding-left: 40px;
}

label {
	font-weight: inherit;
}

.checkbox label,
.radio label {
	font-weight: inherit;
}

.checkbox label[for],
.radio label[for] {
	font-weight: inherit;
}

.form-control + [class*="icon2-"] {
	position: absolute;
	font-size: 24px;
	left: 20px;
	top: 50%;
	color: #91d0cc;
}

.checkbox label[for] + input[type=checkbox],
.checkbox label[for] + input[type=radio],
.radio label[for] + input[type=checkbox],
.radio label[for] + input[type=radio] {
	left: 20px;
}

input[type=checkbox],
input[type=radio] {
	margin-top: 5px;
}

.form-wrapper.with_background, .form-wrapper.muted_background, .form-wrapper.cs, .form-wrapper.ds, .form-wrapper.ls {
	padding: 35px;
}

.form-inline-button .form-inline {
	position: relative;
}

.form-inline-button .form-inline .form-control {
	width: 100%;
	margin: 0;
}

.form-inline-button .form-inline label {
	display: block;
}

.form-inline-button .form-inline label[for] {
	display: none;
}

.form-inline-button .form-inline .form-group {
	display: block;
	vertical-align: top;
	position: relative;
}

@media (min-width: 768px) {
	.form-inline-button .form-inline .form-group.inline-block {
		min-width: 370px;
		margin-right: 5px;
	}
}

.form-inline-button .form-inline [type="text"],
.form-inline-button .form-inline [type="password"],
.form-inline-button .form-inline [type="search"],
.form-inline-button .form-inline [type="email"] {
	padding-right: 50px;
	width: 100%;
}

.form-inline-button .form-inline [type="submit"],
.form-inline-button .form-inline .theme_button {
	font-size: 0;
	padding-left: 22px;
	padding-right: 22px;
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;
	border: none;
	background-color: #91d0cc;
	color: #ffffff;
}

.form-inline-button .form-inline [type="submit"] i,
.form-inline-button .form-inline .theme_button i {
	font-size: 16px;
	content: "\e656";
	font-family: 'rt-icons-2';
	line-height: 1;
	top: 0;
}

.form-inline-button .form-inline [type="submit"]:hover,
.form-inline-button .form-inline .theme_button:hover {
	opacity: 0.5;
}

.has-error .form-control {
	border-color: #91d0cc;
}

.has-error label,
.has-error .help-block {
	color: #91d0cc;
}

/*styled checkbox */
.checkbox input[type="checkbox"] {
	display: none;
}

.checkbox label + input[type="checkbox"],
.checkbox label > input[type="checkbox"] {
	display: inline-block;
}

input[type="checkbox"] + label {
	padding-left: 0;
}

input[type="checkbox"] + label > a {
	color: #444444;
}

input[type="checkbox"] + label:before {
	content: "";
	display: inline-block;
	vertical-align: middle;
	margin: 1px 10px 4px 0;
	text-align: center;
	padding-right: 0.1em;
	width: 16px;
	height: 16px;
	line-height: 14px;
	font-size: 15px;
	border: 2px solid #ebebeb;
	font-family: "FontAwesome";
}

input[type="checkbox"]:checked + label:before {
	content: "\f00c";
	color: #444444;
}

input[type="checkbox"]:disabled + label:before {
	background-color: #ebebeb;
}

/*styled radio*/
.radio input[type="radio"] {
	display: none;
}

.radio label + input[type="radio"],
.radio label > input[type="radio"] {
	display: inline-block;
}

input[type="radio"] + label {
	padding-left: 0;
}

input[type="radio"] + label > a {
	color: #999999;
}

input[type="radio"] + label:before {
	content: "";
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	margin-bottom: 2px;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 2px solid #ebebeb;
}

input[type="radio"]:checked + label:before {
	padding: 3px;
	background-color: #444444;
	background-clip: content-box;
}

input[type="radio"]:disabled + label:before {
	background-color: #ebebeb;
}

/* inline input with button */
.button-on-input {
	position: relative;
}

.button-on-input input[type*="text"],
.button-on-input input[type*="email"]
input[type*="password"],
.button-on-input .form-group {
	width: 100%;
}

.button-on-input button,
.button-on-input input[type*="submit"] {
	position: absolute;
	right: 0;
	top: 0;
	margin: 0;
}

/* carousel */
.carousel {
	overflow: hidden;
}

.carousel-indicators li {
	width: 10px;
	height: 10px;
	border-width: 0;
	background-color: #444444;
	margin: 0 3px;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.carousel-indicators li:hover {
	opacity: 0.8;
}

.carousel-indicators .active {
	width: 10px;
	height: 10px;
	margin: 0 3px;
	background-color: #91d0cc;
}

.carousel-control.left,
.carousel-control.right {
	border: 1px solid #ffffff;
	color: #ffffff;
	border-radius: 0px;
	width: 50px;
	height: 50px;
	left: -50px;
	top: 50%;
	margin-top: -25px;
	text-shadow: none;
	opacity: 1;
	background-image: none;
	filter: alpha(opacity=100);
	text-align: center;
}

.carousel-control.right {
	right: -50px;
}

.carousel:hover .carousel-control {
	left: 50px;
}

.carousel:hover .carousel-control.right {
	left: auto;
	right: 50px;
}

.carousel-control.left span,
.carousel-control.right span {
	font-family: 'rt-icons-2';
	width: 50px;
	height: 50px;
	margin: 0;
	font-size: 16px;
	left: 0;
	top: 0;
	line-height: 50px;
}

.carousel-control .icon-prev:before {
	content: "\e7c4";
}

.carousel-control .icon-next:before {
	content: "\e7c5";
}

/* collapse accordion */
.panel-heading .panel-title {
	font-size: inherit;
	letter-spacing: 0;
	padding: 0;
	position: relative;
}

.panel-heading .panel-title > a {
	position: relative;
	font-size: 20px;
	font-weight: 700;
	line-height: 1.2;
	display: block;
	padding: 23px 28px 27px;
	background-color: transparent;
	color: #91d0cc;
	border: none;
	word-wrap: break-word;
}

.panel-heading .panel-title > a:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	height: 1px;
	width: 180px;
	background-color: #ebebeb;
	display: none;
}

.panel-heading .panel-title > a.collapsed:after {
	display: block;
}

@media (min-width: 500px) {
	.panel-heading .panel-title > a {
		font-size: 25px;
	}
}

.panel-heading .panel-title > a.collapsed:hover,
.panel-heading .panel-title > a:hover {
	background-color: transparent;
	color: #91d0cc;
}

.panel-heading .panel-title > a.collapsed {
	color: #444444;
	background-color: transparent;
}

.panel-heading .panel-title > a > i {
	position: relative;
	padding-right: 8px;
	width: 1.5em;
	vertical-align: middle;
	display: inline-block;
}

.panel {
	box-shadow: none;
	border: none;
}

.panel-group .panel {
	border-radius: 0;
	background-color: transparent;
}

.panel-default > .panel-heading {
	background-color: transparent;
	color: inherit;
	position: relative;
	border: none;
	border-radius: 0;
	padding: 0;
}

.panel-default {
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.panel-default.collapsed {
	box-shadow: none;
}

.panel-group .panel + .panel {
	margin-top: 0;
}

.panel-body {
	font-size: 18px;
	line-height: 30px;
	border: none;
	border-top: none !important;
}

.panel-body .media-left {
	padding-right: 28px;
}

.panel-body .media-left .img-circle {
	max-width: 50px;
}

.panel-body .media-left img {
	max-width: 80px;
}

.panel-group .panel-heading + .panel-collapse .panel-body {
	padding: 0 28px 25px;
	border-radius: 0px;
}

.collapse-unstyled .panel h4 {
	margin: 0 0 0.5em;
}

.collapse-unstyled .panel h4 a:after {
	color: #91d0cc;
	padding: 0 5px;
	display: inline-block;
	font-family: 'FontAwesome';
	content: "\f107";
}

.collapse-unstyled .panel h4 a.collapsed:after {
	content: "\f105";
}

.collapse-unstyled .panel-content {
	padding: 10px 20px;
}

/* progress bars */
.progress-bar-title {
	font-size: 12px;
	margin-bottom: 2px;
}

.progress {
	overflow: visible;
	height: 4px;
	margin-bottom: 30px;
	position: relative;
	border-radius: 0;
	box-shadow: none;
	border: none;
}

.progress-bar {
	background-color: #91d0cc;
	color: inherit;
	box-shadow: none;
	height: 4px;
}

.progress-bar span {
	position: absolute;
	right: 0;
	top: -22px;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.2em;
}

.progress-bar-success {
	background-color: #4db19e;
}

.progress-bar-info {
	background-color: #007ebd;
}

.progress-bar-warning {
	background-color: #eeb269;
}

.progress-bar-danger {
	background-color: #dc5753;
}

/* nav and tabs */
.nav > li > a {
	color: #444444;
}

.nav > li > a:hover,
.nav > li > a:focus {
	background-color: inherit;
}

.unstyled-tabs-title {
	margin-bottom: -20px;
}

.nav-unstyled {
	list-style: none;
	padding: 0;
	margin-bottom: 20px;
	text-align: right;
	border-bottom: 1px solid #ebebeb;
}

.nav-unstyled li {
	display: inline-block;
	margin-bottom: -1px;
}

.nav-unstyled li a {
	display: inline-block;
	padding: 0 10px 20px;
	border-bottom: 3px solid transparent;
}

.nav-unstyled .active a {
	border-color: #91d0cc;
}

.nav-image {
	font-size: 0;
	text-align: center;
	margin: 5px -5px;
}

.nav-image > li {
	font-size: 16px;
	display: inline-block;
	max-width: 200px;
	width: 33.333%;
	padding: 5px;
}

.nav-image > li > a {
	position: relative;
	padding: 0;
}

.nav-image > li > a img {
	max-width: 100%;
}

.nav-image > li > a:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(145, 208, 204, 0.75);
	border: 3px solid transparent;
	-webkit-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
}

.nav-image > li > a:hover:after {
	background-color: transparent;
	border-color: #91d0cc;
}

.nav-image > li.active a:after {
	background-color: transparent;
	border-color: #91d0cc;
}

/*tabs*/
.tab-content {
	border-radius: 0 0 0 0;
	padding: 35px 20px;
	border: none;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.tab-content.no-border {
	padding: 36px 0 0;
	background-color: transparent;
	box-shadow: none;
}

.tab-content.top-color-border {
	position: relative;
}

.tab-content.top-color-border:before {
	content: '';
	position: absolute;
	left: 0px;
	right: 0px;
	top: -1px;
	height: 10px;
	background-color: #91d0cc;
}

.tab-content.tab-unstyled {
	padding: 10px 0 0;
	box-shadow: none;
}

.ls .tab-content.tab-unstyled,
.ds .tab-content.tab-unstyled,
.cs .tab-content.tab-unstyled {
	background-color: transparent;
}

.tab-content .featured-tab-image {
	margin: -35px -20px 35px -20px;
}

@media (min-width: 400px) {
	.tab-content {
		padding: 50px 35px;
	}

	.tab-content .featured-tab-image {
		margin: -50px -35px 35px -35px;
	}
}

.nav-tabs {
	border-bottom-color: transparent;
	margin-top: 0px;
	margin-bottom: -1px;
}

.nav-tabs > li {
	margin-bottom: 0;
}

.nav-tabs > li > a {
	padding: 13px 30px;
	margin-right: 3px;
	margin-top: 0px;
	border: 1px solid #ebebeb;
	background-color: transparent;
	color: #b4b4b4;
	border-radius: 0;
}

.small-tabs .nav-tabs > li > a {
	padding: 10px 10px 9px;
}

.nav-tabs > li > a i {
	position: relative;
	top: 2px;
	padding-right: 8px;
	font-size: 1.6em;
	width: 1.5em;
	text-align: center;
	display: inline-block;
	line-height: 0;
}

@media (max-width: 499px) {
	.nav-tabs > li > a {
		padding: 10px 10px 9px;
		font-size: 14px;
	}
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
	z-index: 1;
	border-color: #91d0cc;
	background-color: #91d0cc;
	color: #ffffff;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus,
.nav-tabs.nav-justified > .active > a {
	border-color: #91d0cc;
	background-color: #91d0cc;
	color: #ffffff;
}

/*vertical tabs*/
.vertical-tabs .tab-content.no-border {
	padding-top: 0;
}

.vertical-tabs .nav > li > a {
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-weight: 700;
	background-color: #f2f2f2;
	color: #444444;
	padding: 16px 60px 16px 40px;
	margin-bottom: 10px;
	position: relative;
	border-radius: 2px;
}

.vertical-tabs .nav > li > a i {
	position: relative;
	top: 4px;
	padding-right: 8px;
	font-size: 1.6em;
	width: 2em;
	text-align: center;
	display: inline-block;
	line-height: 0;
}

.vertical-tabs .nav > li > a:after {
	color: #91d0cc;
	font-family: 'rt-icons-2';
	content: "\e7c5";
	position: absolute;
	font-size: 12px;
	line-height: 60px;
	text-align: center;
	right: 0;
	top: 0;
	bottom: 0;
	width: 60px;
	letter-spacing: 0;
}

.vertical-tabs .nav > li.active a,
.vertical-tabs .nav > li > a:hover {
	background-color: #91d0cc;
	color: #ffffff;
}

.vertical-tabs .nav > li.active a:after,
.vertical-tabs .nav > li > a:hover:after {
	color: #ffffff;
}

@media (min-width: 768px) {
	.vertical-tabs .nav > li.active {
		position: relative;
		z-index: 2;
	}
}

/* modal */
.modal-backdrop.in {
	filter: alpha(opacity=80);
	opacity: .8;
}

.modal-content {
	border-radius: 1px;
}

.modal-content [class*="col-"]:last-child {
	margin-bottom: 0;
}

/* bootstrap tables */
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
	padding: 15px 15px;
	border-color: #ebebeb;
}

.table {
	border-top: 1px solid #ebebeb;
	border-bottom: 1px solid #ebebeb;
}

.table td + th,
.table th + td,
.table th + th,
.table td + td {
	border-left: 1px solid #ebebeb;
}

.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
	border-bottom-width: 1px;
}

.table > tbody > tr > td.media-middle {
	vertical-align: middle;
}

.table-responsive {
	border: none;
}

/* bootstrap buttons */
.btn {
	padding: 22px 30px 22px;
	margin: 0 0 4px 0;
	font-weight: 700;
	border: none;
	border-radius: 0;
	outline: none;
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.btn:hover, .btn:focus {
	outline: none;
}

/* media elements */
.media {
	margin-top: 25px;
}

.media .media-object {
	max-width: 100px;
}

.media.small-teaser {
	margin-top: 15px;
}

li.media:before,
li.media:after {
	display: block;
	content: '';
	clear: both;
}

.media-right, .media > .pull-right {
	padding-left: 15px;
}

.media-left, .media > .pull-left {
	padding-right: 15px;
}

.media-left img,
.media-right img {
	max-width: 60px;
}

@media (min-width: 768px) {
	.big-left-media .media-left img, .big-left-media
  .media-right img {
		max-width: 120px;
		margin-right: 20px;
	}
}

td .media-body {
	width: auto;
}

/* bootstrap dropdown menu */
.dropdown-menu {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 0;
	border-color: transparent;
	z-index: 1001;
	margin-top: 0;
	padding-left: 0;
	padding-right: 0;
	max-width: 100%;
}

[class*="right"] .dropdown-menu {
	right: 0;
	left: auto;
}

.login-dropdown .dropdown-menu {
	min-width: 270px;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
	color: #444444;
	background-color: transparent;
}

.dropdwon-menu-title {
	padding: 10px 30px;
}

.well {
	box-shadow: none;
	border: none;
	border-radius: 0;
}

.embed-responsive-3by2 {
	padding-bottom: 66.666%;
}

.embed-placeholder:after {
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
	content: "\f04b";
	font-family: 'FontAwesome';
	position: absolute;
	color: #ffffff;
	font-size: 24px;
	line-height: 60px;
	width: 60px;
	height: 60px;
	text-align: center;
	padding-left: 6px;
	background-color: #91d0cc;
	border-radius: 50%;
	left: 50%;
	top: 50%;
	margin-left: -30px;
	margin-top: -30px;
}

.embed-placeholder:hover:after {
	opacity: 0.8;
}

.alert {
	border-radius: 0;
	border-width: 0 0 0 4px;
	padding: 30px;
	margin-bottom: 40px;
}

button.close {
	min-width: 0;
}

/* small in headings */
.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
	color: inherit;
	font-weight: 300;
}

/* labels */
.label-success {
	color: #ffffff;
	background-color: #4db19e;
}

.label-info {
	color: #ffffff;
	background-color: #007ebd;
}

.label-warning {
	color: #ffffff;
	background-color: #eeb269;
}

.label-danger {
	color: #ffffff;
	background-color: #dc5753;
}

/*
** General Styles for HTML tags
*/
html {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: visible;
}

::-moz-selection {
	color: #ffffff;
	background-color: #91d0cc;
}

::selection {
	color: #ffffff;
	background-color: #91d0cc;
}

::-moz-selection {
	color: #ffffff;
	background-color: #91d0cc;
}

body {
	font-family: 'Muli', sans-serif;
	font-size: 18px;
	font-weight: 300;
	line-height: 28px;
	background-color: #ffffff;
	background-color: #ccc;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	line-height: 1.1;
	word-break: break-word;
	margin-top: 1.5em;
	margin-bottom: 1em;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 16px;
}

h6 {
	font-size: 14px;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

p {
	margin-bottom: 15px;
}

p:last-child {
	margin-bottom: 0;
}

blockquote {
	position: relative;
	margin: 30px 0;
	padding: 0;
	border: none;
	font-family: 'Playfair Display', serif;
	font-size: 20px;
	font-weight: 700;
	line-height: 1.15;
	text-transform: uppercase;
	text-align: center;
}

blockquote .item-meta {
	font-family: 'Muli', sans-serif;
	margin-top: 30px;
	font-style: normal;
	font-size: 18px;
	font-weight: 300;
	text-transform: none;
}

blockquote h5 {
	font-family: 'Muli', sans-serif;
	font-weight: 300;
	font-size: 18px;
}

@media (min-width: 992px) {
	blockquote {
		font-size: 28px;
	}
}

@media (min-width: 1200px) {
	blockquote {
		font-size: 35px;
	}
}

img, figure {
	max-width: 100%;
	height: auto;
}

figcaption, .entry-caption {
	padding: 2px 0 12px;
}

figcaption p, .entry-caption p {
	margin-bottom: 0;
}

label {
	color: #444444;
}

a {
	text-decoration: none;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

a:hover {
	color: #d9be93;
	text-decoration: none;
	-webkit-transition: all 0.15s linear 0s;
	transition: all 0.15s linear 0s;
}

input:focus,
button:focus,
select:focus,
textarea:focus,
a:focus {
	outline: medium none;
	text-decoration: none;
}

a > img {
	-webkit-transition: all 0.15s ease-in-out 0s;
	transition: all 0.15s ease-in-out 0s;
}

hr {
	border: none;
	height: 1px;
	margin-top: 25px;
	margin-bottom: 25px;
	background-color: #ddd;
}

iframe {
	border: none;
	max-width: 100%;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

table {
	width: 100%;
	max-width: 100%;
	margin: 10px 0;
}

table td, table th {
	padding: 13px 8px;
	line-height: 1.42857143;
	vertical-align: top;
}

table th {
	color: #444444;
	font-weight: normal;
	vertical-align: middle;
	line-height: 1;
}

.table_template thead {
	background-color: #91d0cc;
	color: #444444;
}

.table_template thead.light {
	background-color: #ffffff;
}

.table_template th {
	font-weight: 700;
}

.table_template td, .table_template th {
	padding: 18px 30px;
}

.table_template tbody tr:nth-of-type(odd) {
	background-color: rgba(238, 238, 238, 0.1);
}

.table_template tbody tr:nth-of-type(even) {
	background-color: rgba(17, 17, 17, 0.05);
}

canvas {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

/*
** Styled Lists
*/
ul, ol {
	padding-left: 30px;
	margin-bottom: 20px;
}

.list1 ul,
ul.list1 {
	list-style: none;
	padding: 0;
}

.padding_30 .list1 ul, .padding_30
ul.list1 {
	margin: 0;
}

.list1 li {
	position: relative;
	padding: 14px 0 14px 30px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.muted_background .list1 li {
	border-color: rgba(255, 255, 255, 0.4);
}

.list1 li > .media {
	margin: 4px 0 1px;
}

.list1.no-bullets li {
	padding-left: 0;
}

.list1.no-bullets li:before {
	display: none;
}

.list1 li:first-child {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.list1.no-top-border li:first-child {
	border-top: none;
	margin-top: 0;
	padding-top: 0;
}

.list1.no-bottom-border li:last-child {
	border-bottom: none;
	margin-bottom: 0;
	padding-bottom: 0;
}

.list1 li:before {
	content: '';
	display: block;
	position: absolute;
	border: 1px solid #91d0cc;
	left: 10px;
	top: 25px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
}

.list2 ul,
ul.list2 {
	list-style: none;
	padding-left: 20px;
	margin-bottom: 20px;
}

.list2 li {
	position: relative;
	padding: 3px 0 3px 0;
}

.list2 li:first-child {
	padding-top: 0;
}

.list2 li:first-child:before {
	top: 12px;
}

.list2 li:before {
	content: '';
	display: block;
	width: 5px;
	height: 5px;
	background-color: #91d0cc;
	position: absolute;
	left: -18px;
	top: 15px;
}

.list3 ol,
ol.list3 {
	counter-reset: li;
	list-style: none outside none;
	padding: 0;
}

.list3 li {
	position: relative;
	padding: 8px 0 8px 25px;
}

.list3 li:after {
	content: counter(li) ".";
	counter-increment: li;
	position: absolute;
	left: 2px;
	top: 8px;
	color: #91d0cc;
	text-align: center;
	border-radius: 100%;
}

.list-unstyled .media {
	margin-bottom: 10px;
}

.list-unstyled li:last-child > .media {
	margin-bottom: 0;
}

.list-unstyled .media-left {
	padding-right: 10px;
}

.list-unstyled .media-left img {
	border-radius: 50%;
	padding-right: 10px;
}

.list-unstyled .media-right {
	padding-left: 10px;
}

.list-unstyled .media-right img {
	border-radius: 50%;
	padding-left: 10px;
}

.list-unstyled .media-body .pull-right {
	margin: 0 20px;
}

/*
** Buttons
*/
.theme_buttons a,
.theme_button,
.wc-proceed-to-checkout .checkout-button,
.buttons a,
button,
input[type="submit"] {
	font-size: 18px;
	padding: 17px 30px 17px;
	margin-bottom: 4px;
	line-height: 1;
	display: inline-block;
	text-align: center;
	color: #ffffff;
	border: none;
	background-color: #444444;
	border-radius: 0;
	position: relative;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

.theme_buttons a.with_shadow.color1,
.theme_button.with_shadow.color1,
.wc-proceed-to-checkout .checkout-button.with_shadow.color1,
.buttons a.with_shadow.color1,
button.with_shadow.color1,
input[type="submit"].with_shadow.color1 {
	box-shadow: 0px 2px 30px rgba(145, 208, 204, 0.5);
}

.theme_buttons a.with_shadow.color1:hover,
.theme_button.with_shadow.color1:hover,
.wc-proceed-to-checkout .checkout-button.with_shadow.color1:hover,
.buttons a.with_shadow.color1:hover,
button.with_shadow.color1:hover,
input[type="submit"].with_shadow.color1:hover {
	box-shadow: 0px 2px 30px rgba(217, 190, 147, 0.5);
}

.theme_button[type="reset"] {
	display: none;
}

.theme_buttons.color1 a,
.theme_button.color1,
.button.alt,
.btn-accent,
.wc-proceed-to-checkout .checkout-button,
.widget_price_filter button,
.form-submit [type="submit"] {
	background-color: #91d0cc;
	border-color: #91d0cc;
}

.theme_buttons.color2 a,
.theme_button.color2 {
	background-color: #d9be93;
	border-color: #d9be93;
}

.theme_buttons.color3 a,
.theme_button.color3 {
	background-color: #e7003e;
	border-color: #e7003e;
}

.theme_buttons a:active,
.theme_buttons a:hover,
.theme_buttons a:focus,
.theme_button:active,
.theme_button:hover,
.theme_button:focus,
.wc-proceed-to-checkout .checkout-button:hover {
	color: #444444;
	opacity: 1;
	text-decoration: none;
	outline: none;
	-webkit-transition: all 0.05s linear 0s;
	transition: all 0.05s linear 0s;
}

.theme_buttons a:active,
.theme_button:active {
	top: 1px;
}

.theme_buttons.inverse a,
.theme_button.inverse {
	color: #444444;
	background-color: #f5f5f5;
}

.muted_buttons a,
.muted_button {
	opacity: 0.2;
}

.small_buttons a,
.small_button {
	padding: 5px 7px 2px;
	text-transform: uppercase;
	font-weight: 100;
	min-width: auto;
}

.square_buttons a,
.square_button {
	padding-left: 10px;
	padding-right: 10px;
	min-width: auto;
}

.wide_buttons a,
.wide_button {
	padding-left: 50px;
	padding-right: 50px;
}

.block_buttons a,
.block_button {
	display: block;
	width: 100%;
	text-align: center;
}

/*icons in headings, paragraphs and buttons*/
h1 > [class*="rt-icon"],
h2 > [class*="rt-icon"],
h3 > [class*="rt-icon"],
h4 > [class*="rt-icon"],
h5 > [class*="rt-icon"],
h6 > [class*="rt-icon"] {
	position: relative;
	top: .08em;
	padding-right: 0.1em;
}

p > [class*="rt-icon"] {
	font-size: 1.1em;
	position: relative;
	top: .1em;
	line-height: 0;
	padding: 0 0.2em;
}

p > [class*="rt-icon"] + [class*="rt-icon"] {
	margin-left: -0.75em;
}

.theme_buttons a > [class*="rt-icon"],
.theme_button > [class*="rt-icon"] {
	font-size: 1.8em;
	line-height: 0;
	position: relative;
	top: 5px;
	padding: 0 3px;
}

.theme_buttons a > [class*="glyphicon"],
.theme_buttons a > [class*="fa-"],
.theme_button > [class*="glyphicon"],
.theme_button > [class*="fa-"] {
	font-size: 1.5em;
	position: relative;
	top: 3px;
	line-height: 0;
}

.form-inline [type="submit"],
.form-inline .btn,
.form-inline .theme_button {
	margin-bottom: 0;
}

.btn-group-lg > .btn, .btn-lg {
	padding: 16px 16px;
}

.btn-group-sm > .btn, .btn-sm {
	padding: 5px 10px;
}

.btn-group-xs > .btn, .btn-xs {
	padding: 1px 5px;
}

/*
**plugins
*/
/* Owl carousel v 2.1.6 styles */
/* Owl Carousel - Core */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
  /* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
	-webkit-transform-style: preserve-3d;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
	display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
	display: none;
}

.owl-carousel.owl-drag .owl-item {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}

.owl-carousel .owl-dots {
	text-align: center;
	-webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-dots .owl-dot {
	display: inline-block;
	zoom: 1;
	margin: 7px;
	*display: inline;
}

.owl-carousel .owl-dots .owl-dot span {
	display: block;
	width: 16px;
	height: 16px;
	padding: 3px;
	position: relative;
	background-color: #b4b4b4;
	background-clip: content-box;
	-webkit-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
	z-index: 1;
}

.owl-carousel .owl-dots .owl-dot span:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: 10px;
	background-color: transparent;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
	z-index: -1;
}

.owl-carousel .owl-dots .owl-dot:hover span, .owl-carousel .owl-dots .owl-dot.active span {
	background-color: #b4b4b4;
}

.owl-carousel .owl-dots .owl-dot:hover span:before, .owl-carousel .owl-dots .owl-dot.active span:before {
	background-color: #444444;
}

.owl-carousel .owl-dots .owl-dot.active span {
	padding: 0;
}

.owl-carousel .owl-nav {
	-webkit-transition: all 0.6s ease 0s;
	transition: all 0.6s ease 0s;
}

.owl-carousel .owl-nav > div {
	color: transparent;
	font-size: 0;
	display: inline-block;
	right: 10px;
	text-align: center;
}

.owl-carousel .owl-nav > div.disabled {
	opacity: 0.5;
	cursor: default;
}

.owl-carousel .owl-nav > div:first-child {
	left: 10px;
	right: auto;
}

.owl-carousel .owl-nav > div:after {
	-webkit-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	font-family: 'rt-icons-2';
	font-size: 20px;
	line-height: 60px;
	content: "\e7c5";
	color: #444444;
}

.owl-carousel .owl-nav > div:first-child:after {
	content: '\e7c4';
}

.owl-carousel .owl-nav > div:hover:after {
	color: #91d0cc;
}

.owl-carousel.top-dots {
	padding-top: 60px;
}

.owl-carousel.top-dots .owl-dots {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin-top: 0;
}

.top-right-nav .owl-nav {
	position: absolute;
	top: -70px;
	right: 0;
}

.top-right-nav .owl-nav > div {
	background: transparent;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/* Owl Carousel - Animate Plugin */
.owl-carousel .animated {
	-webkit-animation-duration: 1000ms;
	animation-duration: 1000ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/* Owl Carousel - Auto Height Plugin */
.owl-height {
	-webkit-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}

/* Owl Carousel - Lazy Load Plugin */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	-webkit-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

/* Owl Carousel - Video Plugin */
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	background: url("../img/owl.video.play.png") no-repeat;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transition: -webkit-transform 100ms ease;
	transition: -webkit-transform 100ms ease;
	transition: transform 100ms ease;
	transition: transform 100ms ease, -webkit-transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
	-webkit-transform: scale(1.3, 1.3);
	transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	-webkit-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%;
}

/* Owl Default Theme */
.owl-theme:hover .owl-nav {
	opacity: 1;
}

.owl-theme .owl-nav {
	position: absolute;
	top: 40%;
	opacity: 1;
	right: 0;
	left: 0;
	text-align: center;
	-webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav > div {
	font-size: 0;
	position: absolute;
	right: 10px;
	text-align: center;
	margin-top: -30px;
}

.owl-theme .owl-nav .disabled {
	opacity: 0.5;
	cursor: default;
}

.container .owl-theme .owl-nav {
	right: -10%;
	left: -10%;
}

.owl-theme .owl-dots {
	margin-top: 25px;
}

/* .owl-center theme */
.owl-center .owl-nav {
	top: 0;
	bottom: 0;
	position: static;
	margin-top: 0;
	opacity: 1;
}

.owl-center .owl-nav > div {
	right: -15px;
	width: 50px;
	top: 0;
	bottom: 0;
	margin: 0;
	height: auto;
	background-color: rgba(68, 68, 68, 0.6);
}

.owl-center .owl-nav > div:first-child {
	left: -15px;
	right: auto;
}

.owl-center .owl-nav > div:first-child:after {
	left: 13px;
}

.owl-center .owl-nav > div:after {
	position: absolute;
	top: 50%;
	margin-top: -10px;
	color: #ffffff;
	font-size: 20px;
	left: 17px;
}

/* owl images fix */
.owl-carousel .owl-item {
	-webkit-transition: opacity 0.2s ease 0.1s;
	transition: opacity 0.2s ease 0.1s;
	opacity: 0;
}

.owl-carousel .owl-item.active {
	opacity: 1;
}

.owl-center .owl-item {
	opacity: 1;
}

/*
** Flexslider
* http://www.woothemes.com/flexslider/
*
* Copyright 2012 WooThemes
* Free to use under the GPLv2 license.
* http://www.gnu.org/licenses/gpl-2.0.html
*
* Contributing author: Tyler Smith (@mbmufffin)
*/
/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
	outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
	margin: 0;
	padding: 0;
	list-style: none;
}

/* FlexSlider Necessary Styles */
.flexslider {
	margin: 0;
	padding: 0;
}

.flexslider .slides > li {
	display: none;
	-webkit-backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
	width: 100%;
	display: block;
}

.flex-pauseplay span {
	text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
	content: "\0020";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

html[xmlns] .slides {
	display: block;
}

* html .slides {
	height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
* include js that eliminates this class on page load */
.no-js .slides > li:first-child {
	display: block;
}

/* FlexSlider Default Theme */
.flexslider {
	margin: 0 0;
	position: relative;
	zoom: 1;
}

.flex-viewport {
	max-height: 2000px;
	-webkit-transition: all 1s ease;
	transition: all 1s ease;
}

.loading .flex-viewport {
	max-height: 300px;
}

.flexslider .slides {
	zoom: 1;
}

/* Direction Nav */
.flex-direction-nav {
	*height: 0;
}

.flex-direction-nav a {
	text-decoration: none;
	display: block;
	width: 40px;
	height: 40px;
	margin: -20px 0 0;
	position: absolute;
	top: 50%;
	z-index: 10;
	overflow: hidden;
	opacity: 0;
	cursor: pointer;
	font-size: 0;
	color: transparent;
	text-align: center;
}

.flex-direction-nav .flex-prev {
	left: 40px;
}

.flex-direction-nav .flex-next {
	right: 40px;
	text-align: right;
}

.flexslider:hover .flex-prev {
	opacity: 0.7;
	left: 40px;
}

.flexslider:hover .flex-next {
	opacity: 0.7;
	right: 40px;
}

.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {
	opacity: 1;
}

.flex-direction-nav .flex-disabled {
	opacity: 0;
	filter: alpha(opacity=0);
	cursor: default;
}

.flex-direction-nav a:before {
	font-family: "FontAwesome";
	font-size: 20px;
	content: '\f104';
	position: absolute;
	top: 5px;
	left: 12px;
}

.flex-direction-nav a.flex-next:before {
	content: '\f105';
	left: 14px;
}

/* Pause/Play */
.flex-pauseplay a {
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	bottom: 5px;
	left: 10px;
	opacity: 0.8;
	z-index: 10;
	overflow: hidden;
	cursor: pointer;
	color: #000;
}

.flex-pauseplay a:before {
	font-family: 'rt-icons-2';
	font-size: 20px;
	display: inline-block;
	content: "\e6a2";
}

.flex-pauseplay a:hover {
	opacity: 1;
}

.flex-pauseplay a.flex-play:before {
	content: "\e711";
}

/* Control Nav */
.flex-control-nav {
	width: 100%;
	position: absolute;
	bottom: 40px;
	text-align: center;
	z-index: 10;
}

.flex-control-nav li {
	text-align: center;
	display: inline-block;
	padding: 0 10px;
	zoom: 1;
	*display: inline;
}

.flex-control-paging li a {
	display: block;
	width: 16px;
	height: 16px;
	padding: 3px;
	font-size: 0;
	position: relative;
	background-color: transparent;
	background-clip: content-box;
	-webkit-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
}

.flex-control-paging li a:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: 10px;
	background-color: #b4b4b4;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
	z-index: -1;
}

.flex-control-paging li a:hover {
	background-color: #444444;
}

.flex-control-paging li a.flex-active {
	background-color: #444444;
}

.flex-control-paging li a.flex-active:before {
	width: 16px;
	height: 16px;
}

.cs .flex-control-paging li a:before,
.ds .flex-control-paging li a:before {
	background-color: rgba(255, 255, 255, 0.5);
}

.cs .flex-control-paging li a.flex-active, .cs .flex-control-paging li a:hover,
.ds .flex-control-paging li a.flex-active,
.ds .flex-control-paging li a:hover {
	background-color: #ffffff;
}

.flex-control-thumbs {
	margin: 5px 0 0;
	position: static;
	overflow: hidden;
}

.flex-control-thumbs li {
	width: 25%;
	float: left;
	margin: 0;
}

.flex-control-thumbs img {
	width: 100%;
	display: block;
	opacity: .7;
	cursor: pointer;
}

.flex-control-thumbs img:hover {
	opacity: 1;
}

.flex-control-thumbs .flex-active {
	opacity: 1;
	cursor: default;
}

@media screen and (max-width: 600px) {
	.flex-direction-nav .flex-prev {
		top: 24%;
	}

	.flex-direction-nav .flex-next {
		top: 24%;
	}
}

/* custom nav for flex slider*/
.flex-custom-nav {
	text-align: center;
	position: absolute;
	bottom: 38px;
	right: 0;
	left: 0;
	z-index: 3;
	line-height: 1;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.flex-custom-nav .flex-control-nav {
	width: auto;
	display: inline-block;
	position: static;
}

.flex-custom-nav .flex-custom-link {
	font-size: 12px;
	position: relative;
	bottom: 7px;
	margin: 0 8px;
	color: #444444;
}

.ds .flex-custom-nav .flex-custom-link,
.cs .flex-custom-nav .flex-custom-link {
	color: #ffffff;
}

.flex-custom-nav .flex-custom-link:hover {
	color: #91d0cc;
}

.intro_section:hover .flex-custom-nav {
	opacity: 1;
}

/*
** prettyPhoto
*/
div.facebook .pp_description {
	margin: 0 37px 0 0;
}

div.pp_pic_holder a:focus {
	outline: none;
}

div.pp_overlay {
	background: #000;
	display: none;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 9500;
}

div.pp_pic_holder {
	display: none;
	position: absolute;
	width: 100px;
	z-index: 10000;
}

.pp_content {
	height: 40px;
	min-width: 40px;
	overflow: hidden;
}

* html .pp_content {
	width: 40px;
}

.pp_content_container {
	position: relative;
	text-align: left;
	width: 100%;
}

.pp_content_container .pp_left {
	padding-left: 20px;
}

.pp_content_container .pp_right {
	padding-right: 20px;
}

.pp_content_container .pp_details {
	float: left;
	padding: 8px 10px;
	line-height: 1;
	background-color: #ffffff;
}

.pp_description {
	display: none;
	margin: 0;
}

.pp_social {
	float: left;
	margin: 0 0 0 30px;
}

.pp_social .facebook {
	float: left;
	margin-left: 5px;
	width: auto;
	overflow: hidden;
}

.pp_social .twitter {
	float: left;
}

.pp_nav p {
	float: left;
	white-space: nowrap;
	margin: 4px 6px;
	font-weight: bold;
	opacity: 0.7;
}

.pp_nav .pp_play,
.pp_nav .pp_pause,
a.pp_arrow_previous,
a.pp_arrow_next {
	float: left;
	text-align: center;
	font-size: 0;
	color: transparent;
}

.pp_nav .pp_play:before,
.pp_nav .pp_pause:before,
a.pp_arrow_previous:before,
a.pp_arrow_next:before {
	line-height: 20px;
	display: inline-block;
	width: 20px;
	font-family: 'FontAwesome';
	font-size: 12px;
	color: #444444;
	opacity: 0.7;
	content: "\f04b";
}

.pp_nav .pp_play:hover:before,
.pp_nav .pp_pause:hover:before,
a.pp_arrow_previous:hover:before,
a.pp_arrow_next:hover:before {
	opacity: 1;
}

.pp_nav .pp_pause:before {
	content: "\f04c";
}

a.pp_arrow_previous:before {
	content: "\f04a";
}

a.pp_arrow_next:before {
	content: "\f04e";
}

a.pp_arrow_previous.disabled,
a.pp_arrow_next.disabled {
	visibility: hidden;
	cursor: default;
}

.pp_hoverContainer {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2000;
}

.pp_gallery {
	display: none;
	left: 50%;
	margin-top: -50px;
	position: absolute;
	z-index: 10000;
}

.pp_gallery div {
	float: left;
	overflow: hidden;
	position: relative;
}

.pp_gallery ul {
	float: left;
	height: 35px;
	position: relative;
	white-space: nowrap;
	margin: 0 0 0 5px;
	padding: 0;
}

.pp_gallery ul a {
	border: 1px rgba(0, 0, 0, 0.1) solid;
	display: block;
	float: left;
	height: 33px;
	overflow: hidden;
}

.pp_gallery ul a:hover,
.pp_gallery ul .selected a {
	border-color: #91d0cc;
}

.pp_gallery ul a img {
	border: 0;
}

.pp_gallery li {
	display: block;
	float: left;
	margin: 0 5px 0 0;
	padding: 0;
}

.pp_gallery li.default a {
	background: url(../img/prettyPhoto/facebook/default_thumbnail.gif) 0 0 no-repeat;
	display: block;
	height: 33px;
	width: 50px;
}

div.facebook .pp_gallery .pp_arrow_previous, div.facebook .pp_gallery .pp_arrow_next {
	margin-top: 7px;
}

a.pp_next, a.pp_previous {
	position: relative;
	display: block;
	float: right;
	height: 100%;
	width: 49%;
	font-size: 0;
	color: transparent;
	opacity: 0;
}

a.pp_next:before, a.pp_previous:before {
	line-height: 1;
	background-color: rgba(68, 68, 68, 0.3);
	font-size: 18px;
	padding: 10px 15px 10px 18px;
	color: #ffffff;
	font-family: 'FontAwesome';
	content: "\f105";
	position: absolute;
	top: 50%;
	margin-top: -3px;
	right: 30px;
}

a.pp_next:hover, a.pp_previous:hover {
	opacity: 1;
}

a.pp_previous {
	float: left;
}

a.pp_previous:before {
	content: "\f104";
	right: auto;
	padding: 10px 18px 10px 15px;
	left: 30px;
}

a.pp_expand, a.pp_contract {
	cursor: pointer;
	display: none;
	position: absolute;
	right: 30px;
	top: 19px;
	z-index: 20000;
	font-size: 0;
	color: transparent;
	text-align: center;
}

a.pp_expand:before, a.pp_contract:before {
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	font-family: 'FontAwesome';
	content: "\f0b2";
	background-color: rgba(68, 68, 68, 0.3);
	padding: 12px 13px;
}

a.pp_contract:before {
	content: "\f066";
}

a.pp_close {
	display: none;
	position: absolute;
	right: -7px;
	top: -25px;
	font-size: 0;
	color: transparent;
}

a.pp_close:before {
	background-color: #1a1a1a;
	padding: 6px 8px;
	font-size: 14px;
	color: #ffffff;
	font-family: 'FontAwesome';
	content: "\f00d";
}

.pp_loaderIcon {
	height: 50px;
	width: 50px;
	left: 50%;
	top: 50%;
	position: absolute;
	margin: -25px 0 0 -25px;
	text-align: center;
	-webkit-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
}

.pp_loaderIcon:before {
	display: block;
	font-family: 'FontAwesome';
	content: "\f1ce";
	color: #ffffff;
	font-size: 50px;
	line-height: 50px;
	opacity: 0.6;
}

#pp_full_res {
	line-height: 1;
}

#pp_full_res .pp_inline {
	text-align: left;
}

#pp_full_res .pp_inline p {
	margin: 0 0 15px;
}

div.ppt {
	color: #ffffff;
	display: none;
	font-size: 18px;
	z-index: 9999;
	margin: 0 0 5px 20px;
}

.pp_top, .pp_bottom {
	position: relative;
}

* html .pp_top, * html .pp_bottom {
	padding: 0 20px;
}

.pp_top .pp_left, .pp_bottom .pp_left {
	height: 20px;
	left: 0;
	position: absolute;
	width: 20px;
}

.pp_top .pp_middle, .pp_bottom .pp_middle {
	height: 20px;
	left: 20px;
	position: absolute;
	right: 20px;
}

* html .pp_top .pp_middle, * html .pp_bottom .pp_middle {
	left: 0;
	position: static;
}

.pp_top .pp_right, .pp_bottom .pp_right {
	height: 20px;
	left: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
}

.pp_fade, .pp_gallery li.default a img {
	display: none;
}

/*
** Easy pieChart
*/
.chart {
	position: relative;
	display: inline-block;
	min-height: 150px;
	min-width: 150px;
	margin-bottom: 10px;
	text-align: center;
}

.chart .chart-meta {
	position: absolute;
	width: 100%;
	top: 50%;
	margin-top: -20px;
}

.chart .chart-meta h4 {
	font-size: 14px;
	margin: 0;
}

.percent {
	display: inline-block;
	font-size: 60px;
	font-weight: 300;
	z-index: 2;
	letter-spacing: -0.05em;
}

.percent:after {
	content: '%';
}

.angular {
	margin-top: 100px;
}

.angular .chart {
	margin-top: 0;
}

/*
** Time Table
*/
#timetable span {
	display: block;
}

#timetable th, #timetable td {
	vertical-align: middle;
	font-weight: normal;
	text-align: center;
	padding: 25px 5px;
}

#timetable thead {
	color: #ffffff;
}

#timetable thead th {
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-width: 0 1px;
}

#timetable tbody th, #timetable tbody td {
	border: 1px solid #ebebeb;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

#timetable tbody .current {
	background-color: #91d0cc;
	border-color: #91d0cc;
}

#timetable tbody .current a {
	color: #ffffff;
}

/*
** toTop
*/
#toTop {
	bottom: 5px;
	right: 5px;
	display: none;
	width: 60px;
	height: 60px;
	overflow: hidden;
	position: fixed;
	text-decoration: none;
	z-index: 9999;
	font-size: 0;
	color: transparent;
	background-color: #91d0cc;
	opacity: 0.7;
}

#toTop:after {
	color: #ffffff;
	content: '\f106';
	font-size: 16px;
	line-height: 60px;
	font-family: 'FontAwesome';
	text-align: center;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

#toTopHover, #toTop:hover {
	bottom: 8px;
	opacity: 1;
}

#toTop:hover:after {
	color: #ffffff;
}

#toTop:active, #toTop:focus {
	outline: medium none;
}

.modal-open #toTop {
	visibility: hidden;
}

/* Countdown */
#comingsoon-countdown {
	text-align: center;
}

#comingsoon-countdown:before,
#comingsoon-countdown:after {
	clear: both;
	display: table;
	content: ' ';
}

.countdown-rtl {
	direction: rtl;
}

.countdown-row {
	clear: both;
	display: table;
	margin: auto;
	padding: 20px 0;
	text-align: center;
}

.countdown-section {
	display: table-cell;
	padding: 10px 30px 20px;
	text-align: center;
	border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.countdown-section:first-child {
	border-left-width: 0;
}

.countdown-amount {
	font-size: 80px;
	line-height: 1;
	font-weight: 700;
	color: #91d0cc;
}

.countdown-period {
	display: block;
	color: #444444;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-weight: 700;
	min-width: 11em;
}

.countdown-descr {
	display: block;
}

@media (max-width: 767px) {
	.countdown-section {
		padding: 20px 8px 0;
	}

	.countdown-amount {
		font-size: 40px;
	}

	.countdown-period {
		min-width: 4em;
		letter-spacing: 0.1em;
	}
}

/*
** Vertical Margins and Paddings
*/
/*same top and bottom paddings for sections*/
.section_padding_0 > [class*="container"] {
	padding: 0;
}

.section_padding_15 > [class*="container"] {
	padding-top: 15px;
	padding-bottom: 15px;
}

.section_padding_25 > [class*="container"] {
	padding-top: 25px;
	padding-bottom: 25px;
}

.section_padding_50 > [class*="container"] {
	padding-top: 50px;
	padding-bottom: 50px;
}

.section_padding_75 > [class*="container"] {
	padding-top: 75px;
	padding-bottom: 75px;
}

.section_padding_100 > [class*="container"] {
	padding-top: 100px;
	padding-bottom: 100px;
}

/*top paddings for sections*/
.section_padding_top_0 > [class*="container"] {
	padding-top: 0;
}

.section_padding_top_5 > [class*="container"] {
	padding-top: 5px;
}

.section_padding_top_15 > [class*="container"] {
	padding-top: 15px;
}

.section_padding_top_25 > [class*="container"] {
	padding-top: 25px;
}

.section_padding_top_30 > [class*="container"] {
	padding-top: 30px;
}

.section_padding_top_40 > [class*="container"] {
	padding-top: 40px;
}

.section_padding_top_50 > [class*="container"] {
	padding-top: 50px;
}

.section_padding_top_65 > [class*="container"] {
	padding-top: 65px;
}

.section_padding_top_75 > [class*="container"] {
	padding-top: 75px;
}

.section_padding_top_100 > [class*="container"] {
	padding-top: 100px;
}

.section_padding_top_130 > [class*="container"] {
	padding-top: 130px;
}

.section_padding_top_150 > [class*="container"] {
	padding-top: 150px;
}

@media (max-width: 991px) {
	.section_padding_top_130 > [class*="container"] {
		padding-top: 100px;
	}

	.section_padding_top_150 > [class*="container"] {
		padding-top: 100px;
	}
}

/*bottom paddings for sections*/
.section_padding_bottom_0 > [class*="container"] {
	padding-bottom: 0;
}

.section_padding_bottom_5 > [class*="container"] {
	padding-bottom: 5px;
}

.section_padding_bottom_15 > [class*="container"] {
	padding-bottom: 15px;
}

.section_padding_bottom_25 > [class*="container"] {
	padding-bottom: 25px;
}

.section_padding_bottom_30 > [class*="container"] {
	padding-bottom: 30px;
}

.section_padding_bottom_40 > [class*="container"] {
	padding-bottom: 40px;
}

.section_padding_bottom_50 > [class*="container"] {
	padding-bottom: 50px;
}

.section_padding_bottom_65 > [class*="container"] {
	padding-bottom: 65px;
}

.section_padding_bottom_75 > [class*="container"] {
	padding-bottom: 75px;
}

.section_padding_bottom_100 > [class*="container"] {
	padding-bottom: 100px;
}

.section_padding_bottom_130 > [class*="container"] {
	padding-bottom: 130px;
}

.section_padding_bottom_150 > [class*="container"] {
	padding-bottom: 150px;
}

@media (max-width: 991px) {
	.section_padding_bottom_130 > [class*="container"] {
		padding-top: 100px;
	}

	.section_padding_bottom_150 > [class*="container"] {
		padding-top: 100px;
	}
}

[class*="col-"] {
	margin-top: 10px;
	margin-bottom: 10px;
}

form [class*="col-"] {
	margin-top: 0;
}

[class*="col-"] > .checkbox,
[class*="col-"] > .radio {
	margin: 0;
}

@media (max-width: 767px) {
	aside {
		margin-top: 80px !important;
	}
}

header [class*="col-"] {
	margin-top: 0;
	margin-bottom: 0;
	min-height: 0;
}

.columns_margin_0 [class*="col-"] {
	margin-top: 0;
	margin-bottom: 0;
}

.columns_padding_1 [class*="col-"] {
	margin-top: 0;
	margin-bottom: 1px;
}

.columns_padding_2 [class*="col-"] {
	margin-top: 1px;
	margin-bottom: 1px;
}

.columns_padding_5 [class*="col-"] {
	margin-top: 5px;
	margin-bottom: 5px;
}

.columns_padding_10 [class*="col-"] {
	margin-top: 10px;
	margin-bottom: 10px;
}

.columns_padding_0 [class*="col-"] {
	padding: 0;
}

.columns_padding_1 [class*="col-"] {
	padding-left: 0;
	padding-right: 1px;
}

.columns_padding_2 [class*="col-"] {
	padding-left: 1px;
	padding-right: 1px;
}

.columns_padding_5 [class*="col-"] {
	padding-left: 5px;
	padding-right: 5px;
}

.columns_padding_10 [class*="col-"] {
	padding-left: 10px;
	padding-right: 10px;
}

.columns_padding_5 > .container {
	padding-left: 25px;
	padding-right: 25px;
}

.columns_padding_10 > .container {
	padding-left: 20px;
	padding-right: 20px;
}

.columns_padding_10 > .container-fluid {
	padding-left: 30px;
	padding-right: 30px;
}

.columns_padding_0 > .container-fluid {
	padding-left: 0;
	padding-right: 0;
}

.row.columns_padding_0,
.columns_padding_0 .row {
	margin: 0;
}

.row.columns_padding_1,
.columns_padding_1 .row {
	margin-left: 0;
	margin-right: -1px;
}

.row.columns_padding_2,
.columns_padding_2 .row {
	margin-left: -1px;
	margin-right: -1px;
}

.row.columns_padding_5,
.columns_padding_5 .row {
	margin-left: -5px;
	margin-right: -5px;
}

.row.columns_padding_10,
.columns_padding_10 .row {
	margin-left: -10px;
	margin-right: -10px;
}

@media (min-width: 500px) {
	.columns_padding_25 > [class*="container"] > .row {
		margin-right: -25px;
		margin-left: -25px;
	}

	.columns_padding_25 > [class*="container"] > .row > [class*="col-"] {
		padding-left: 25px;
		padding-right: 25px;
	}

	.row.columns_padding_25 {
		margin-left: -25px;
		margin-right: -25px;
	}

	.row.columns_padding_25 > [class*="col-"] {
		padding-left: 25px;
		padding-right: 25px;
	}
}

/*top and bottom margins for columns inside sections and rows */
.columns_margin_top_0 [class*="col-"],
.row.columns_margin_top_0 [class*="col-"] {
	margin-top: 0;
}

.columns_margin_top_5 [class*="col-"],
.row.columns_margin_top_5 [class*="col-"] {
	margin-top: 5px;
}

.columns_margin_top_10 [class*="col-"],
.row.columns_margin_top_10 [class*="col-"] {
	margin-top: 10px;
}

.columns_margin_top_15 [class*="col-"],
.row.columns_margin_top_15 [class*="col-"] {
	margin-top: 15px;
}

.columns_margin_top_20 [class*="col-"],
.row.columns_margin_top_20 [class*="col-"] {
	margin-top: 20px;
}

.columns_margin_top_30 [class*="col-"],
.row.columns_margin_top_30 [class*="col-"] {
	margin-top: 30px;
}

.columns_margin_bottom_0 [class*="col-"],
.row.columns_margin_bottom_0 [class*="col-"] {
	margin-bottom: 0;
}

.columns_margin_bottom_5 [class*="col-"],
.row.columns_margin_bottom_5 [class*="col-"] {
	margin-bottom: 5px;
}

.columns_margin_bottom_10 [class*="col-"],
.row.columns_margin_bottom_10 [class*="col-"] {
	margin-bottom: 10px;
}

.columns_margin_bottom_15 [class*="col-"],
.row.columns_margin_bottom_15 [class*="col-"] {
	margin-bottom: 15px;
}

.columns_margin_bottom_20 [class*="col-"],
.row.columns_margin_bottom_20 [class*="col-"] {
	margin-bottom: 20px;
}

.columns_margin_bottom_30 [class*="col-"],
.row.columns_margin_bottom_30 [class*="col-"] {
	margin-bottom: 30px;
}

.columns_margin_bottom_40 [class*="col-"],
.row.columns_margin_bottom_40 [class*="col-"] {
	margin-bottom: 40px;
}

@media (min-width: 768px) {
	.container-fluid {
		padding-right: 30px;
		padding-left: 30px;
	}
}

@media (min-width: 1200px) {
	.container-fluid {
		padding-right: 50px;
		padding-left: 50px;
	}
}

@media (min-width: 1450px) {
	.container-fluid {
		padding-right: 80px;
		padding-left: 80px;
	}
}

/* vertical alignmetn in columns */
.table_section [class*="container"] {
	height: 100%;
	padding-left: 0;
	padding-right: 0;
}

.columns_padding_0.table_section [class*="container"] {
	padding-right: 15px;
	padding-left: 15px;
}

@media (min-width: 768px) {
	.table_section .container-fluid {
		padding-right: 15px;
		padding-left: 15px;
	}
}

@media (min-width: 1200px) {
	.table_section .container-fluid {
		padding-right: 35px;
		padding-left: 35px;
	}
}

@media (min-width: 1450px) {
	.table_section .container-fluid {
		padding-right: 65px;
		padding-left: 65px;
	}
}

.columns_padding_0.table_section .container-fluid {
	padding-right: 15px;
	padding-left: 15px;
}

@media (min-width: 768px) {
	.columns_padding_0.table_section .container-fluid {
		padding-right: 30px;
		padding-left: 30px;
	}
}

@media (min-width: 1200px) {
	.columns_padding_0.table_section .container-fluid {
		padding-right: 50px;
		padding-left: 50px;
	}
}

@media (min-width: 1450px) {
	.columns_padding_0.table_section .container-fluid {
		padding-right: 80px;
		padding-left: 80px;
	}
}

.table_section .row {
	display: table;
	min-width: 100%;
	height: 100%;
	margin-left: 0;
	margin-right: 0;
}

.table_section [class*="col-"] {
	display: table-cell;
	vertical-align: middle;
	float: none;
}

@media screen and (max-width: 767px) {
	.table_section .row,
  .table_section [class*="col-"] {
		display: block;
	}
}

@media screen and (max-width: 991px) {
	.table_section.table_section_md .row,
  .table_section.table_section_md [class*="col-"] {
		display: block;
	}
}

@media screen and (max-width: 1199px) {
	.table_section.table_section_lg .row,
  .table_section.table_section_lg [class*="col-"] {
		display: block;
	}
}

@media screen and (min-width: 1200px) {
  /* overlap featured person */
	.top-overlap {
		margin-top: -150px;
	}

	.top-overlap-small {
		margin-top: -100px;
	}
}

.section_full_height {
	min-height: 100vh;
}

.flex-row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.flex-row > [class*='col-'] {
	min-width: 300px;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.flex-row > [class*='col-'] > * {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.flex-row.flex-row-vertical-centered > [class*='col-'] > * {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

/*
** Common Sections Styles
*/
/*parallax*/
.background_cover {
	background-size: cover;
}

.parallax {
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 0;
	background-repeat: no-repeat;
	position: relative;
}

@media (max-width: 767px) {
	.parallax {
		background-size: auto auto;
	}
}

.parallax.ls:after {
	background-color: #ffffff;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=92)";
	filter: alpha(opacity=80);
	opacity: 0.8;
}

.parallax.ls.ms:after {
	background-color: #f2f2f2;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=88)";
	filter: alpha(opacity=88);
	opacity: 0.88;
}

.parallax.cs:after {
	background-color: #91d0cc;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=90)";
	filter: alpha(opacity=87);
	opacity: 0.87;
}

.darken_gradient.parallax.cs:after {
	background: -webkit-linear-gradient(rgba(14, 33, 32, 0.15), #91d0cc 10%, #91d0cc 90%, rgba(14, 33, 32, 0.15));
	background: linear-gradient(rgba(14, 33, 32, 0.15), #91d0cc 10%, #91d0cc 90%, rgba(14, 33, 32, 0.15));
	background-color: #91d0cc;
}

.parallax.cs.main_color2:after {
	background-color: #d9be93;
}

.parallax.cs.main_color3:after {
	background-color: #e7003e;
}

.parallax.ds:after,
.background_cover:after {
	background-color: #444444;
	opacity: 0.8;
}

.parallax.ds.ms:after {
	display: none;
	background-color: #444444;
}

.parallax.abstract_background.ls:after,
.parallax.abstract_background.ds:after {
	display: none;
}

.texture_bg {
	background-size: cover;
	background-position: 50% 0;
	background-repeat: no-repeat;
	position: relative;
}

.muted_section:before {
	background-color: rgba(68, 68, 68, 0.1);
	opacity: 1;
}

.parallax > *,
.muted_section > *,
.radial_gradient > *,
.gradient > *,
.vertical_gradient > *,
.darken_gradient > *,
.diagonal_section > *,
.background_cover > * {
	z-index: 4;
	position: relative;
}

.radial_gradient {
	position: relative;
	overflow: hidden;
}

.gradient,
.muted_section,
.vertical_gradient,
.darken_gradient,
.background_cover {
	position: relative;
}

.muted_section:before,
.muted_section:after,
.parallax:before,
.parallax:after,
.gradient:before,
.gradient:after,
.vertical_gradient:before,
.vertical_gradient:after,
.darken_gradient:before,
.darken_gradient:after,
.background_cover:before,
.background_cover:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
}

.no_overlay:before, .no_overlay:after {
	display: none;
}

.muted_section:before,
.muted_section:after,
.parallax:before,
.parallax:after,
.background_cover:after {
	opacity: 0.8;
}

.header_gradient,
.gradient:before,
.dark_gradient:before {
	background: #91d0cc;
	background: -webkit-gradient(linear, left top, right top, color-stop(0%, #91d0cc), color-stop(100%, #d9be93));
	background: -webkit-linear-gradient(left, #91d0cc 0%, #d9be93 100%);
	background: linear-gradient(to right, #91d0cc 0%, #d9be93 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$mainColor', endColorstr='$mainColor2',GradientType=1 );
}

.vertical_gradient:before {
	background: #91d0cc;
  /* Old browsers */
  /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #91d0cc 0%, #d9be93 100%);
  /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #91d0cc 0%, #d9be93 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$mainColor', endColorstr='$mainColor2',GradientType=0 );
  /* IE6-9 */
}

.darken_gradient:before {
	background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.3) 100%);
	background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.3) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000',GradientType=0 );
}

.with_top_border,
.with_bottom_border,
.with_top_border_container .container,
.with_bottom_border_container .container {
	position: relative;
}

.with_top_border:before,
.with_bottom_border:after,
.with_top_border_container .container:before,
.with_bottom_border_container .container:after {
	content: '';
	display: block;
	position: absolute;
	height: 1px;
	left: 0;
	right: 0;
	top: 0;
	background-color: #ebebeb;
	z-index: 1;
}

.cs .with_top_border:before, .cs
.with_bottom_border:after, .cs
.with_top_border_container .container:before, .cs
.with_bottom_border_container .container:after {
	background-color: rgba(255, 255, 255, 0.3);
}

.with_top_border_container .container:before,
.with_bottom_border_container .container:after {
	right: 15px;
	left: 15px;
}

.with_bottom_border:after,
.with_bottom_border_container .container:after {
	top: auto;
	bottom: 0;
}

.bg_image,
.bg_image:after {
	background-image: url(../img/background_section.png);
}

/* Hero text in sections */
.section_header {
	margin: 10px 0 24px;
	font-size: 45px;
}

.section_header.big {
	font-size: 60px;
}

.section_header.small {
	font-size: 35px;
}

h3 + .section_header,
.big + .section_header {
	margin-top: -17px;
}

.section_header + h3 {
	margin: -22px 0 44px;
	font-size: 30px;
}

.section_header + p {
	margin: 0 0 45px;
	font-size: 16px;
	line-height: 1.5em;
	color: #b4b4b4;
}

.section_header.big + p {
	margin-bottom: 30px;
}

@media (min-width: 992px) {
	.text-center .section_header + p,
  .section_header.text-center + p {
		max-width: 67%;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
}

@media (max-width: 991px) {
	.section_header {
		font-size: 30px;
		margin-top: 0;
	}

	.section_header.text-center + p,
  .text-center .section_header + p {
		max-width: 100%;
	}

	.section_header + p {
		font-size: 1.1em;
		letter-spacing: 0;
	}
}

@media (max-width: 767px) {
	.section_header.big {
		font-size: 35px;
		margin-top: 0;
	}
}

/*page preloader*/
.preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.99);
	z-index: 13000;
	height: 100%;
}

.no-js .preloader,
.lt-ie9 .preloader,
.lt-ie10 .preloader {
	display: none;
}

.preloader_image {
	width: 100px;
	height: 100px;
	position: absolute;
	left: 50%;
	top: 50%;
	background: url(../img/preloader.gif) no-repeat 50% 50% transparent;
	margin: -50px 0 0 -50px;
}

/* animated elements */
.to_animate {
	visibility: hidden;
}

.no-js .to_animate,
.lt-ie10 .to_animate,
.lt-ie9 .to_animate {
	visibility: visible;
}

.animated {
  /*IE7 fix*/
	visibility: visible !important;
}

/* boxed layout */
#box_wrapper, #canvas {
	overflow: visible;
	position: relative;
}

#box_wrapper > section,
#box_wrapper > div > section {
	clear: both;
}

#canvas.boxed {
	padding: 1px 0;
}

body.boxed {
	position: static;
	width: auto;
	height: auto;
}

.pattern1 {
	background-image: url(../img/pattern1.png);
}

.pattern2 {
	background-image: url(../img/pattern2.png);
}

.pattern3 {
	background-image: url(../img/pattern3.png);
}

.pattern4 {
	background-image: url(../img/pattern4.png);
}

.pattern5 {
	background-image: url(../img/pattern5.png);
}

.pattern6 {
	background-image: url(../img/pattern6.png);
}

.pattern7 {
	background-image: url(../img/pattern7.png);
}

.pattern8 {
	background-image: url(../img/pattern8.png);
}

.pattern9 {
	background-image: url(../img/pattern9.png);
}

.pattern10 {
	background-image: url(../img/pattern10.png);
}

.pattern11 {
	background-image: url(../img/pattern11.png);
}

.boxed .container,
.boxed .container-fluid,
.boxed .container-left-half,
.boxed .container-right-half {
	max-width: 100%;
}

.boxed .container,
.boxed .container-left-half,
.boxed .container-right-half {
	padding-left: 30px;
	padding-right: 30px;
}

@media (max-width: 500px) {
	.boxed .container {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (min-width: 1400px) {
	.boxed #box_wrapper.container {
		width: 1370px;
	}
}

@media (min-width: 1590px) {
	.boxed #box_wrapper.container {
		width: 1560px;
	}
}

.boxed #box_wrapper.container {
	padding: 0;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.boxed #box_wrapper.container.top-bottom-margins {
	margin-top: 150px;
	margin-bottom: 120px;
}

/* float columns offset fix */
@media (min-width: 1200px) {
	.clear-lg {
		clear: both;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.clear-md {
		clear: both;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.clear-sm {
		clear: both;
	}
}

@media (max-width: 767px) {
	.clear-xs {
		clear: both;
	}
}

/*
**utilites
*/
/*
**Helper Utilites
*/
/* layout */
.clear, .clearfix {
	clear: both;
}

.alignleft,
.alignright {
	display: block;
	margin-bottom: 15px;
}

@media (min-width: 768px) {
	.alignleft {
		float: left;
		margin: 5px 30px 15px 0;
		max-width: 50%;
	}

	.alignright {
		float: right;
		margin: 5px 0 15px 30px;
		max-width: 50%;
	}
}

.round {
	border-radius: 50%;
}

.inline-block {
	display: inline-block;
	max-width: 100%;
}

.with_padding {
	padding: 35px 20px;
}

@media (min-width: 400px) {
	.with_padding {
		padding: 50px 35px;
	}
}

.with_padding_small {
	padding: 30px;
}

.with_background {
	background-color: #f5f5f5;
	border-radius: 2px;
}

.muted_background {
	background-color: rgba(68, 68, 68, 0.06);
}

.with_border {
	border-radius: 2px;
	border: 1px solid #ebebeb;
}

.with_shadow {
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.hover_shadow {
	-webkit-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
}

.hover_shadow:hover {
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.with_icon {
	position: relative;
}

.with_icon:before {
	content: "";
	position: absolute;
	top: -37px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 13px;
	height: 16px;
	background-image: url("../images/icon.png");
}

.no_top_border {
	border-top: none;
}

.no_bottom_border {
	border-bottom: none;
}

.divided_content > * {
	display: inline-block;
	margin: 0 -2px;
}

.divided_content > *:first-child {
	margin-left: 0;
}

.divided_content > *:last-child {
	margin-right: 0;
}

.divided_content > * + *:before {
	content: "";
	display: inline-block;
	position: relative;
	top: -1px;
	width: 1px;
	height: 8px;
	margin: 0 11px 0 15px;
	background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
	.divided_content.wide_divider > * + *:before {
		margin: 0 28px 0 32px;
	}
}

.bottom-border {
	position: relative;
}

.bottom-border:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 2px;
	background-color: rgba(0, 0, 0, 0.1);
}

.before_cover,
.after_cover {
	overflow: hidden;
}

.before_cover,
.after_cover,
.before_cover > *,
.after_cover > * {
	position: relative;
	z-index: 2;
}

.before_cover:before,
.after_cover:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.top-corner {
	position: absolute;
	top: 0;
	left: 0;
	line-height: 1;
}

.big {
	font-size: 42px;
	line-height: 0.9;
}

/* borders, colors and backgrouns */
.response,
.highlight {
	color: #91d0cc;
	border-color: #91d0cc;
}

.highlight2 {
	color: #d9be93;
	border-color: #d9be93;
}

.highlight3 {
	color: #e7003e;
	border-color: #e7003e;
}

.red, .required {
	color: #fa5c5d;
}

.black {
	color: #1a1a1a;
	border-color: #1a1a1a;
}

.grey {
	color: #444444;
	border-color: #444444;
}

.lightgreylinks a {
	color: #e5e5e5;
}

.lightgreylinks a.social-icon {
	color: #e5e5e5;
}

.greylinks a {
	color: #787878;
}

.darklinks a {
	color: #444444;
}

.thin {
	font-weight: 300;
}

.bold {
	font-weight: 700;
}

.quotesign {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 50px;
	line-height: 1;
}

.quotesign:before {
	font-size: 150px;
	font-family: 'PT Sans', sans-serif;
	content: '‘‘';
	position: absolute;
	left: 0;
	top: 0;
	line-height: 1;
}

.success_color {
	color: #4db19e;
}

.info_color {
	color: #007ebd;
}

.warning_color {
	color: #eeb269;
}

.danger_color {
	color: #dc5753;
}

[class*='_bg_color'] {
	color: #ffffff;
}

[class*='_bg_color'] h1, [class*='_bg_color'] h2, [class*='_bg_color'] h3, [class*='_bg_color'] h4, [class*='_bg_color'] h5, [class*='_bg_color'] h6 {
	color: #ffffff;
}

.main_bg_color {
	background-color: #91d0cc;
}

.main_bg_color2 {
	background-color: #d9be93;
}

.main_bg_color3 {
	background-color: #e7003e;
}

.dark_bg_color {
	background-color: #1a1a1a;
}

.darkblue_bg_color {
	color: #ffffff;
	background-color: #253138;
}

.darkgrey_bg_color {
	color: #ffffff;
	background-color: #444444;
}

.success_bg_color {
	background-color: #4db19e;
}

.info_bg_color {
	background-color: #007ebd;
}

.warning_bg_color {
	background-color: #eeb269;
}

.danger_bg_color {
	background-color: #dc5753;
}

.light_bg_color {
	background-color: #ffffff;
}

/*dividers, margins and paddings*/
.divider_20 {
	margin: 20px 0;
}

.divider_30 {
	margin: 30px 0;
}

.divider_40 {
	margin: 40px 0;
}

.divider_30_1 {
	text-align: left;
	margin-left: 0;
	margin-right: auto;
	width: 30px;
}

.text-center .divider_30_1 {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.text-right .divider_30_1 {
	text-align: right;
	margin-left: auto;
	margin-right: 0;
}

.margin_0 {
	margin: 0 !important;
}

.topmargin_0 {
	margin-top: 0 !important;
}

.topmargin_1 {
	margin-top: 1px;
}

.topmargin_10 {
	margin-top: 10px;
}

.topmargin_20 {
	margin-top: 20px;
}

.topmargin_30 {
	margin-top: 30px;
}

.topmargin_40 {
	margin-top: 40px;
}

.topmargin_50 {
	margin-top: 50px;
}

.topmargin_60 {
	margin-top: 60px;
}

.bottommargin_0 {
	margin-bottom: 0 !important;
}

.bottommargin_5 {
	margin-bottom: 5px;
}

.bottommargin_10 {
	margin-bottom: 10px;
}

.bottommargin_20 {
	margin-bottom: 20px;
}

.bottommargin_30 {
	margin-bottom: 30px;
}

.bottommargin_40 {
	margin-bottom: 40px;
}

.bottommargin_60 {
	margin-bottom: 60px;
}

.leftmargin_10 {
	margin-left: 10px;
}

.leftmargin_20 {
	margin-left: 20px;
}

.leftmargin_30 {
	margin-left: 30px;
}

.leftmargin_40 {
	margin-left: 40px;
}

.leftmargin_50 {
	margin-left: 50px;
}

.leftmargin_60 {
	margin-left: 60px;
}

.rightmargin_10 {
	margin-right: 10px;
}

.rightmargin_20 {
	margin-right: 20px;
}

.rightmargin_30 {
	margin-right: 30px;
}

.rightmargin_40 {
	margin-right: 40px;
}

.rightmargin_50 {
	margin-right: 50px;
}

.rightmargin_60 {
	margin-right: 60px;
}

.padding_0 {
	padding: 0;
}

.padding_10 {
	padding: 10px;
}

.padding_20 {
	padding: 20px;
}

.padding_30 {
	padding: 30px;
}

.padding_40 {
	padding: 40px;
}

.toppadding_10 {
	padding-top: 10px;
}

.toppadding_20 {
	padding-top: 20px;
}

.toppadding_30 {
	padding-top: 30px;
}

.toppadding_40 {
	padding-top: 40px;
}

.toppadding_50 {
	padding-top: 50px;
}

.toppadding_60 {
	padding-top: 60px;
}

.bottompadding_10 {
	padding-bottom: 10px;
}

.bottompadding_20 {
	padding-bottom: 20px;
}

.bottompadding_30 {
	padding-bottom: 30px;
}

.bottompadding_40 {
	padding-bottom: 40px;
}

.bottompadding_50 {
	padding-bottom: 50px;
}

.bottompadding_60 {
	padding-bottom: 60px;
}

.leftpadding_10 {
	padding-left: 10px;
}

.leftpadding_20 {
	padding-left: 20px;
}

.leftpadding_30 {
	padding-left: 30px;
}

.leftpadding_40 {
	padding-left: 40px;
}

.leftpadding_50 {
	padding-left: 50px;
}

.leftpadding_60 {
	padding-left: 60px;
}

.rightpadding_10 {
	padding-right: 10px;
}

.rightpadding_20 {
	padding-right: 20px;
}

.rightpadding_30 {
	padding-right: 30px;
}

.rightpadding_40 {
	padding-right: 40px;
}

.rightpadding_50 {
	padding-right: 50px;
}

.rightpadding_60 {
	padding-right: 60px;
}

.fontsize_16 {
	font-size: 16px;
	line-height: 23px;
}

.fontsize_18 {
	font-size: 18px;
}

.fontsize_20 {
	font-size: 20px;
}

.fontsize_24 {
	font-size: 24px;
}

.fontsize_28 {
	font-size: 28px;
}

.fontsize_32 {
	font-size: 32px;
}

.playfair {
	font-family: 'Playfair Display', serif;
}

/*alignment*/
.display_table {
	display: table;
	min-width: 100%;
	height: 100%;
	margin: auto;
	border-collapse: collapse;
}

.display_table_cell {
	display: table-cell;
	vertical-align: middle;
	float: none;
	overflow: hidden;
	zoom: 1;
	box-sizing: border-box;
}

@media (min-width: 1200px) {
	.pull-lg-right {
		float: right;
	}

	.pull-lg-left {
		float: left;
	}

	.text-lg-right {
		text-align: right;
	}

	.text-lg-left {
		text-align: left;
	}

	.text-lg-center {
		text-align: center;
	}

	.display_table_lg {
		display: table;
		min-width: 100%;
		height: 100%;
		margin: auto;
		border-collapse: collapse;
	}

	.display_table_cell_lg {
		display: table-cell;
		vertical-align: middle;
		float: none;
		overflow: hidden;
		zoom: 1;
		box-sizing: border-box;
	}
}

@media (min-width: 992px) {
	.pull-md-right {
		float: right;
	}

	.pull-md-left {
		float: left;
	}

	.text-md-right {
		text-align: right;
	}

	.text-md-left {
		text-align: left;
	}

	.text-md-center {
		text-align: center;
	}

	.display_inline_md {
		display: inline;
	}

	.display_table_md {
		display: table;
		min-width: 100%;
		height: 100%;
		margin: auto;
		border-collapse: collapse;
	}

	.display_table_cell_md {
		display: table-cell;
		vertical-align: middle;
		float: none;
		overflow: hidden;
		zoom: 1;
		box-sizing: border-box;
	}
}

@media (min-width: 768px) {
	.pull-sm-right {
		float: right;
	}

	.pull-sm-left {
		float: left;
	}

	.text-sm-right {
		text-align: right;
	}

	.text-sm-left {
		text-align: left;
	}

	.text-sm-center {
		text-align: center;
	}

	.display_table_sm {
		display: table;
		min-width: 100%;
		height: 100%;
		margin: auto;
		border-collapse: collapse;
	}

	.display_table_cell_sm {
		display: table-cell;
		vertical-align: middle;
		float: none;
		overflow: hidden;
		zoom: 1;
		box-sizing: border-box;
	}
}

@media (max-width: 767px) {
	.text-xs-right {
		text-align: right;
	}

	.text-xs-left {
		text-align: left;
	}

	.text-xs-center {
		text-align: center;
	}
}

@media (max-width: 500px) {
	.hidden-xxs {
		display: none;
	}
}

.content-justify {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.inline-dropdown {
	list-style: none;
	margin: 0;
	padding: 0;
}

.inline-dropdown > li {
	display: inline-block;
	margin: 0 -2px;
}

.small-text {
	font-weight: 700;
	font-size: 14px;
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.small-text.extra-letter-spacing {
		letter-spacing: 1em;
	}
}

h5.small-text, h4.small-text {
	font-family: 'Muli', sans-serif;
}

/* media items - common side and vertical items */
.with_border .item-media,
.with_border .entry-thumbnail {
	margin: -1px -1px -2px -1px;
	z-index: 1;
}

.with_border.full-padding .item-media {
	margin: 0;
}

/* side and vertical items paddings */
.full-padding {
	padding: 55px 35px;
}

.content-padding .item-content {
	padding: 35px 20px;
}

@media (min-width: 400px) {
	.content-padding .item-content {
		padding: 35px;
	}
}

.side-item .row {
	margin: 0;
}

.side-item .row [class*="col-"] {
	padding: 0;
	margin: 0;
}

.side-item .item-title + .item-content {
	padding-top: 20px;
}

.side-item [class*="col-"] > .item-title {
	padding: 15px 30px;
}

.side-item [class*="col-"] > .item-title h2, .side-item [class*="col-"] > .item-title h3, .side-item [class*="col-"] > .item-title h4 {
	margin: 0;
}

.side-item.with_border [class*="col-"] > .item-title {
	margin: -1px;
}

.side-item .small-text {
	margin-bottom: 10px;
}

/*-xs- side-item paddings and margins*/
@media (max-width: 767px) {
	.no-content-padding [class*='col-xs-'] .item-content,
  .full-padding [class*='col-xs-'] .item-content {
		padding-left: 30px;
	}

	.no-content-padding [class*='col-xs-'][class*='col-xs-pull'] .item-content,
  .full-padding [class*='col-xs-'][class*='col-xs-pull'] .item-content {
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-xs-12 .item-content,
  .full-padding .col-xs-12 .item-content {
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

/*-sm- side-item paddings and margins*/
@media (min-width: 768px) {
	.no-content-padding [class*='col-sm-'] .item-content,
  .full-padding [class*='col-sm-'] .item-content {
		padding-left: 30px;
	}

	.no-content-padding [class*='col-sm-'][class*='col-sm-pull'] .item-content,
  .full-padding [class*='col-sm-'][class*='col-sm-pull'] .item-content {
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-sm-12 .item-content,
  .full-padding .col-sm-12 .item-content {
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

/*-md- side-item paddings and margins*/
@media (min-width: 992px) {
	.no-content-padding [class*='col-md-'] .item-content,
  .full-padding [class*='col-md-'] .item-content {
		padding-left: 30px;
	}

	.no-content-padding [class*='col-md-'][class*='col-md-pull'] .item-content,
  .full-padding [class*='col-md-'][class*='col-md-pull'] .item-content {
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-md-12 .item-content,
  .full-padding .col-md-12 .item-content {
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

/*-lg- side-item paddings and margins*/
@media (min-width: 1200px) {
	.no-content-padding [class*='col-lg-'] .item-content,
  .full-padding [class*='col-lg-'] .item-content {
		padding-left: 30px;
	}

	.no-content-padding [class*='col-lg-'][class*='col-lg-pull'] .item-content,
  .full-padding [class*='col-lg-'][class*='col-lg-pull'] .item-content {
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-lg-12 .item-content,
  .full-padding .col-lg-12 .item-content {
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

.vertical-item .item-content {
	padding-top: 35px;
}

.vertical-item h3 {
	font-size: 35px;
	line-height: 1.1;
}

.vertical-item h4 {
	font-size: 25px;
}

.vertical-item .list1 {
	text-align: left;
	margin: 0;
}

.vertical-item .list1 + .social-icons {
	margin-top: 30px;
}

.vertical-item.content-padding .item-content {
	padding: 30px 20px;
}

@media (min-width: 400px) {
	.vertical-item.content-padding .item-content {
		padding: 50px 35px;
	}
}

.vertical-item.content-absolute {
	position: relative;
}

.vertical-item.content-absolute .item-content {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 20px 30px 20px;
	z-index: 2;
}

.vertical-item.content-absolute.vertical-center .item-content {
	top: 0;
	line-height: 1.1;
}

.vertical-item + .item-title {
	padding: 10px 30px;
}

.vertical-item + .item-title h2, .vertical-item + .item-title h3, .vertical-item + .item-title h4 {
	margin: 0;
}

.side-item.content-padding .item-content {
	padding: 35px 20px;
}

@media (min-width: 400px) {
	.side-item.content-padding .item-content {
		padding: 35px;
	}
}

/* item meta */
.item-meta {
	margin-bottom: 10px;
}

.item-meta i {
	width: 1em;
	text-align: center;
	position: relative;
	font-size: 20px;
	top: 2px;
}

.item-meta:last-child {
	margin-bottom: 0;
}

/* item media links */
.item-media {
	position: relative;
	overflow: hidden;
}

.item-media img {
	width: 100%;
}

.media-links {
	text-align: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.media-links div {
	position: absolute;
	top: 50%;
	width: 100%;
	margin-top: -25px;
	z-index: 4;
}

.media-links:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.5);
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

[class*="-item"]:hover .media-links:before {
	filter: alpha(opacity=50);
	opacity: 0.5;
}

.media-links a.abs-link {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
}

[class*="-item"]:hover .media-links a.abs-link {
	opacity: 1;
}

.media-links a.abs-link:before {
	content: '+';
	font-size: 60px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -15px;
	margin-left: -23px;
	text-align: center;
	color: #91d0cc;
}

[class*="-item"] .links-wrap a {
	position: relative;
	width: 60px;
	height: 60px;
	line-height: 56px;
	top: -100px;
	border: 2px solid transparent;
	display: inline-block;
	z-index: 5;
	font-size: 16px;
	text-align: center;
	filter: alpha(opacity=0);
	opacity: 0;
	transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

[class*="-item"] .links-wrap a.p-view {
	color: #ffffff;
	background-color: #444444;
	border-color: #444444;
}

[class*="-item"] .links-wrap a.p-view:hover {
	background-color: #91d0cc;
	border-color: #91d0cc;
}

[class*="-item"] .links-wrap a.p-link {
	color: #444444;
	background-color: #ffffff;
	border-color: #ffffff;
	transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
}

[class*="-item"] .links-wrap a.p-link:hover {
	color: #ffffff;
	background-color: #91d0cc;
	border-color: #91d0cc;
}

[class*="-item"] .links-wrap a:before {
	font-family: 'FontAwesome';
	content: "\f002";
}

[class*="-item"] .links-wrap a.p-link:before {
	content: "\f041";
}

[class*="-item"]:hover .links-wrap a {
	top: 0px;
	filter: alpha(opacity=100);
	opacity: 1;
}

[class*="-item"]:hover .media-links div {
	filter: alpha(opacity=100);
	opacity: 1;
}

/* item icons */
.item-icons {
	display: table;
	width: 100%;
	border-top: 1px solid rgba(128, 128, 128, 0.1);
}

.item-icons i {
	font-size: 14px;
}

.item-icons > div {
	padding: 24px 50px 24px;
	display: table-cell;
	width: 400px;
	white-space: nowrap;
}

@media (max-width: 1199px) {
	.item-icons > div {
		padding-left: 30px;
		padding-right: 30px;
	}
}

.item-icons > div.text-right {
	padding-left: 0;
}

.item-icons [data-id] {
	display: none;
}

/*
** Header
*/
.header_white {
	color: #787878;
	background-color: #ffffff;
}

.header_white.affix {
	box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
}

.header_white a.logo {
	color: #91d0cc;
}

.header_darkgrey {
	background-color: #91d0cc;
}

.header_darkgrey.header_transparent {
	background-color: transparent;
}

.header_darkgrey.header_half_transparent {
	background-color: rgba(68, 68, 68, 0.5);
}

.header_darkgrey.header_transparent.affix, .header_darkgrey.header_half_transparent.affix {
	background-color: #444444;
}

.header_darkgrey.affix {
	box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.2);
}

.header_darkgrey a[class*='soc-'] {
	color: #ffffff;
}

.header_darkgrey .logo-meta,
.header_darkgrey .grey {
	color: #ffffff;
}

.header_darkgrey.header_darkblue {
	background-color: #253138;
}

.header_darkgrey .divided_content > * + *:before {
	background-color: rgba(255, 255, 255, 0.3);
}

/* topline buttons */
.currency-dropdown .dropdown-menu {
	min-width: 0;
	padding: 20px 10px;
}

.currency-dropdown .dropdown-menu > li > a {
	color: inherit;
	padding: 5px 20px;
}

.currency-dropdown .dropdown-menu > li > a:hover,
.currency-dropdown .dropdown-menu > li.active-currency {
	color: #444444;
}

.header-notes-dropdown .dropdown-menu,
.login-dropdown .dropdown-menu {
	min-width: 400px;
}

.header-notes-dropdown .dropdown-menu .list-unstyled,
.login-dropdown .dropdown-menu .list-unstyled {
	padding: 30px;
}

.header-notes-dropdown .dropdown-menu .list1,
.login-dropdown .dropdown-menu .list1 {
	padding: 20px 0;
}

.header-notes-dropdown .dropdown-menu .list1 .media-left img,
.login-dropdown .dropdown-menu .list1 .media-left img {
	max-width: 40px;
	border-radius: 50%;
}

.header-notes-dropdown .dropdown-menu .list1 li,
.login-dropdown .dropdown-menu .list1 li {
	padding-left: 30px;
	padding-right: 30px;
}

.header-notes-dropdown .dropdown-menu .list1 h5,
.login-dropdown .dropdown-menu .list1 h5 {
	margin-top: 1em;
	font-size: inherit;
}

.header-notes-dropdown .dropdown-menu .list1 h5 .pull-right,
.login-dropdown .dropdown-menu .list1 h5 .pull-right {
	font-weight: 300;
	opacity: 0.8;
}

.cart-dropdown .dropdown-menu {
	min-width: 400px;
	padding: 20px 30px;
}

.user-dropdown-menu .dropdown-menu {
	min-width: 200px;
	padding: 20px 30px;
}

.header-dropdown-number {
	position: absolute;
	font-size: 10px;
	color: #ffffff;
	background-color: #91d0cc;
	border-radius: 5px;
	line-height: 1;
	padding: 2px 4px 1px;
	top: 22%;
	right: 0;
	font-weight: bold;
}

@media (max-width: 500px) {
	.header-notes-dropdown,
  .login-dropdown,
  .cart-dropdown {
		position: static;
	}

	.header-notes-dropdown .dropdown-menu,
  .login-dropdown .dropdown-menu,
  .cart-dropdown .dropdown-menu {
		margin-left: 0;
		margin-right: 0;
		right: 0;
		left: 0;
		min-width: 0;
	}
}

@media (max-width: 992px) {
	.page_header_wrapper .col-sm-12 {
		min-height: 0;
	}
}

/* logo */
a.logo {
	display: inline-block;
	word-break: break-word;
	font-weight: 700;
	font-size: 30px;
	font-family: 'Playfair Display', serif;
	line-height: 1;
	position: relative;
	z-index: 2;
	padding: 5px 0;
	min-height: 60px;
	line-height: 45px;
}

a.logo .small-text {
	display: block;
	font-size: 9px;
	line-height: 1;
}

a.logo.text-shadow {
	text-shadow: 0 0.05em 0.1em rgba(68, 68, 68, 0.5);
}

a.logo.text-shadow .small-text {
	text-shadow: 0 0.15em 0.05em rgba(68, 68, 68, 0.5);
}

@media (min-width: 992px) {
	a.logo.big {
		font-size: 40px;
	}

	a.logo.big .small-text {
		margin-top: 5px;
	}
}

@media (min-width: 1200px) {
	a.logo.big {
		font-size: 55px;
		line-height: 1;
	}

	a.logo.big .small-text {
		margin-top: 10px;
	}
}

footer a.logo img {
	max-width: 100%;
}

@media (max-width: 991px) {
	a.logo img {
		max-width: 120px;
	}
}

.ls a.logo {
	color: #91d0cc;
}

.ls a.logo:hover {
	color: #91d0cc;
}

.header_gradient a.logo,
.header_darkgrey a.logo {
	color: #ffffff;
}

a.logo.bottom_logo {
	padding: 0;
	font-size: 28px;
}

a.logo.bottom_logo img {
	max-width: 65px;
}

.floating_logo a.logo {
	float: left;
	margin-right: 30px;
}

a.logo.vertical_logo {
	padding: 0;
	margin-bottom: 15px;
}

a.logo.vertical_logo img {
	margin: 0 auto 5px;
	display: block;
}

@media (min-width: 992px) {
	.header_logo_center a.logo {
		margin: 15px 20px;
	}
}

@media (min-width: 992px) {
	.affix.header_logo_center a.logo {
		margin: 5px 20px;
	}
}

@media (min-width: 1450px) {
	a.logo {
		font-size: 36px;
	}
}

@media (min-width: 992px) {
	.header_logo_center .logo_wrapper {
		position: absolute;
		left: 0;
		right: 0;
	}
}

.logo-meta {
	line-height: 1;
	display: inline-block;
	margin: 11px 20px;
	vertical-align: middle;
	text-align: left;
}

@media (max-width: 1400px) {
	.logo + .logo-meta {
		display: none;
	}
}

.logo-meta strong {
	display: block;
	font-size: 16px;
}

.logo-meta span {
	display: block;
}

.page_header_side .logo-meta {
	display: block !important;
}

.lt-ie9 .page_header_wrapper {
	height: auto !important;
}

.lt-ie9 .page_header_wrapper .md-hidden {
	display: inline-block;
}

.page_header_wrapper.header_transparent, .page_header_wrapper.header_half_transparent {
	height: 0 !important;
	position: relative;
	background-color: transparent;
}

.page_header {
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	top: 0;
	left: 0;
	right: 0;
	position: relative;
	z-index: 1000;
	margin: 0;
	padding: 0;
	height: auto;
}

.page_header.affix {
	position: fixed;
	z-index: 1001;
}

.page_header.mobile-active {
	z-index: 1002;
}

.page_header.affix-bottom {
	top: 0 !important;
	position: fixed;
}

.page_header a.header-button {
	display: inline-block;
	color: #787878;
	padding: 0 15px;
	line-height: 60px;
	position: relative;
}

.page_header a.header-button i {
	font-size: 1.2em;
	position: relative;
	top: 0.07em;
}

.page_header a.header-button i + span {
	display: none;
}

.page_header .big-header-buttons {
	padding: 0 15px;
}

.page_header .social-icon {
	margin-bottom: 0;
}

.page_header [class*="display_table_cell"] {
	overflow: visible;
}

.page_header .widget_search {
	display: inline-block;
	margin: 10px 30px 10px 30px;
}

.page_header .widget_search .form-group {
	margin-bottom: 0;
}

@media (max-width: 767px) {
	.page_header .widget_search {
		display: none;
	}
}

/* modal search */
#search_modal {
	color: #ffffff;
}

#search_modal .search-form {
	margin: 0 0 0 60px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

#search_modal a {
	color: #ffffff;
	opacity: 0.6;
	font-size: 12px;
	text-transform: uppercase;
}

#search_modal a:hover {
	color: #91d0cc;
	opacity: 1;
}

#search_modal .searchform-respond {
	width: 300px;
	margin: 20px auto;
}

#search_modal .form-control {
	border: none;
	background-color: transparent;
	height: 60px;
	color: #ffffff;
	width: 100%;
}

#search_modal .form-control:-moz-placeholder {
	color: rgba(255, 255, 255, 0.8);
	opacity: 1;
}

#search_modal .form-control::-moz-placeholder {
	color: rgba(255, 255, 255, 0.8);
	opacity: 1;
}

#search_modal .form-control:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.8);
}

#search_modal .form-control::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.8);
}

#search_modal .theme_button {
	color: #ffffff;
}

#search_modal .close {
	padding: 19px 20px 18px;
	position: absolute;
	min-width: 0;
	color: #ffffff;
	text-shadow: none;
	opacity: 1;
}

/* header floats */
.page_header:before,
.page_header:after {
	content: '';
	display: block;
	clear: both;
}

.header_left_logo {
	position: relative;
}

.page_header {
	height: auto;
}

.header_left_logo {
	white-space: nowrap;
	padding: 0 15px;
	overflow: visible;
}

.header_left_logo .logo img {
	max-width: none;
}

.header_mainmenu {
	overflow: visible;
	width: 10000px;
	padding: 0 15px;
}

.header_right_buttons {
	white-space: nowrap;
	padding: 0 15px;
	text-align: right;
	overflow: visible;
}

@media (min-width: 1600px) {
	.header_left_logo {
		padding: 0 50px;
	}

	.row .header_left_logo {
		padding: inherit;
	}

	.header_right_buttons {
		padding: 0 50px;
	}

	.row .header_right_buttons {
		padding: inherit;
	}
}

/*
** Side Header
*/
/* side header layout */
body {
	position: relative;
	left: 0;
}

body:after {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1001;
	visibility: hidden;
	opacity: 0;
}

body,
body:after,
.page_header_side {
	-webkit-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;
}

body {
	-webkit-transition-property: left, right;
	transition-property: left, right;
}

body.active-side-header {
	left: 270px;
}

body.active-side-header .page_header_side {
	left: 0;
}

body.active-side-header:after {
	visibility: visible;
	opacity: 1;
}

.page_header_side {
	z-index: 1002;
	position: fixed;
	width: 270px;
	left: -270px;
	top: 0;
	bottom: 0;
}

.page_header_side.active-slide-side-header {
	box-shadow: 0px 0px 4px 3px rgba(50, 50, 50, 0.05);
}

.page_header_side ~ * .toggle_menu {
	display: none;
}

.page_header_side .widget {
	margin-top: 20px;
	padding-left: 30px;
	padding-right: 30px;
}

.side_header_inner {
	padding: 10px 0 60px;
}

.toggle_menu_side {
	position: absolute;
	top: 5px;
	right: -65px;
	background-color: #ffffff;
	width: 60px;
	height: 60px;
	cursor: pointer;
	box-shadow: 0 0 4px 2px rgba(50, 50, 50, 0.05);
	z-index: 2;
}

.toggle_menu_side:before,
.toggle_menu_side span,
.toggle_menu_side span:before,
.toggle_menu_side span:after {
	content: '';
	display: block;
	width: 24px;
	position: absolute;
	background-color: #444444;
	left: 0;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
	height: 2px;
}

.toggle_menu_side:before,
.toggle_menu_side span {
	left: 18px;
	top: 50%;
}

.toggle_menu_side span:before {
	opacity: 1;
	top: -6px;
}

.toggle_menu_side span:after {
	opacity: 1;
	top: 6px;
}

.active-slide-side-header .toggle_menu_side:before,
.active-side-header .toggle_menu_side:before {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.active-slide-side-header .toggle_menu_side span,
.active-side-header .toggle_menu_side span {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.active-slide-side-header .toggle_menu_side span:before, .active-slide-side-header .toggle_menu_side span:after,
.active-side-header .toggle_menu_side span:before,
.active-side-header .toggle_menu_side span:after {
	opacity: 0;
}

.ds .toggle_menu_side {
	background-color: #444444;
}

.ds .toggle_menu_side span,
.ds .toggle_menu_side span:before,
.ds .toggle_menu_side span:after, .ds .toggle_menu_side:before {
	background-color: #ffffff;
}

.bs .toggle_menu_side {
	background-color: #253138;
}

@media (max-width: 350px) {
	.toggle_menu_side {
		top: 0;
		right: -50px;
	}
}

.page_header_side.page_header_side_sticked .toggle_menu_side {
	-webkit-transition: 0.6s ease-in-out;
	transition: 0.6s ease-in-out;
	right: -65px;
	top: 5px;
}

.admin .page_header_side.page_header_side_sticked .toggle_menu_side {
	right: -60px;
	top: 0;
}

.admin .page_header_side.page_header_side_sticked.active-slide-side-header .toggle_menu_side {
	top: 0;
	right: 0;
	box-shadow: none;
}

.page_header_side.active-slide-side-header {
	left: 0;
}

.container {
	max-width: 100%;
}

@media (max-width: 767px) {
	.container {
		max-width: 500px;
	}
}

@media (min-width: 600px) {
	.page_header_side.page_header_side_sticked.active-slide-side-header {
		left: 0;
	}

	.page_header_side_sticked ~ * {
		-webkit-transition: margin 0.6s ease-in-out;
		transition: margin 0.6s ease-in-out;
	}

	.page_header_side_sticked ~ div .page_header {
		padding-left: 35px;
	}

	.page_header_side_sticked.active-slide-side-header ~ div .page_header {
		padding-left: 0;
		margin-left: 270px;
	}

	.page_header_side_sticked.active-slide-side-header ~ * {
		margin-left: 270px;
	}

	.boxed .page_header_side_sticked.active-slide-side-header ~ *,
  .boxed .page_header_side_sticked.active-slide-side-header ~ div .page_header {
		margin-left: 0;
	}

	.page_header_side_sticked ~ .page_header_wrapper {
		margin-left: 0;
	}
}

.page_header_side.header_side_right {
	left: auto;
	right: -270px;
}

.page_header_side.header_side_right.active-slide-side-header {
	right: 0;
}

.page_header_side.header_side_right .toggle_menu_side {
	right: auto;
	left: -65px;
}

@media (max-width: 350px) {
	.page_header_side.header_side_right .toggle_menu_side {
		left: -50px;
	}
}

body.active-side-header.slide-right {
	left: -270px;
}

body.active-side-header.slide-right .page_header_side {
	left: auto;
	right: 0;
}

/* side clickable menu styles */
.menu-click {
	padding: 0;
	margin: 19px 0;
	line-height: 1;
}

.menu-click i {
	width: 1em;
	text-align: center;
	margin-right: 10px;
}

.menu-click .activate_submenu {
	cursor: pointer;
	position: absolute;
	right: 30px;
	top: 9px;
	font-size: 11px;
	color: #444444;
	text-align: center;
	width: 10px;
	opacity: 0.9;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
}

.menu-click .activate_submenu:before {
	font-family: 'FontAwesome';
	content: "\f0dd";
}

.ds .menu-click .activate_submenu {
	color: #ffffff;
}

.menu-click .active-submenu > .activate_submenu {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
}

.menu-click li {
	position: relative;
	list-style: none;
	margin-top: 10px;
	margin-bottom: 10px;
}

.menu-click > li {
	padding: 0 40px 0 27px;
	font-weight: 400;
	background-color: transparent;
	border-left: 3px solid transparent;
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}

.menu-click > li a {
	display: block;
	padding: 7px 0;
}

.menu-click > li.active-submenu {
	padding-top: 19px;
	padding-bottom: 10px;
	border-color: #91d0cc;
}

.bs .menu-click > li.active-submenu {
	background-color: #253138;
}

.ls .menu-click > li.active-submenu {
	background-color: #f5f5f5;
}

.menu-click > li.active-submenu > .activate_submenu {
	top: 22px;
}

.menu-click > li li {
	padding-right: 40px;
	font-weight: 300;
}

.menu-click li.active > a {
	color: #91d0cc;
}

.menu-click ul {
	opacity: 0;
	height: 0;
	padding-left: 26px;
}

.menu-click ul ul {
	padding-left: 10px;
	font-size: 95%;
}

.menu-click .active-submenu > div.mega-menu {
	height: auto;
}

.menu-click .active-submenu > ul,
.menu-click .active-submenu > div.mega-menu > ul {
	opacity: 1;
	height: auto;
}

.menu-click ul, .menu-click div.mega-menu {
	margin-right: -40px;
	margin-top: 0;
	margin-bottom: 0;
	height: 0;
	overflow: hidden;
	-webkit-transition: opacity 0.6s, margin-top 0.4s, margin-bottom 0.4s, height 0.3s ease-in-out;
	transition: opacity 0.6s, margin-top 0.4s, margin-bottom 0.4s, height 0.3s ease-in-out;
}

.menu-click ul li.sfHover > .mega-menu ul {
	display: block;
	height: 100%;
}

.menu-click div.mega-menu > ul {
	margin-right: 0;
	height: auto;
}

.side_header_social {
	margin: 30px;
	text-align: center;
}

/* sidebar scroll plugin */
.scroll-wrapper {
	overflow: hidden;
	padding: 0;
	position: relative;
	height: 100%;
}

.scroll-wrapper > .scroll-content {
	border: none;
	box-sizing: content-box;
	height: auto;
	left: 0;
	margin: 0;
	max-height: none;
	max-width: none;
	overflow: scroll;
	padding: 0;
	position: relative;
	top: 0;
	width: auto;
}

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
	height: 0;
	width: 0;
}

.scroll-wrapper.scroll--rtl {
	direction: rtl;
}

.scroll-element {
	box-sizing: content-box;
	display: none;
}

.scroll-element div {
	box-sizing: content-box;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
	cursor: default;
}

.scroll-element.scroll-x.scroll-scrollx_visible, .scroll-element.scroll-y.scroll-scrolly_visible {
	display: block;
}

.scrollbar-macosx > .scroll-element,
.scrollbar-macosx > .scroll-element div {
	background: none;
	border: none;
	margin: 0;
	padding: 0;
	position: absolute;
	z-index: 10;
}

.scrollbar-macosx > .scroll-element div {
	display: block;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
}

.scrollbar-macosx > .scroll-element .scroll-element_track {
	display: none;
}

.scrollbar-macosx > .scroll-element .scroll-bar {
	background-color: rgba(127, 127, 127, 0.4);
	display: block;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	border-radius: 1px;
	-webkit-transition: opacity 0.2s linear;
	transition: opacity 0.2s linear;
}

.scrollbar-macosx:hover > .scroll-element .scroll-bar,
.scrollbar-macosx > .scroll-element.scroll-draggable .scroll-bar {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
	filter: alpha(opacity=70);
	opacity: 0.7;
}

.scrollbar-macosx > .scroll-element.scroll-x {
	bottom: 0px;
	height: 0px;
	left: 0;
	min-width: 100%;
	overflow: visible;
	width: 100%;
}

.scrollbar-macosx > .scroll-element.scroll-y {
	height: 100%;
	min-height: 100%;
	right: 0px;
	top: 0;
	width: 0px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-bar {
	height: 7px;
	min-width: 10px;
	top: -9px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-bar {
	left: -9px;
	min-height: 10px;
	width: 7px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_outer {
	left: 2px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_size {
	left: -4px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_outer {
	top: 2px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_size {
	top: -4px;
}

.scrollbar-macosx > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
	left: -11px;
}

.scrollbar-macosx > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
	top: -11px;
}

/*
** Light Section - .ls
*/
.ls {
	background-color: #ffffff;
	color: #787878;
}

.ls h1,
.ls h2,
.ls h3,
.ls h4,
.ls h5,
.ls h6 {
	color: #444444;
}

.ls h1 a,
.ls h2 a,
.ls h3 a,
.ls h4 a,
.ls h5 a,
.ls h6 a {
	color: inherit;
}

.ls h1 a:hover,
.ls h2 a:hover,
.ls h3 a:hover,
.ls h4 a:hover,
.ls h5 a:hover,
.ls h6 a:hover {
	color: #91d0cc;
}

.ls [class*='_bg'] {
	color: #ffffff;
}

.ls [class*='_bg'] h1,
.ls [class*='_bg'] h2,
.ls [class*='_bg'] h3,
.ls [class*='_bg'] h4,
.ls [class*='_bg'] h5,
.ls [class*='_bg'] h6 {
	color: #ffffff;
}

.ls a {
	color: #91d0cc;
}

.ls a:hover {
	color: #444444;
}

.ls .theme_buttons a,
.ls .theme_button {
	color: #ffffff;
}

.ls .theme_buttons a:hover, .ls .theme_buttons a:focus,
.ls .theme_button:hover,
.ls .theme_button:focus {
	background-color: #f5f5f5;
	color: #444444;
}

.ls .theme_buttons a.color1:hover,
.ls .theme_button.color1:hover {
	background-color: #d9be93;
}

.ls .theme_buttons a.color2:hover,
.ls .theme_button.color2:hover {
	background-color: #91d0cc;
}

.ls .theme_button.inverse a,
.ls .theme_button.inverse {
	color: #444444;
}

.ls .theme_button.inverse a:hover, .ls .theme_button.inverse a:focus,
.ls .theme_button.inverse:hover,
.ls .theme_button.inverse:focus {
	background-color: #444444;
	color: #ffffff;
}

.ls [class*='color'].theme_button:active,
.ls [class*='color'].theme_button:hover,
.ls [class*='color'].theme_button:focus {
	color: #ffffff;
}

.ls .color-icon.bg-icon {
	background-color: #ececec;
}

.ls .grey {
	color: #444444;
}

.ls .highlight {
	color: #91d0cc;
}

.ls .highlight2 {
	color: #d9be93;
}

.ls .lightgreylinks a {
	color: #e5e5e5;
}

.ls .lightgreylinks a.social-icon {
	color: #e5e5e5;
}

.ls .greylinks a {
	color: #b4b4b4;
}

.ls .greylinks a:hover {
	color: #444444;
}

.ls .darklinks a {
	color: #444444;
}

.ls .darklinks a:hover {
	color: #91d0cc;
}

/*
** Grey Section - light section, muted section - .ls.ms
*/
.ls.ms {
	background-color: #f2f2f2;
}

.ls.ms .with_background {
	background-color: #ffffff;
}

.ls.ms .with_background hr {
	background-color: #ebebeb;
}

.ls.ms hr {
	background-color: #ffffff;
}

.ls.ms input[type="text"],
.ls.ms input[type="email"],
.ls.ms input[type="url"],
.ls.ms input[type="password"],
.ls.ms input[type="search"],
.ls.ms input[type="tel"],
.ls.ms input[type="number"],
.ls.ms textarea,
.ls.ms select,
.ls.ms .form-control {
	border-color: #b4b4b4;
}

.ls.ms .form-control:focus {
	border-color: #91d0cc;
}

.ls.ms .form-control:-moz-placeholder {
	color: #787878;
}

.ls.ms .form-control::-moz-placeholder {
	color: #787878;
}

.ls.ms .form-control:-ms-input-placeholder {
	color: #787878;
}

.ls.ms .form-control::-webkit-input-placeholder {
	color: #787878;
}

.ls.ms .form-control:focus:-moz-placeholder {
	color: transparent;
}

.ls.ms .form-control:focus::-moz-placeholder {
	color: transparent;
}

.ls.ms .form-control:focus:-ms-input-placeholder {
	color: transparent;
}

.ls.ms .form-control:focus::-webkit-input-placeholder {
	color: transparent;
}

.ls.ms .tab-content {
	background-color: #ffffff;
}

.ls.ms .tab-content .with_border {
	border-color: #e6e6e6;
}

.ls.ms .tab-content.no-border {
	background-color: transparent;
}

.ls.ms .tab-content .form-control {
	background-color: #f2f2f2;
}

.ls.ms .vertical-tabs .nav > li > a {
	background-color: #ffffff;
}

.ls.ms .vertical-tabs .nav > li > a:hover,
.ls.ms .vertical-tabs .nav > li.active > a {
	background-color: #444444;
}

.ls.ms .panel-title > a {
	background-color: #444444;
}

.ls.ms .panel-title > a.collapsed {
	background-color: #ffffff;
}

.ls.ms .with_background .panel-title > a {
	border: 1px solid #ebebeb;
}

.ls.ms .panel-title > a.collapsed:hover,
.ls.ms .panel-title > a:hover {
	background-color: #444444;
}

.ls.ms .breadcrumb {
	color: #787878;
}

.ls.ms .breadcrumb a {
	color: #5f5f5f;
}

.ls.ms .breadcrumb a:hover {
	color: #91d0cc;
}

/*
** Dark Section and Dark Muted sections - .ds and .ds.ms
*/
/* darkgrey section - .ds */
.ds {
	background-color: #444444;
	color: #b4b4b4;
}

/* dark section - .ds.ms */
.ds.ms {
	background-color: #1a1a1a;
}

.ds.ms .ds.ms,
.ds.ms + .ds.ms {
	background-color: #1f1f1f;
}

/* dark section blue - .ds.bs */
.ds.bs {
	background-color: #2f3e47;
}

.ds.bs .side_header_logo {
	background-color: #253138;
}

.ds {
  /* shop */
}

.ds h1,
.ds h2,
.ds h3,
.ds h4,
.ds h5,
.ds h6 {
	color: #ffffff;
}

.ds h1 a,
.ds h2 a,
.ds h3 a,
.ds h4 a,
.ds h5 a,
.ds h6 a {
	color: #ffffff;
}

.ds h1 a:hover,
.ds h2 a:hover,
.ds h3 a:hover,
.ds h4 a:hover,
.ds h5 a:hover,
.ds h6 a:hover {
	color: #91d0cc;
}

.ds [class*='_bg'] {
	color: #ffffff;
}

.ds [class*='_bg'] h1,
.ds [class*='_bg'] h2,
.ds [class*='_bg'] h3,
.ds [class*='_bg'] h4,
.ds [class*='_bg'] h5,
.ds [class*='_bg'] h6 {
	color: #ffffff;
}

.ds .ls {
	background-color: #ffffff;
	color: #787878;
}

.ds .ls h1,
.ds .ls h2,
.ds .ls h3,
.ds .ls h4,
.ds .ls h5,
.ds .ls h6 {
	color: #444444;
}

.ds .ls h1 a,
.ds .ls h2 a,
.ds .ls h3 a,
.ds .ls h4 a,
.ds .ls h5 a,
.ds .ls h6 a {
	color: inherit;
}

.ds .ls h1 a:hover,
.ds .ls h2 a:hover,
.ds .ls h3 a:hover,
.ds .ls h4 a:hover,
.ds .ls h5 a:hover,
.ds .ls h6 a:hover {
	color: #91d0cc;
}

.ds .ls a {
	color: #91d0cc;
}

.ds .ls a:hover {
	color: #444444;
}

.ds label {
	color: #ffffff;
}

.ds a {
	color: #ffffff;
}

.ds a:hover {
	color: #91d0cc;
}

.ds hr {
	border-color: rgba(255, 255, 255, 0.15);
	background-color: rgba(255, 255, 255, 0.15);
}

.ds blockquote, .ds .blockquote {
	color: #ffffff;
}

.ds .blockquote-big:before, .ds .blockquote-big:after {
	color: #2c2c2c;
}

.ds .breadcrumb > .active {
	color: #ffffff;
}

.ds .theme_buttons a,
.ds .theme_button {
	background-color: #ffffff;
	color: #444444;
}

.ds .theme_buttons a:hover, .ds .theme_buttons a:focus,
.ds .theme_button:hover,
.ds .theme_button:focus {
	background-color: #91d0cc;
	color: #ffffff;
}

.ds .theme_buttons.color1 a,
.ds .theme_button.color1 {
	background-color: #91d0cc;
	color: #ffffff;
}

.ds .theme_buttons.color1 a:hover, .ds .theme_buttons.color1 a:focus,
.ds .theme_button.color1:hover,
.ds .theme_button.color1:focus {
	background-color: #d9be93;
}

.ds .theme_buttons.color2 a,
.ds .theme_button.color2 {
	background-color: #d9be93;
	color: #ffffff;
}

.ds .theme_buttons.color2 a:hover, .ds .theme_buttons.color2 a:focus,
.ds .theme_button.color2:hover,
.ds .theme_button.color2:focus {
	background-color: #91d0cc;
}

.ds [class*='color'].theme_button:active,
.ds [class*='color'].theme_button:hover,
.ds [class*='color'].theme_button:focus {
	color: #ffffff;
}

.ds .theme_buttons.inverse a:after,
.ds .theme_button.inverse:after {
	display: none;
}

.ds .theme_buttons.inverse a:hover, .ds .theme_buttons.inverse a:focus,
.ds .theme_button.inverse:hover,
.ds .theme_button.inverse:focus {
	background-color: #91d0cc;
	color: #ffffff;
}

.ds [class*='soc-'] {
	color: #ffffff;
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .social-icon.color-icon:hover {
	color: #ffffff;
}

.ds .with_shadow,
.ds .hover_shadow:hover {
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
}

.ds .divided_content > * + *:before {
	background-color: rgba(255, 255, 255, 0.3);
}

.ds .muted_background,
.ds .with_background,
.ds .well {
	background-color: rgba(255, 255, 255, 0.06);
}

.ds.muted_section:before {
	background-color: rgba(255, 255, 255, 0.06);
}

.ds .with_border {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .highlight {
	color: #91d0cc;
}

.ds .highlight2 {
	color: #d9be93;
}

.ds .grey, .ds .black {
	color: #ffffff;
	border-color: #ffffff;
}

.ds .highlightlinks a {
	color: #91d0cc;
}

.ds .highlightlinks a:hover {
	color: #ffffff;
}

.ds .greylinks a {
	color: #b4b4b4;
}

.ds .greylinks a:hover {
	color: #ffffff;
}

.ds .darklinks a {
	color: #ffffff;
}

.ds .darklinks a:hover {
	color: #91d0cc;
}

.ds.with_top_border:before, .ds.with_bottom_border:after {
	background-color: rgba(255, 255, 255, 0.15);
}

.ds .section_header + p {
	color: #ffffff;
}

.ds input[type="text"],
.ds input[type="email"],
.ds input[type="url"],
.ds input[type="password"],
.ds input[type="search"],
.ds input[type="tel"],
.ds input[type="number"],
.ds textarea,
.ds select,
.ds .form-control {
	border-color: rgba(255, 255, 255, 0.15);
	color: #ffffff;
}

.ds form label + [class*="icon-"] {
	color: #444444;
	color: #ffffff;
}

.ds .form-group-select:before {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .select-group .empty {
	color: #b4b4b4;
}

.ds .select-group option[data-default] {
	color: #b4b4b4;
}

.ds .form-control:focus {
	border-color: #91d0cc;
}

.ds .form-control:-moz-placeholder {
	color: #b4b4b4;
	opacity: 1;
}

.ds .form-control::-moz-placeholder {
	color: #b4b4b4;
	opacity: 1;
}

.ds .form-control:-ms-input-placeholder {
	color: #b4b4b4;
}

.ds .form-control::-webkit-input-placeholder {
	color: #b4b4b4;
}

.ds .form-control:focus:-moz-placeholder {
	color: transparent;
}

.ds .form-control:focus::-moz-placeholder {
	color: transparent;
}

.ds .form-control:focus:-ms-input-placeholder {
	color: transparent;
}

.ds .form-control:focus::-webkit-input-placeholder {
	color: transparent;
}

.ds .contact-form.transparent-background .form-control {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .table,
.ds .table-bordered,
.ds .table th,
.ds .table td,
.ds table th,
.ds table td {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds table th {
	color: #ffffff;
}

.ds .table_template th,
.ds .table_template td {
	border-color: transparent;
}

.ds .table_template th {
	color: inherit;
}

.ds .table-striped > tbody > tr:nth-child(odd),
.ds .table-striped > tbody > tr:nth-child(odd) > td,
.ds .table-striped > tbody > tr:nth-child(odd) > th {
	border-color: rgba(255, 255, 255, 0.15);
	background-color: rgba(255, 255, 255, 0.06);
}

.ds .color-icon.bg-icon {
	background-color: rgba(255, 255, 255, 0.06);
}

.ds .color-icon.bg-icon:hover {
	color: #ffffff;
}

.ds .color-bg-icon:hover {
	background-color: #91d0cc;
}

.ds .tab-content {
	background-color: rgba(255, 255, 255, 0.06);
	border-color: transparent;
}

.ds .tab-content.no-border {
	background-color: transparent;
}

.ds .nav-unstyled {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .nav-tabs > li > a {
	border-color: transparent;
	color: #ffffff;
}

.ds .nav-tabs > li > a:hover,
.ds .nav-tabs > li.active > a, .ds .nav-tabs > li.active > a:hover, .ds .nav-tabs > li.active > a:focus {
	border: none;
	background-color: rgba(255, 255, 255, 0.06);
	color: #ffffff;
}

.ds .price-table.style1 {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .plan-name {
	background-color: rgba(255, 255, 255, 0.06);
}

.ds .plan-price p {
	color: #ffffff;
}

.ds .features-list .disabled:before {
	color: #ffffff;
}

.ds .features-list li:first-child,
.ds .features-list li {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds ul.list1 {
	color: #ffffff;
}

.ds ul.list1 li {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .owl-dots .owl-dot span {
	background-color: rgba(255, 255, 255, 0.5);
}

.ds .owl-dots .owl-dot.active span, .ds .owl-dots .owl-dot:hover span {
	background-color: rgba(255, 255, 255, 0.5);
}

.ds .owl-dots .owl-dot.active span:before, .ds .owl-dots .owl-dot:hover span:before {
	background-color: #ffffff;
}

.ds .testimonials-carousel .media + p {
	background-color: rgba(255, 255, 255, 0.06);
}

.ds .post-adds .share_button > i,
.ds .post-adds .like_button > i {
	color: #ffffff;
}

.ds .side-item {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .entry-excerpt {
	color: #ffffff;
}

.ds .reply a {
	color: #ffffff;
}

.ds .widget_search .form-inline .theme_button, .ds .widget_product_search [type="submit"], .ds .widget_mailchimp .form-inline .theme_button {
	background-color: transparent;
	color: #91d0cc;
}

.ds .widget_shopping_cart .cart_list,
.ds .widget_recent_entries li,
.ds .widget_recent_comments li,
.ds .widget_archive li,
.ds .widget_categories li,
.ds .widget_meta li,
.ds .widget_nav_menu li,
.ds .widget_pages li,
.ds .widget_popular_entries li {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .widget_nav_menu ul ul {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .widget_popular_entries .media-heading {
	color: #ffffff;
}

.ds .tweet_list li + li .tweet_right {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .widget_tag_cloud a {
	color: #ffffff;
	border-color: #ffffff;
}

.ds .widget_shopping_cart li a + a {
	color: #ffffff;
}

.ds .widget_shopping_cart li a + a:hover {
	color: #91d0cc;
}

.ds .widget .woocommerce-Price-amount {
	color: #ffffff;
}

.ds .summary .price {
	color: #ffffff;
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .summary .product_meta {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .widget_price_filter .price_label {
	color: #ffffff;
}

.ds #calendar_wrap {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .widget_calendar table {
	background-color: rgba(255, 255, 255, 0.06);
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .widget_calendar tfoot td a {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .widget_calendar caption,
.ds .widget_calendar thead,
.ds .widget_calendar th {
	color: #ffffff;
}

.ds .widget_calendar tbody td a:hover {
	color: #ffffff;
}

.ds .grid-view h3:before, .ds .grid-view h3:after {
	background-color: rgba(255, 255, 255, 0.15);
}

.ds .owl-carousel.product-thumbnails .owl-nav > div:after {
	color: #ffffff;
}

.ds .ui-slider {
	background-color: rgba(255, 255, 255, 0.06);
}

.ds .ui-slider .ui-slider-handle {
	background-color: #ffffff;
}

.ds #sort_view {
	background-color: transparent;
	color: #ffffff;
}

.ds #toggle_shop_view.grid-view:after, .ds #toggle_shop_view:before {
	background-color: rgba(247, 247, 247, 0.06);
}

.ds .widget_layered_nav .color-filters a:before {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds .table.cart a {
	color: #ffffff;
}

.ds .pagination > li > a,
.ds .pagination > li > span {
	color: #ffffff;
	background-color: transparent;
	border: 1px solid rgba(255, 255, 255, 0.15);
}

.ds .pagination > li.active > a,
.ds .pagination > li.active > span,
.ds .pagination > li > a:hover,
.ds .pagination > li > span:hover,
.ds .pagination > li > a:focus,
.ds .pagination > li > span:focus {
	border-color: #91d0cc;
	background-color: #91d0cc;
	color: #ffffff;
}

.ds .thumbnail {
	background-color: transparent;
}

.ds .thumbnail h3 a {
	color: #ffffff;
}

.ds .thumbnail .caption {
	border-color: transparent;
	background-color: rgba(252, 252, 252, 0.06);
}

.ds .progress {
	background-color: rgba(255, 255, 255, 0.15);
}

.ds .vertical-tabs .nav > li > a {
	background-color: #91d0cc;
	color: #ffffff;
}

.ds .panel-heading .panel-title > a.collapsed {
	background-color: transparent;
	color: #ffffff;
}

.ds .panel-heading .panel-title > a.collapsed:after {
	background-color: rgba(255, 255, 255, 0.15);
}

.ds .panel-heading .panel-title > a.collapsed:hover {
	color: #91d0cc;
}

.ds .panel-default {
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
}

.ds .panel-default.collapsed {
	box-shadow: none;
}

.ds .vertical-tabs .nav > li.active > a,
.ds .vertical-tabs .nav > li > a:hover {
	background-color: #91d0cc;
}

.ds .panel-heading .panel-title > a:hover:after,
.ds .vertical-tabs .nav > li > a:hover:after {
	color: #ffffff;
}

.ds .entry-meta .comments-link a {
	color: #ffffff;
}

.ds #timetable tbody th,
.ds #timetable tbody td {
	border-color: rgba(255, 255, 255, 0.15);
}

.ds #isotope_filters a,
.ds .filters a {
	color: #ffffff;
}

.ds div .fw-shortcode-calendar-wrapper .page-header h3,
.ds div .btn-group button[data-calendar-nav],
.ds div .btn-group button[data-calendar-nav*='today'] {
	color: #ffffff;
}

.ds .flex-control-paging li a {
	border-color: #ffffff;
}

.ds .flex-control-paging li a:hover, .ds .flex-control-paging li a.flex-active {
	border-color: #ffffff;
	background-color: #ffffff;
}

/*
** Color Section - .cs
*/
.cs.main_color2 {
	background-color: #d9be93;
}

.cs.main_color2 .highlight {
	color: #91d0cc;
}

.cs {
	background-color: #91d0cc;
	color: #ffffff;
}

.cs.darken_gradient:after {
	background: -webkit-linear-gradient(rgba(45, 104, 101, 0.15), #91d0cc 10%, #91d0cc 90%, rgba(45, 104, 101, 0.15));
	background: linear-gradient(rgba(45, 104, 101, 0.15), #91d0cc 10%, #91d0cc 90%, rgba(45, 104, 101, 0.15));
	background-color: #91d0cc;
}

.cs h1,
.cs h2,
.cs h3,
.cs h4,
.cs h5,
.cs h6 {
	color: #ffffff;
}

.cs h1 a,
.cs h2 a,
.cs h3 a,
.cs h4 a,
.cs h5 a,
.cs h6 a {
	color: inherit;
}

.cs h1 a:hover,
.cs h2 a:hover,
.cs h3 a:hover,
.cs h4 a:hover,
.cs h5 a:hover,
.cs h6 a:hover {
	color: rgba(255, 255, 255, 0.6);
}

.cs a {
	color: #ffffff;
}

.cs a:hover {
	color: rgba(255, 255, 255, 0.6);
}

.cs blockquote, .cs .blockquote {
	border-color: #b5dfdd;
}

.cs .theme_buttons a.color1,
.cs .theme_button.color1 {
	background-color: #4bb0a9;
}

.cs .theme_buttons a.color1:hover, .cs .theme_buttons a.color1:focus,
.cs .theme_button.color1:hover,
.cs .theme_button.color1:focus {
	background-color: #d9be93;
}

.cs .theme_buttons a.color2,
.cs .theme_button.color2 {
	background-color: #d9be93;
}

.cs .theme_buttons a.color2:hover, .cs .theme_buttons a.color2:focus,
.cs .theme_button.color2:hover,
.cs .theme_button.color2:focus {
	background-color: #4bb0a9;
}

.cs .theme_buttons a:hover, .cs .theme_buttons a:focus,
.cs .theme_button:hover,
.cs .theme_button:focus {
	color: #444444;
	background-color: #ffffff;
}

.cs [class*='color'].theme_button:active,
.cs [class*='color'].theme_button:hover,
.cs [class*='color'].theme_button:focus {
	color: #ffffff;
}

.cs .theme_buttons.inverse a:hover, .cs .theme_buttons.inverse a:focus,
.cs .theme_button.inverse:hover,
.cs .theme_button.inverse:focus {
	color: #ffffff;
	background-color: #444444;
}

.cs .ls .theme_button.color1 {
	background-color: #91d0cc;
}

.cs .ls .theme_button.color1:hover, .cs .ls .theme_button.color1:focus {
	background-color: #d9be93;
}

.cs .divided_content > * + *:before {
	background-color: rgba(255, 255, 255, 0.5);
}

.cs .with_background {
	background-color: rgba(255, 255, 255, 0.3);
}

.cs .with_border {
	border-color: rgba(255, 255, 255, 0.3);
}

.cs.with_top_border .container:before,
.cs.with_bottom_border .container:after {
	background-color: #b5dfdd;
}

.cs .section_header + p {
	color: #ffffff;
}

.cs .greylinks a {
	color: rgba(255, 255, 255, 0.6);
}

.cs .greylinks a:hover {
	color: #ffffff;
}

.cs .black {
	border-color: #ffffff;
	color: #ffffff;
}

.cs .lightgrey {
	color: #d8efed;
}

.cs .grey {
	border-color: #444444;
	color: #444444;
}

.cs .response,
.cs .highlight {
	color: #ffffff;
}

.cs ul.list1 li {
	border-color: rgba(255, 255, 255, 0.3);
}

.cs [class*='soc-'] {
	color: #ffffff;
	border-color: rgba(255, 255, 255, 0.3);
}

.cs .color-icon.bg-icon {
	background-color: rgba(255, 255, 255, 0.3);
}

.cs a.social-icon.color-bg-icon:hover {
	background-color: #444444;
}

.cs input[type="text"],
.cs input[type="email"],
.cs input[type="url"],
.cs input[type="password"],
.cs input[type="search"],
.cs input[type="tel"],
.cs input[type="number"],
.cs textarea,
.cs select,
.cs .form-control {
	color: #444444;
}

.cs .form-control:focus {
	border-color: rgba(120, 120, 120, 0.5);
}

.cs .form-control:-moz-placeholder {
	color: #787878;
}

.cs .form-control::-moz-placeholder {
	color: #787878;
}

.cs .form-control:-ms-input-placeholder {
	color: #787878;
}

.cs .form-control::-webkit-input-placeholder {
	color: #787878;
}

.cs .form-control:focus:-moz-placeholder {
	color: transparent;
}

.cs .form-control:focus::-moz-placeholder {
	color: transparent;
}

.cs .form-control:focus:-ms-input-placeholder {
	color: transparent;
}

.cs .form-control:focus::-webkit-input-placeholder {
	color: transparent;
}

.cs .pagination > li > a,
.cs .pagination > li > span {
	color: #ffffff;
	background-color: rgba(255, 255, 255, 0.3);
}

.cs .pagination > li.active > a,
.cs .pagination > li.active > span,
.cs .pagination > li > a:hover,
.cs .pagination > li > span:hover,
.cs .pagination > li > a:focus,
.cs .pagination > li > span:focus {
	background-color: #ffffff;
	color: #444444;
}

.cs .widget_recent_entries li + li,
.cs .widget_recent_comments li + li,
.cs .widget_archive li + li,
.cs .widget_categories li + li,
.cs .widget_meta li + li,
.cs .widget_popular_entries li + li,
.cs .widget_nav_menu li + li,
.cs .widget_pages li + li {
	border-top-color: rgba(255, 255, 255, 0.3);
}

.cs .widget_search .form-inline .theme_button,
.cs .widget_product_search [type="submit"],
.cs .widget_mailchimp .form-inline .theme_button {
	color: #91d0cc;
}

.cs .widget_search .form-inline .theme_button:hover,
.cs .widget_product_search [type="submit"]:hover,
.cs .widget_mailchimp .form-inline .theme_button:hover {
	background-color: transparent;
}

.cs .owl-carousel .owl-dots .owl-dot.active span {
	background-color: rgba(255, 255, 255, 0.5);
}

.cs .owl-carousel .owl-dots .owl-dot span {
	background-color: rgba(255, 255, 255, 0.4);
}

.cs .owl-carousel .owl-dots .owl-dot.active span:before,
.cs .owl-carousel .owl-dots .owl-dot:hover span:before {
	background-color: #ffffff;
}

/*
** Template Specific Styles
*/
/* topline styles */
.page_topline {
	font-size: 14px;
	position: relative;
	z-index: 1001;
}

.page_topline .media {
	display: inline-block;
	margin-top: 0;
	vertical-align: middle;
}

.page_topline .media-left {
	padding-right: 0;
}

.page_topline .fa,
.page_topline [class*="rt-icon"] {
	margin-right: 8px;
	opacity: 0.5;
}

@media (max-width: 500px) {
	.page_topline .col-md-6.divided_content > * {
		display: block;
	}

	.page_topline .col-md-6.divided_content > * + *:before {
		display: none;
	}
}

/* toplogo styles */
.page_toplogo .logo-meta {
	display: block;
}

.page_toplogo .teaser,
.page_toplogo .small-teaser {
	vertical-align: middle;
	display: inline-block;
	margin: 15px 20px;
}

.page_toplogo .teaser .media-left,
.page_toplogo .small-teaser .media-left {
	line-height: 1;
}

.page_toplogo .teaser .media-body,
.page_toplogo .small-teaser .media-body {
	text-align: left;
	width: auto;
}

.page_toplogo .teaser h4,
.page_toplogo .small-teaser h4 {
	font-size: 18px;
	margin-bottom: 0;
}

@media (min-width: 992px) {
	.page_toplogo {
		white-space: nowrap;
	}

	.page_toplogo .logo {
		padding: 20px 0;
	}

	.page_toplogo .social-icon {
		margin-bottom: 0;
	}
}

@media (min-width: 992px) {
	.page_topline .col-md-3,
  .page_header .col-md-3 {
		width: 21%;
	}

	.page_topline .col-md-6,
  .page_header .col-md-6 {
		width: 58%;
	}
}

@media (min-width: 1200px) {
	.page_topline .col-md-3,
  .page_header .col-md-3 {
		width: 20%;
	}

	.page_topline .col-md-6,
  .page_header .col-md-6 {
		width: 60%;
	}
}

.page_mainslider .flex-custom-nav {
	opacity: 0;
}

/* title section (breadcrumbs) styles */
.page_breadcrumbs .breadcrumbs_logo {
	margin: 0 0 15px;
}

.page_breadcrumbs .breadcrumbs_logo img {
	opacity: 0.3;
}

.page_breadcrumbs h2 {
	display: inline;
	font-size: 32px;
	word-wrap: break-word;
}

.page_breadcrumbs h2 + .breadcrumb {
	margin-top: 10px;
}

.page_breadcrumbs h2.small {
	font-size: 32px;
}

@media (min-width: 992px) {
	.page_breadcrumbs h2 {
		font-size: 45px;
	}
}

@media screen and (min-width: 992px) {
	.page_breadcrumbs .display_table_md {
		width: auto;
		min-width: 0;
	}

	.page_breadcrumbs h2.display_table_cell_md {
		padding-right: 20px;
		padding-left: 15px;
	}

	.page_breadcrumbs .breadcrumb.display_table_cell_md {
		border-left: 1px solid rgba(255, 255, 255, 0.3);
		padding-left: 25px;
	}
}

.page_breadcrumbs .bottom_breadcrumbs {
	text-align: center;
	float: right;
	padding: 0 20px;
	margin: 0 0 -26px 0;
	background-color: rgba(26, 26, 26, 0.035);
}

.page_portfolio {
	overflow: hidden;
}

/* template parallax sections */
.section_subscribe.parallax {
	background-image: url(../img/parallax/map.jpg);
}

.section_subscribe .form-control {
	width: 350px;
	max-width: 100%;
	display: inline-block;
}

.page_breadcrumbs.parallax,
.page_breadcrumbs.background_cover {
	background-image: url(../img/parallax/breadcrumbs.jpg);
}

.page_breadcrumbs.background_cover:after {
	opacity: 0.5;
}

.abstract_background.parallax {
	background-image: url(../img/parallax/progress.jpg);
}

.page_testimonials.parallax {
	background-image: url(../img/parallax/testimonials.jpg);
}

.page_testimonials.parallax:after {
	opacity: 0.97;
}

.page_contact.parallax {
	background-image: url(../img/parallax/map.jpg);
}

.page_copyright.parallax,
.page_footer.parallax {
	background-image: url(../img/parallax/bottom.jpg);
}

.section_404.background_cover {
	background-image: url(../img/parallax/404.jpg);
}

.page_about.parallax {
	background-image: url("../img/parallax/texture.png");
}

/* footer styles */
.page_footer .logo_footer_media {
	margin-bottom: 45px;
}

.page_footer .small-teaser {
	margin-top: 10px;
}

.page_footer .widget_archive a,
.page_footer .widget_categories a,
.page_footer .widget_meta a,
.page_footer .widget_nav_menu a,
.page_footer .widget_pages a {
	padding-left: 0;
}

.page_footer .widget_archive a:before,
.page_footer .widget_categories a:before,
.page_footer .widget_meta a:before,
.page_footer .widget_nav_menu a:before,
.page_footer .widget_pages a:before {
	display: none;
}

/* copyrights styles */
.page_copyright {
	font-size: 14px;
}

.page_copyright p {
	margin: 0;
}

.page_copyright i.fa {
	opacity: 0.8;
}

.page_copyright.table_section .bottom_logo img {
	margin-bottom: 0;
}

.page_copyright .with_top_border:before {
	left: 15px;
	right: 15px;
}

.ds.ms + .page_footer.ds.ms {
	background-color: #151515;
}

.ds.ms.page_subscribe + .ds.ms.page_footer {
	margin-top: -50px;
	background-color: #1a1a1a;
}

/* google map  */
#map,
.page_map {
	height: 500px;
}

#map img,
.page_map img {
	max-width: inherit;
}

#map p,
.page_map p {
	line-height: 1.3;
	margin-bottom: 0;
}

#map .map_marker_description,
.page_map .map_marker_description {
	padding: 10px 5px 5px;
}

#map .map_marker_icon,
.page_map .map_marker_icon {
	display: none;
}

/* intro slider inside content */
/* color ribbon section */
.color-ribbon {
	position: absolute;
	width: 100%;
	left: 0;
	right: 0;
	height: 5px;
	background-color: #91d0cc;
}

.color-ribbon div,
.color-ribbon div:after, .color-ribbon:after {
	content: '';
	position: absolute;
	background-color: #91d0cc;
	width: 25%;
	height: 5px;
	left: 50%;
}

.color-ribbon:after {
	background-color: #d9be93;
}

.color-ribbon div {
	left: 0;
	background-color: #f4a115;
}

.color-ribbon div:after {
	width: 100%;
	left: 100%;
	background-color: #e7003e;
}

.muted_background .pagination {
	margin: 40px 40px 20px;
}

/*
** Intro Section Styles
*/
.intro_section {
	position: relative;
	overflow: hidden;
	clear: both;
}

.intro_section > img {
	width: 100%;
}

.intro_section .flexslider:before, .intro_section .flexslider:after {
	content: '';
	display: block;
	clear: both;
}

.intro_section .flexslider .intro-layer img {
	width: auto;
	display: inline-block;
}

.intro_section.cs img {
	opacity: 0.15;
}

.intro_section .flexslider .container,
.intro_section img + .container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

.intro_section [class*="col-"] {
	position: static;
	min-height: 0;
	margin-top: 0;
	margin-bottom: 0;
}

.intro_section .slide_description_wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	height: 100%;
	font-size: 0;
	z-index: 5;
	padding: 50px 0 50px;
}

@media (min-width: 600px) {
	.boxed .intro_section .slide_description_wrapper {
		padding-left: 60px;
		padding-right: 60px;
	}
}

.intro_section .slide_description_wrapper .slide_description {
	font-size: 16px;
}

.intro_section .slide_description_wrapper:before,
.intro_section .slide_description {
	display: inline-block;
	vertical-align: middle;
}

.intro_section .slide_description_wrapper:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
}

.intro_section .slide_description_wrapper.slide_text_top:before {
	vertical-align: top;
}

.intro_section .slide_description > div {
	margin: 10px 0;
}

.intro_section h4 {
	font-size: 30px;
}

.intro_section h3 {
	font-size: 40px;
	margin-bottom: 0;
	text-shadow: 0.015em 0 0.2em rgba(45, 104, 101, 0.5);
}

.intro_section .small-text {
	text-shadow: 0.015em 0 0.1em rgba(45, 104, 101, 0.5);
}

@media (min-width: 500px) {
	.intro_section h3 {
		font-size: 60px;
	}

	.intro_section .small-text {
		font-size: 16px;
	}
}

@media (min-width: 768px) {
	.intro_section h4 {
		font-size: 40px;
	}

	.intro_section h3 {
		font-size: 80px;
	}

	.intro_section .small-text {
		font-size: 18px;
	}
}

@media (min-width: 992px) {
	.intro_section h4 {
		font-size: 45px;
	}
}

@media (min-width: 1200px) {
	.intro_section h3 {
		font-size: 100px;
	}

	.intro_section .small-text {
		font-size: 20px;
	}
}

@media (min-width: 1450px) {
	.intro_section h3 {
		font-size: 120px;
	}
}

@media (min-width: 1700px) {
	.intro_section h3 {
		font-size: 150px;
	}
}

@media (max-width: 991px) {
	.intro_section {
		text-align: center;
	}

	.intro_section .nav-container {
		padding: 0;
	}

	.intro_section .flex-control-nav,
  .intro_section .flex-custom-nav {
		bottom: 13px;
		text-align: center;
	}

	.intro_section .flexslider .container {
		position: static;
		width: auto;
		max-width: 100%;
	}

	.intro_section .slide_description_wrapper:before {
		display: none;
	}

	.intro_section .slide_description {
		padding: 30px 0 60px;
		display: block;
	}

	.intro_section .slide_description_wrapper {
		padding: 0;
		position: static;
	}

	.intro_section .flex-direction-nav .flex-prev {
		top: 24%;
	}

	.intro_section .flex-direction-nav .flex-next {
		top: 24%;
	}
}

/*
** Main Menu styles
*/
/* Desktop menu - (min 992px) styles */
.sf-menu a {
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	font-size: 18px;
	text-align: left;
}

@media (min-width: 992px) {
	.mainmenu_wrapper {
		position: relative;
	}
  /* menu layout */
	.sf-menu, .sf-menu ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.sf-menu li {
		position: relative;
	}

	.sf-menu li > ul {
		position: absolute;
		display: none;
		top: 100%;
		z-index: 99;
	}

	.sf-menu li:hover > ul,
  .sf-menu li.sfHover > ul {
		display: block;
	}

	.sf-menu a {
		display: block;
		position: relative;
	}

	.sf-menu li > ul > li > ul {
		top: -10px;
		left: 100%;
	}
  /* menu skin */
	.sf-menu ul li {
		padding: 0 30px;
	}

	.sf-menu a {
		padding: 15px 0;
		text-decoration: none;
		zoom: 1;
    /* IE7 */
		-webkit-transition-property: color, background-color, border-color, padding-top, padding-bottom;
		transition-property: color, background-color, border-color, padding-top, padding-bottom;
	}

	.sf-menu li {
		white-space: nowrap;
    /* no need for Supersubs plugin */
		*white-space: normal;
    /* ...unless you support IE7 (let it wrap) */
		-webkit-transition: background .2s;
		transition: background .2s;
	}

	.sf-menu > li {
		display: inline-block;
		position: relative;
		margin: 0 -2px;
	}

	.sf-menu > li > a {
		padding-top: 25px;
		padding-bottom: 25px;
		padding-right: 0;
		padding-left: 0;
	}

	.sf-menu > li > a:hover,
  .sf-menu > li.active > a {
		color: #91d0cc;
	}

	.bordered_items .sf-menu > li {
		padding: 0;
	}

	.bordered_items .sf-menu > li + li:before {
		content: '';
		width: 1px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.3);
	}

	.header_white .sf-menu > li > a {
		color: #444444;
	}

	.header_white .sf-menu > li > a:hover,
  .header_white .sf-menu > li.active > a {
		color: #91d0cc;
	}

	.header_white .sf-menu > li + li:before {
		background-color: #ebebeb;
	}

	.header_darkgrey .sf-menu > li > a {
		color: #ffffff;
	}

	.header_darkgrey .sf-menu > li > a:hover,
  .header_darkgrey .sf-menu > li.active > a {
		color: white;
	}

	.header_gradient .sf-menu > li > a {
		color: #ffffff;
	}

	.header_gradient .sf-menu > li > a:hover,
  .header_gradient .sf-menu > li.active > a {
		color: #444444;
	}

	.sf-menu li:hover,
  .sf-menu li.sfHover {
    /* only transition out, not in */
		-webkit-transition: none;
		transition: none;
	}
  /* second and next levels */
	.sf-menu > li .mega-menu,
  .sf-menu li > ul {
		margin-top: 10px;
		text-align: center;
		list-style: none;
		padding: 10px 0 10px;
		background-color: #ffffff;
		box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
		min-width: 220px;
	}

	.sf-menu ul a {
		color: #444444;
	}

	.sf-menu .mega-menu ul {
		box-shadow: none;
		padding: 0;
	}

	.sf-menu .mega-menu ul a {
		padding-right: 0;
	}

	.sf-menu ul a:hover,
  .sf-menu ul li.active > a {
		color: #91d0cc;
	}
  /* third level */
	.sf-menu ul ul {
		margin-top: 0;
		margin-left: 1px;
	}

	.sf-menu ul a {
		padding-left: 15px;
		position: relative;
	}

	.sf-menu ul a:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		background-color: #444444;
		-webkit-transition: all 0.2s linear 0s;
		transition: all 0.2s linear 0s;
	}

	.sf-menu ul a:hover:before {
		background-color: #91d0cc;
	}
}

.sf-menu ul > li + li > a {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* common paddings */
@media (min-width: 992px) {
	.sf-menu > li > a {
		margin-left: 2px;
		margin-right: 2px;
	}

	.sf-menu > li:first-child > a {
		margin-left: 0 !important;
	}

	.sf-menu > li:last-child > a {
		margin-right: 0 !important;
	}

	.bordered_items .sf-menu > li > a {
		margin: 0;
		padding: 15px 40px;
	}
}

/* -md- paddings */
@media (min-width: 992px) and (max-width: 1199px) {
	.sf-menu > li > a {
		margin-left: 10px;
		margin-right: 10px;
	}

	.bordered_items .sf-menu > li > a {
		margin-right: 24px;
		margin-left: 24px;
	}
}

/* -lg- paddings */
@media (min-width: 1200px) {
	.sf-menu > li > a {
		margin-left: 25px;
		margin-right: 25px;
	}

	.boxed .col-md-6 .sf-menu > li > a {
		margin-left: 14px;
		margin-right: 14px;
	}
}

@media (min-width: 1400px) {
	.sf-menu > li > a {
		margin-left: 40px;
		margin-right: 40px;
	}

	.header_logo_center .sf-menu > li > a {
		margin-left: 60px;
		margin-right: 60px;
	}
}

.sf-menu i[class] {
	margin-right: 7px;
	font-size: 1.1em;
}

/* hidden menu items */
@media (min-width: 992px) {
	.sf-menu .sf-md-hidden {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.sf-more-li {
		display: none;
	}
}

/* logo in center of menu */
.sf-menu > .sf-logo-li {
	visibility: hidden;
}

/* Mobile menu toggler */
@media (max-width: 991px) {
	.toggle_menu {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -30px;
		margin-top: -30px;
		width: 60px;
		height: 60px;
		cursor: pointer;
		z-index: 1001;
		visibility: visible;
	}

	.toggle_menu.vertical-center {
		top: 50%;
		margin-top: -30px;
	}

	.toggler_left .toggle_menu {
		left: 0;
		margin-left: 0;
	}

	.toggler_right .toggle_menu {
		right: 0;
		left: auto;
		margin-left: 0;
	}

	.ls .toggle_menu,
  .header_white .toggle_menu {
		background-color: #ffffff;
	}

	.ls .toggle_menu span,
  .ls .toggle_menu span:before,
  .ls .toggle_menu span:after,
  .header_white .toggle_menu span,
  .header_white .toggle_menu span:before,
  .header_white .toggle_menu span:after {
		background-color: #444444;
	}

	.page_toplogo .toggle_menu {
		position: fixed;
		top: 0;
		left: auto;
		right: 0;
		margin: 0;
	}

	.header_logo_center .toggle_menu {
		right: 15px;
		left: auto;
	}

	.toggle_menu:hover span:before {
		top: -9px;
	}

	.toggle_menu:hover span:after {
		top: 9px;
	}

	.toggle_menu:before,
  .toggle_menu span,
  .toggle_menu span:before,
  .toggle_menu span:after {
		display: block;
		width: 30px;
		position: absolute;
		background-color: #ffffff;
		content: '';
		left: 0;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
		height: 2px;
	}

	.toggle_menu:before,
  .toggle_menu span {
		left: 15px;
		top: 50%;
	}

	.toggle_menu span:before {
		opacity: 1;
		top: -8px;
	}

	.toggle_menu span:after {
		opacity: 1;
		top: 8px;
	}

	.toggle_menu.mobile-active {
		background-color: #444444;
		border: none;
		left: auto;
		top: 0;
		right: 0;
		position: fixed;
		margin: 0;
		z-index: 3000;
	}

	.toggle_menu.mobile-active:before {
		background-color: #ffffff;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.toggle_menu.mobile-active span {
		background-color: #ffffff;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}

	.toggle_menu.mobile-active span:before,
  .toggle_menu.mobile-active span:after {
		opacity: 0;
	}
}

@media (max-width: 767px) {
	.toggler-xs-right .toggle_menu {
		right: 0;
		left: auto;
	}
}

/* Mobile Menu (max 991px) styles */
@media (max-width: 991px) {
	.mainmenu_wrapper {
    /*position: relative;*/
		position: fixed;
		visibility: hidden;
		z-index: 10;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: transparent;
		opacity: 0;
		-webkit-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
	}

	.mobile-active .mainmenu_wrapper {
		visibility: visible;
		opacity: 1;
		background-color: #111;
		background-color: rgba(0, 0, 0, 0.9);
	}

	.nav-justified > li > a {
		text-align: left;
	}

	.sf-menu {
		position: fixed;
		overflow: auto;
		background-color: #444444;
		padding: 20px 20px 20px 10px;
		top: 0px;
		left: 0px;
		bottom: 0px;
		right: 100%;
		font-size: 1.1em;
		font-weight: 400;
		word-wrap: break-word;
		text-align: left;
		-webkit-transition: all 0.4s ease-out 0s;
		transition: all 0.4s ease-out 0s;
		opacity: 0;
	}

	.mobile-active .sf-menu {
		opacity: 1;
		right: 15px;
	}

	.sf-menu > li > a,
  .sf-menu a {
		color: #ffffff;
	}

	.sf-menu ul {
		list-style: none;
		padding-left: 10px;
	}

	.sf-menu ul li a {
		display: block;
		padding: 10px;
		font-weight: 300;
		font-size: 16px;
		text-transform: none;
	}

	.sf-menu ul li a:before {
		content: '-';
		padding-right: 5px;
	}

	.sf-menu ul ul li a:before {
		content: '--';
		padding-right: 5px;
	}
  /* arrows (for all except IE7) */
	.sf-arrows .sf-with-ul {
		padding-right: 3.5em;
	}
  /* styling for both css and generated arrows */
	.sf-arrows .sf-with-ul:after {
		font-family: 'FontAwesome';
		content: '\f107';
		position: absolute;
		right: 2em;
		height: 0;
		width: 0;
		font-size: 20px;
	}
}

/* Mega Menu */
@media screen and (min-width: 992px) {
	.sf-menu > li .mega-menu .mega-menu-row > li > a {
		border-top: none;
	}

	.sf-menu .mega-menu-col > a {
		padding-left: 0;
	}

	.sf-menu .mega-menu-col > a:before {
		display: none;
	}

	.sf-menu .mega-menu-col li {
		padding: 0;
	}

	.sf-menu .mega-menu-col li a {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.mainmenu_wrapper ul .mega-menu ul {
		min-width: auto;
	}

	.mainmenu_wrapper .menu-item-has-mega-menu {
		position: relative;
	}

	.mainmenu_wrapper .mega-menu {
		display: none;
		position: absolute;
		width: 940px;
		top: 100%;
		z-index: 10;
	}

	.mainmenu_wrapper .menu-item-has-mega-menu:hover .mega-menu {
		display: block;
	}
  /* row */
	.mainmenu_wrapper .mega-menu-row {
		position: static;
		display: table;
		table-layout: fixed;
	}

	.mainmenu_wrapper .mega-menu-row ul {
		position: static;
		display: block;
	}
  /* column */
	.mainmenu_wrapper .mega-menu-col {
		display: table-cell;
		width: 2000px;
		padding: 0 30px;
		border-left: 1px solid #ebebeb;
    /* overrides from settings / styling / mega-menu */
	}

	.mainmenu_wrapper .mega-menu-col > a {
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 700;
	}

	.mainmenu_wrapper .mega-menu-col:first-child {
		border-left: none;
	}

	.mainmenu_wrapper ul ul .mega-menu {
		width: auto;
		top: -20px;
		margin-left: 1px;
		display: block;
		width: auto;
	}

	.mainmenu_wrapper ul ul .mega-menu li {
		display: block;
		width: auto;
		border: none;
	}
}

@media screen and (min-width: 1200px) {
	.mainmenu_wrapper .mega-menu {
		width: 1140px;
	}
}

@media screen and (max-width: 991px) {
	.mainmenu_wrapper .mega-menu {
		position: static;
	}
}

/*
**Subpages
*/
.edit-link {
	text-align: right;
	display: block;
}

/*404*/
.not_found {
	position: relative;
	font-size: 150px;
	font-weight: 700;
	line-height: 0.8;
	margin-bottom: 30px;
}

.not_found .ops {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 18px;
	text-transform: uppercase;
	-webkit-transform: translate(-49%, -40%) rotate(90deg);
	transform: translate(-49%, -40%) rotate(90deg);
}

.not_found + h2 {
	font-size: 30px;
	text-transform: uppercase;
	margin: 0 0 35px;
}

.not_found + h2 + p {
	margin: 0 0 15px;
}

.not_found + h2 + p + .widget_search {
	max-width: 370px;
	margin: auto;
}

@media (min-width: 767px) {
	.not_found {
		font-size: 300px;
		line-height: 0.8;
	}

	.not_found .ops {
		font-size: 35px;
	}

	.not_found + h2 {
		font-size: 35px;
	}
}

/*about*/
.about-slogan-text .media-left h3 {
	font-size: 73px;
	line-height: 0.8;
	white-space: nowrap;
	opacity: 0.2;
	margin: 0;
}

@media (min-width: 1200px) {
	.about-slogan-text .media-left h3 {
		font-size: 103px;
	}
}

.about-slogan-text .media-body h3 {
	margin-bottom: 0;
}

.about-slogan-text .media-body h2 {
	margin-top: 0;
	font-size: 30px;
}

@media (min-width: 1200px) {
	.about-slogan-text .media-body h2 {
		font-size: 60px;
	}
}

/*blog*/
.intro_section.blog_slider .slides > li {
	max-height: 600px;
}

article iframe {
	margin-bottom: -8px;
}

h1.entry-title {
	font-size: 25px;
}

.entry-meta + h1.entry-title {
	margin-top: 10px;
}

article.post {
	font-size: 18px;
	line-height: 30px;
}

article.post + article.post,
.search article + article,
.archive article + article {
	margin-top: 60px;
}

/* mosaic blog */
.mosaic-post p {
	margin-bottom: 0;
}

.mosaic-post .post-social-links {
	margin: -1px;
	padding: 18px 40px 17px;
}

h3.entry-title {
	font-size: 28px;
	margin: 0 0 14px;
}

h3.entry-title + .date {
	display: inline-block;
	text-transform: uppercase;
	line-height: 1;
	padding: 4px 14px;
	margin-bottom: 22px;
}

h4.entry-title {
	font-size: 25px;
}

.entry-title:last-child {
	margin-bottom: 25px;
}

* + .entry-title {
	margin-top: 15px;
}

h2.search-title {
	margin: 0 0 30px;
	font-size: 40px;
}

.single .item-meta + .entry-title {
	display: none;
}

.entry-tags {
	margin-top: 30px;
}

.entry-tags .theme_button {
	margin-right: 6px;
}

.entry-tags span + span {
	margin-left: 14px;
}

.entry-tags i {
	font-size: 14px;
	margin-right: 5px;
}

.comment-meta:before,
.comment-meta:after,
.post-adds:before,
.post-adds:after {
	display: block;
	clear: both;
	content: '';
	width: 0;
	height: 0;
}

.entry-thumbnail {
	text-align: center;
	position: relative;
}

.entry-thumbnail .entry-thumbnail-carousel {
	margin-bottom: 0;
}

.entry-thumbnail .entry-thumbnail-carousel .owl-dots {
	position: absolute;
	bottom: 40px;
	text-align: center;
	left: 0;
	right: 0;
}

.entry-thumbnail[class*='col-'] {
	margin-bottom: 0;
}

/* additional meta at the bottom of entry-title */
.post-adds {
	margin: 33px 0 33px;
}

.post-adds span, .post-adds a {
	display: inline-block;
}

.post-adds .item-likes {
	padding-left: 20px;
	padding-right: 20px;
}

.post-adds .votes_count,
.post-adds .views-count {
	padding: 0 20px;
	line-height: 40px;
}

.post-adds .views-count {
	padding: 0 30px;
}

.post-adds .item-views-count {
	font-weight: 700;
}

.post-adds .comments-link {
	line-height: 40px;
	padding: 0 15px;
}

.blog-feed .post-adds .comments-link {
	display: none;
}

.post-adds .share_button,
.post-adds .like_button {
	border-color: transparent rgba(55, 52, 47, 0.1) transparent transparent;
	border-width: 1px;
	border-style: solid;
}

.post-adds .share_button > i,
.post-adds .like_button > i {
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
	font-size: 18px;
	padding: 0 10px 0 10px;
	color: #444444;
}

.post-adds .share_button:hover > i,
.post-adds .like_button:hover > i {
	color: #91d0cc;
}

.post-adds .like_button {
	margin-left: -4px;
}

/* share buttons */
.share_buttons {
	min-width: 300px;
}

.share_buttons a.social-icon.color-bg-icon + a.social-icon.color-bg-icon {
	margin-left: -2px;
}

.post-adds .dropdown-menu {
	margin: 5px 0 0;
	padding: 0;
	border: none;
	background-color: transparent;
}

/* additional meta in corner of thumbnail */
.entry-meta-corner {
	position: absolute;
	z-index: 2;
	text-align: center;
	color: #ffffff;
	background-color: #91d0cc;
	line-height: 1;
}

.entry-meta-corner a {
	color: #ffffff;
}

.entry-meta-corner a:hover {
	color: #91d0cc;
}

.entry-meta-corner .date {
	color: #444444;
	display: block;
	min-width: 60px;
	padding: 0 3px 3px;
	text-transform: uppercase;
}

.entry-meta-corner .date strong {
	color: #ffffff;
	display: block;
	font-size: 32px;
	font-weight: 100;
	padding: 8px 0 0;
}

.entry-meta-corner .comments-link {
	display: block;
	padding: 4px 4px 4px;
	background-color: #444444;
}

.entry-meta-corner .comments-link i {
	position: relative;
	top: -1px;
	left: -2px;
}

.item-title + .categories-links {
	display: block;
	margin: 0 0 40px;
}

@media (min-width: 1200px) {
	.item-title + .categories-links {
		margin-top: -43px;
	}
}

.categories-links a {
	margin-right: 6px;
	margin-bottom: 6px;
	font-size: 12px;
}

.item-button {
	margin-top: 40px;
}

/* highlight first letter in blog post */
.entry-content p.big-first-letter:first-letter {
	font-size: 60px;
	line-height: 60px;
	font-weight: 700;
	color: #91d0cc;
	float: left;
	margin-right: 10px;
}

.entry-excerpt {
	font-size: 20px;
	line-height: 30px;
	margin: 8px 0 30px;
}

.entry-excerpt p {
	margin-bottom: 16px;
}

.blog-more-toggle {
	display: block;
	text-transform: uppercase;
	padding: 9px 0;
	margin: 32px 0 80px;
	background-color: rgba(128, 128, 128, 0.05);
}

.blog-more-toggle .blog-more-visible {
	display: inline;
}

.blog-more-toggle .blog-more-hidden {
	display: none;
}

.blog-more-toggle.collapsed .blog-more-visible {
	display: none;
}

.blog-more-toggle.collapsed .blog-more-hidden {
	display: inline;
}

.st_facebook_hcount,
.st_twitter_hcount,
.st_googleplus_hcount {
	position: relative;
	text-align: right;
}

.st_facebook_hcount span,
.st_twitter_hcount span,
.st_googleplus_hcount span {
	-ms-box-sizing: content-box;
	box-sizing: content-box;
}

.author-meta {
	margin-top: 80px;
}

.author-meta h4 {
	font-size: 35px;
	margin-bottom: 20px;
}

@media (max-width: 991px) {
	.author-meta .item-content {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
}

/*single post*/
.single-post article > .entry-thumbnail {
	margin-bottom: 40px;
}

.single-post .video-entry-thumbnail .entry-thumbnail {
	margin-bottom: 0;
}

.single-post footer.entry-meta {
	margin: 30px 0 76px;
}

/*comments*/
.comments-area h2 {
	margin-bottom: 28px;
	font-size: 40px;
	font-weight: 700;
}

.comments-area li.comment {
	list-style: none;
	padding-top: 40px;
}

.comments-area .media {
	position: relative;
}

.comments-area .media-left img {
	max-width: 70px;
	border-radius: 50%;
}

.comments-area .media-object {
	max-width: 70px;
}

.comment-list {
	margin: 0;
	padding: 0;
	position: relative;
	list-style: none;
}

.comment-list ol {
	padding-left: 10px;
}

@media (min-width: 992px) {
	.comment-list ol {
		padding-left: 90px;
	}
}

.comment-meta {
	margin-bottom: 0;
	padding: 5px 40px 10px 0;
}

.comment-meta .comment-metadata {
	display: block;
	margin: 7px 0 10px;
}

.comment-meta .comment-date {
	display: block;
}

.comment-meta .author_url {
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	font-size: 20px;
}

@media (min-width: 400px) {
	.comment-meta .author_url {
		font-size: 25px;
	}
}

.reply a {
	position: absolute;
	right: 0;
	top: 0;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	text-align: center;
}

.comment-respond {
	margin-top: 60px;
	margin-bottom: 40px;
}

.comment-respond h3 {
	font-size: 20px;
	margin: 0 0 24px;
}

.comment-respond p {
	margin-bottom: 10px;
	position: relative;
}

.comments-area + .comment-respond {
	margin-top: 80px;
}

.comment-form.muted_background {
	padding: 30px 30px 20px;
}

.comment-form p {
	margin-bottom: 0;
}

.comment-form label[for] {
	display: none;
}

.comment-form .theme_button {
	margin-right: 10px;
}

@media (max-width: 500px) {
	.comment-respond i.pull-left {
		display: none;
	}

	.comment-respond .comment-form {
		margin-left: 0;
	}
}

.comment-navigation {
	margin-top: 40px;
}

.comment-navigation .pagination > li > a,
.comment-navigation .pagination > li > span {
	padding: 5px 10px;
}

.page-nav {
	margin-top: 80px;
}

.page-nav h4 {
	margin-top: 10px;
	line-height: 1.3;
	min-height: 2.5em;
	font-size: 25px;
}

.post-password-form input[type="password"].form-control {
	border-color: rgba(0, 0, 0, 0.1);
}

.post-password-form .theme_button {
	padding: 11px 10px 9px;
}

/* gallery */
#isotope_filters,
.filters {
	margin: 0 0 30px;
	padding: 0;
	list-style: none;
}

#isotope_filters li,
.filters li {
	display: inline-block;
	margin: 0;
	padding: 0;
}

#isotope_filters a,
.filters a {
	padding: 6px 15px;
	margin: 0 3px 0;
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	display: inline-block;
}

.gallery-item {
	overflow: hidden;
	position: relative;
}

.gallery-item .item-content {
	padding: 0 40px 5px;
}

.gallery-item .item-content h4 {
	font-weight: 700;
	font-size: 16px;
	z-index: 5;
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.gallery-item:hover .item-content h4 {
	-webkit-transform: scale(1);
	transform: scale(1);
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
}

.gallery-item .post-adds {
	margin-bottom: 0;
}

.gallery-title-item + .item-title {
	padding: 20px 0 40px;
}

.gallery-title-item + .item-title h3 {
	font-size: 25px;
}

i.loadmore_spinner {
	font-size: 30px;
	opacity: 0.3;
	margin: 10px 0 20px;
}

#gallery-owl-carousel .owl-item {
	padding: 15px;
}

/* gallery single */
.gallery-single-title {
	margin: 12px 0 0;
}

@media (min-width: 768px) {
	.gallery-single-title {
		font-size: 60px;
	}
}

.gallery-single-title + .block-header {
	margin-bottom: 40px;
}

.share-gallery-single {
	margin: 40px 0 16px;
}

.items-nav {
	padding: 30px 23px;
}

.items-nav[class*='display_table_'] {
	margin-top: 40px;
}

.items-nav h4 {
	margin: -5px 0 0;
}

.items-nav .media {
	position: relative;
	padding: 10px 0;
}

.items-nav .next-item img {
	max-width: none;
	padding: 0 30px 0 0;
}

.items-nav .next-item i {
	right: 0;
}

.items-nav .prev-item img {
	max-width: none;
	padding: 0 0 0 30px;
}

.items-nav a {
	position: relative;
}

.items-nav a i {
	top: 50%;
	margin-top: -14px;
	font-size: 20px;
}

.items-nav a i.position-absolute {
	position: absolute;
}

.loop-pagination {
	margin-top: 60px;
}

.muted_background .loop-pagination {
	margin-top: 0;
}

.owl-carousel.related-photos-carousel {
	margin-bottom: 0;
}

/* post formats */
.format-status .avatar {
	max-width: 100px;
	border-radius: 50%;
	margin-bottom: 20px;
}

.format-status .entry-title {
	margin-top: 15px;
}

.format-chat .entry-content p {
	margin-top: 0;
	margin-bottom: 0;
	padding: 0.4em 0;
}

.format-chat .entry-content p:nth-child(2n) {
	background-color: rgba(127, 127, 127, 0.1);
	padding: 0.4em 0.8em;
}

.format-quote blockquote {
	border: none;
	text-align: center;
	font-style: italic;
	margin-bottom: 0;
	padding-bottom: 0;
}

.format-quote blockquote cite {
	display: block;
	font-style: normal;
	margin: 24px 0 0;
	font-size: 18px;
	line-height: 1.5;
}

.format-gallery .entry-thumbnail {
	background-color: transparent;
}

/*
isotope portfolio and gallery post
*/
.gallery-item {
	text-align: center;
}

.gallery-columns-1 .gallery-item {
	width: 100%;
}

.gallery-columns-2 .gallery-item {
	width: 50%;
}

.gallery-columns-3 .gallery-item {
	width: 33.33%;
}

.gallery-columns-4 .gallery-item {
	width: 25%;
}

.gallery-columns-5 .gallery-item {
	width: 20%;
}

.gallery-columns-6 .gallery-item {
	width: 16.66%;
}

.gallery-columns-7 .gallery-item {
	width: 14.28%;
}

.gallery-columns-8 .gallery-item {
	width: 12.5%;
}

.gallery-columns-9 .gallery-item {
	width: 11.11%;
}

.gallery-icon img {
	display: block;
	margin: 0 auto;
	width: 100%;
}

.gallery-caption {
	display: block;
	padding: 0.5em;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
	display: none;
}

@media (max-width: 600px) {
	.gallery-columns-4 .gallery-item,
  .gallery-columns-5 .gallery-item,
  .gallery-columns-6 .gallery-item,
  .gallery-columns-7 .gallery-item,
  .gallery-columns-8 .gallery-item,
  .gallery-columns-9 .gallery-item {
		width: 33%;
	}

	.gallery-columns-4 .gallery-caption,
  .gallery-columns-5 .gallery-caption,
  .gallery-columns-6 .gallery-caption,
  .gallery-columns-7 .gallery-caption,
  .gallery-columns-8 .gallery-caption,
  .gallery-columns-9 .gallery-caption {
		display: none;
	}
}

.isotope_container {
	-webkit-transition: height 0.2s ease-in-out 0s;
	transition: height 0.2s ease-in-out 0s;
}

.single-post .isotope_container {
	margin: 20px 0;
	clear: both;
}

/*
** Widgets
*/
/* common widgets styles */
aside > div + div {
	margin-top: 75px;
}

aside .widget + .widget {
	margin-top: 75px;
}

.widget-theme-wrapper.with_background, .widget-theme-wrapper.muted_background, .widget-theme-wrapper.cs, .widget-theme-wrapper.ds, .widget-theme-wrapper.ls {
	padding: 35px;
}

/* affix-aside */
.affix-aside {
	position: relative;
}

.affix-aside.affix {
	position: fixed;
}

@media (max-width: 767px) {
	.affix-aside, .affix-aside.affix, .affix-aside.affix-bottom {
		position: static;
		width: inherit !important;
	}
}

.widget > h3,
.widget-title {
	position: relative;
	font-size: 35px;
	margin-bottom: 55px;
}

.page_footer .widget > h3, .page_footer
.widget-title {
	text-transform: uppercase;
	font-size: 20px;
	margin: 0 0 44px;
}

.widget:before, .widget:after {
	display: block;
	content: '';
	clear: both;
}

.widget ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.widget ul ul li {
	padding-left: 20px;
}

.widget li {
	position: relative;
}

.widget .vertical-item h4 {
	font-size: 16px;
	margin: 0;
}

.widget .vertical-item .item-meta {
	letter-spacing: 0.1em;
}

.page_footer .widget li,
.page_footer .widget ul {
	border: none;
}

/*
widget_search,
widget_mailchimp
*/
.widget_mailchimp {
	overflow: hidden;
}

.widget_search .form-inline,
.widget_product_search form,
.widget_mailchimp .form-inline {
	position: relative;
}

.widget_search .form-inline .form-control,
.widget_product_search form .form-control,
.widget_mailchimp .form-inline .form-control {
	width: 100%;
	margin: 0;
}

.widget_search .form-inline label,
.widget_product_search form label,
.widget_mailchimp .form-inline label {
	display: block;
}

.widget_search .form-inline label[for],
.widget_product_search form label[for],
.widget_mailchimp .form-inline label[for] {
	display: none;
}

.widget_search .form-inline .form-group,
.widget_mailchimp .form-inline .form-group {
	display: block;
	vertical-align: top;
	position: relative;
}

.widget_search .form-inline [type="text"],
.widget_product_search [type="search"],
.widget_mailchimp .form-inline [type="email"] {
	padding-right: 50px;
	width: 100%;
}

@media (min-width: 768px) {
	.widget_search .form-group.inline-block,
  .widget_mailchimp .form-group.inline-block {
		min-width: 370px;
		margin-right: 5px;
	}
}

.widget_search .form-inline .theme_button,
.widget_product_search [type="submit"],
.widget_mailchimp .form-inline .theme_button {
	font-size: 0;
	padding: 12px;
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;
	height: 42px;
	width: 42px;
	border: none;
	background-color: transparent;
	color: #91d0cc;
}

.widget_search .form-inline .theme_button:before,
.widget_product_search [type="submit"]:before,
.widget_mailchimp .form-inline .theme_button:before {
	font-size: 16px;
	content: "\f002";
	font-family: 'FontAwesome';
}

.widget_search .form-inline .theme_button:hover,
.widget_product_search [type="submit"]:hover,
.widget_mailchimp .form-inline .theme_button:hover {
	opacity: 0.5;
}

.widget_mailchimp .form-inline .theme_button:before {
	content: "\f0e0";
}

/*
widget_recent_entries
widget_recent_comments
widget_archive
widget_categories
widget_meta
widget_nav_menu
widget_pages
*/
.widget_recent_comments li {
	padding: 10px 0;
}

.widget_recent_comments li:first-child {
	padding-top: 0;
}

.widget_recent_comments li:before {
	font-family: 'FontAwesome';
	content: "\f0e5";
	font-size: 16px;
	line-height: 1;
	padding-right: 10px;
	color: #91d0cc;
}

.widget_recent_entries li {
	padding: 10px 0 10px 36px;
}

.widget_recent_entries p {
	margin-bottom: 0;
}

.widget_recent_entries li:first-child {
	padding-top: 0;
}

.widget_recent_entries li:before {
	font-family: 'FontAwesome';
	content: "\f040";
	font-size: 18px;
	line-height: 1;
	position: absolute;
	top: 19px;
	left: 0;
	padding-right: 10px;
	color: #91d0cc;
}

.widget_recent_entries li:first-child:before {
	top: 5px;
}

.widget_recent_entries > ul > li:first-child,
.widget_recent_comments > ul > li:first-child,
.widget_archive > ul > li:first-child,
.widget_categories > ul > li:first-child,
.widget_meta > ul > li:first-child,
.widget_popular_entries > ul > li:first-child,
.widget_nav_menu > ul > li:first-child,
.widget_pages > ul > li:first-child {
	border-top: none;
	margin-top: -0.75em;
}

.widget_popular_entries > ul > li:first-child {
	margin-top: 0;
}

.widget_archive a,
.widget_categories a,
.widget_meta a,
.widget_nav_menu a,
.widget_pages a {
	display: inline-block;
	padding: 8px 0 8px 16px;
	position: relative;
	font-family: 'Playfair Display', serif;
	font-size: 18px;
	font-weight: 700;
}

.widget_archive a:before,
.widget_categories a:before,
.widget_meta a:before,
.widget_nav_menu a:before,
.widget_pages a:before {
	font-family: 'rt-icons-2';
	content: "\e7c1";
	font-size: 18px;
	color: #91d0cc;
	position: absolute;
	left: -3px;
}

.widget_archive span,
.widget_categories span {
	position: absolute;
	right: 0;
	top: 0.45em;
}

.widget_nav_menu ul ul li:first-child a {
	padding-top: 13px;
}

.widget_nav_menu a i {
	padding-right: 7px;
}

@media (min-width: 768px) {
	.page_copyright .widget_nav_menu li, .page_topline .widget_nav_menu li {
		display: inline-block;
		position: relative;
		border: none;
		padding-right: 5px;
	}

	.page_copyright .widget_nav_menu li:before, .page_topline .widget_nav_menu li:before {
		content: '';
		position: absolute;
		padding: 0;
		left: -5px;
		top: 5px;
		bottom: 5px;
		width: 1px;
		background-color: rgba(0, 0, 0, 0.1);
		opacity: 0.3;
		display: block;
	}

	.page_copyright .widget_nav_menu li:first-child:before, .page_topline .widget_nav_menu li:first-child:before {
		display: none;
	}

	.page_copyright .widget_nav_menu a, .page_topline .widget_nav_menu a {
		padding: 0 10px;
	}

	.page_copyright .widget_nav_menu a:before, .page_topline .widget_nav_menu a:before {
		display: none;
	}
}

/* widget_tag_cloud */
.widget_tag_cloud a {
	border: 2px solid #444444;
	border-radius: 2px;
	padding: 3px 18px 1px 22px;
	margin: 0 4px 10px 0;
	font-size: 12px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 700;
	display: inline-block;
}

aside .widget_tag_cloud a {
	color: #444444;
}

aside .widget_tag_cloud a:hover {
	color: #ffffff;
}

.widget_tag_cloud a:hover {
	background-color: #91d0cc;
	border-color: #91d0cc;
	color: #ffffff;
}

/* widget_popular_entries */
.widget_popular_entries h4 {
	margin-bottom: 10px;
}

.widget_popular_entries p {
	padding-top: 6px;
	margin-bottom: 10px;
}

.widget_popular_entries .media-body {
	overflow: visible;
}

.widget_popular_entries .media-left {
	padding-right: 20px;
}

.widget_popular_entries a img,
.widget_popular_entries .media-object {
	max-width: 80px;
}

@media (min-width: 768px) and (max-width: 1199px) {
	.widget_popular_entries .media-object {
		max-width: 40px;
	}
}

.widget_popular_entries li {
	margin-top: 2px;
	padding: 20px 0 18px 0;
}

.widget_popular_entries li:first-child {
	padding-top: 0;
}

.widget_popular_entries li:last-child {
	padding-bottom: 0;
}

/* recent posts widget */
.widget_recent_posts h4 {
	font-size: 18px;
	line-height: 1.4;
	margin-top: 10px;
}

.widget_recent_posts a img {
	max-width: 80px;
}

.widget_recent_posts li + li:before {
	content: "";
	display: block;
	margin: 20px 0;
	width: 30px;
	height: 1px;
	background-color: #ebebeb;
}

/* widget_calendar */
#calendar_wrap {
	width: 100%;
	margin-bottom: 0;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (min-width: 768px) and (max-width: 992px) {
	#calendar_wrap {
		overflow-x: auto;
		overflow-y: hidden;
	}
}

.widget_calendar table {
	width: 100%;
	margin: 0;
	position: relative;
	text-align: center;
	background-color: #ffffff;
}

.widget_calendar caption {
	line-height: 1;
	font-size: 24px;
	padding: 18px 0 18px;
	text-align: center;
	color: #ffffff;
	background-color: #91d0cc;
}

.widget_calendar thead {
	background-color: #444444;
	border: 1px solid #444444;
	border-width: 7px 1px 7px 1px;
	color: #ffffff;
}

.widget_calendar thead th {
	font-weight: 300;
}

.widget_calendar thead th:first-child {
	padding-left: 15px;
}

.widget_calendar thead th:last-child {
	padding-right: 15px;
}

.widget_calendar tbody {
	font-weight: 300;
}

.widget_calendar tbody tr:first-child td {
	padding-top: 29px;
}

.widget_calendar tbody tr:last-child td {
	padding-bottom: 30px;
}

.widget_calendar tbody td {
	border: none;
	padding: 3px 4px;
	position: relative;
}

.widget_calendar tbody td:first-child {
	padding-left: 30px;
}

.widget_calendar tbody td:last-child {
	padding-right: 30px;
}

.widget_calendar tbody td a {
	font-weight: 700;
	color: #91d0cc;
}

.widget_calendar tbody td a:hover {
	color: #d9be93;
}

.widget_calendar th {
	text-align: center;
	border: 0;
	color: inherit;
}

.widget_calendar td {
	border: 0;
}

.widget_calendar tfoot {
	position: absolute;
	top: -3px;
	right: 0;
	left: 0;
}

.widget_calendar tfoot td {
	position: absolute;
	top: 0;
}

.widget_calendar tfoot td a {
	color: #ffffff;
	text-align: center;
	font-size: 0;
	width: 60px;
	height: 60px;
	line-height: 60px;
	display: inline-block;
}

.widget_calendar tfoot td a:hover {
	color: #ffffff;
	background-color: #91d0cc;
	border-color: #91d0cc;
}

.widget_calendar tfoot #prev {
	left: 0;
	padding: 0;
}

.widget_calendar tfoot #prev a {
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.widget_calendar tfoot #prev a:before {
	font-size: 14px;
	font-family: 'rt-icons-2';
	content: '\e7c4';
}

.widget_calendar tfoot #next {
	right: 0;
	padding: 0;
}

.widget_calendar tfoot #next a {
	border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.widget_calendar tfoot #next a:after {
	font-size: 14px;
	font-family: 'rt-icons-2';
	content: '\e7c5';
}

/* widget_banner */
.widget_banner {
	overflow: hidden;
	line-height: 1;
}

.widget_banner .item-content {
	padding: 20px;
}

.widget_banner .item-content h3 {
	margin: 0;
	padding: 8px 0;
	font-size: 28px;
}

.widget_banner .item-content span {
	display: inline-block;
	text-transform: uppercase;
	padding: 4px 10px 1px;
	min-width: 120px;
}

.widget_banner .small-text {
	margin-bottom: 0;
}

/* widget_slider */
.widget_slider {
	position: relative;
}

.widget_slider .owl-carousel {
	margin-bottom: 0;
}

.widget_slider .owl-carousel:hover .owl-nav {
	top: -43px;
}

.widget_slider .owl-carousel .owl-nav {
	opacity: 1;
	top: -25px;
	right: 0;
}

.widget_slider .owl-carousel .owl-nav > div {
	width: auto;
	height: auto;
	right: 0;
	background-color: transparent;
}

.widget_slider .owl-carousel .owl-nav > div:first-child {
	left: auto;
	right: 22px;
}

.widget_slider .owl-carousel .owl-nav > div:after {
	font-size: 12px;
	color: inherit;
	color: #1a1a1a;
}

/* widget_flickr */
.widget_flickr ul {
	margin: -5px;
}

.widget_flickr li {
	display: inline-block;
	padding: 5px;
	max-width: 50%;
}

.widget_flickr img {
	max-width: 160px;
	width: 100%;
	border-radius: 1px;
}

/* widget_instagram */
.widget_instagram .instafeed {
	padding-top: 10px;
}

.widget_instagram .photo {
	max-width: 60px;
	float: left;
}

.widget_instagram:after {
	content: '';
	display: block;
	clear: both;
}

/* widget_twitter */
.tweet_avatar {
	float: left;
	margin-top: 4px;
}

.tweet_right {
	overflow: hidden;
	zoom: 1;
	margin-left: 60px;
}

.tweet_list li + li {
	margin-top: 16px;
	padding-top: 20px;
	clear: both;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.tweet_time {
	display: block;
}

/* widget_tabs widget_theme_post_tabs */
.widget_theme_post_tabs .vertical-item {
	margin-bottom: 20px;
}

.widget_theme_post_tabs .vertical-item .item-content {
	padding-top: 20px;
}

.widget_theme_post_tabs .pull-right {
	margin-left: 10px;
}

.widget_rss li {
	margin-bottom: 15px;
}

.widget_rss li .rsswidget {
	font-size: 1.1em;
}

.widget_rss .rss-date {
	display: block;
	font-weight: 700;
}

.widget_rss cite {
	display: block;
	text-align: right;
	font-weight: 700;
}

/* access_press */
.apsc-facebook-icon {
	background-color: #3b5998;
}

.apsc-twitter-icon {
	background-color: #1dcaff;
}

.apsc-youtube-icon {
	background-color: #e52d27;
}

.apsc-soundcloud-icon {
	background-color: #ff8800;
}

.apsc-dribble-icon {
	background-color: #ea4c89;
}

.apsc-comment-icon {
	background-color: #464646;
}

.apsc-edit-icon {
	background-color: #837d7d;
}

.apsc-google-plus-icon {
	background-color: #dd4b39;
}

.apsc-instagram-icon {
	background-color: #3f729b;
}

.apsc-each-profile a {
	color: white !important;
}

.apsc-each-profile a:hover {
	color: white !important;
	text-decoration: none !important;
}

.apsc-theme-4 .apsc-each-profile {
	margin-bottom: 10px;
}

.apsc-theme-4 .social-icon {
	display: inline-block;
	position: relative;
	float: left;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
}

.apsc-theme-4 .apsc-each-profile a {
	line-height: 60px;
	padding: 0 18px 0 0;
	font-size: 12px;
	text-transform: uppercase;
	display: block;
	color: #fff;
	position: relative;
	text-align: right;
}

.apsc-theme-4 .apsc-each-profile a:hover {
	opacity: 0.8;
}

.apsc-theme-4 .apsc-each-profile a .fa {
	border-right: 1px solid rgba(255, 255, 255, 0.4);
	width: 60px;
	line-height: 60px;
	height: 60px;
	vertical-align: top;
	text-align: center;
	font-size: 16px;
	display: inline-block;
	transform: none;
	-webkit-transform: none;
	-ms-transform: none;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
}

.apsc-theme-4 .apsc-each-profile a:hover .fa {
	transform: none;
	-webkit-transform: snone;
	-ms-transform: none;
}

.apsc-theme-4 .media-name {
	padding: 0 18px;
	display: inline-block;
}

.apsc-theme-4 .apsc-count {
	border-radius: 0;
	color: #fff;
	display: inline-block;
	font-size: inherit;
	text-align: center;
	position: relative;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
}

.apsc-theme-4 .apsc-media-type {
	display: inline-block;
	padding: 0 4px;
}

@media screen and (max-width: 479px) {
	.apsc-theme-4 .apsc-each-profile, .apsc-theme-3 .apsc-each-profile {
		float: none;
	}
}

/*
** Shortcodes
*/
/*
** Teasers
*/
/* small teaser - media bootstrap component with icon */
.small-teaser h4 {
	margin-bottom: 8px;
}

.small-teaser .media-body {
	width: auto;
}

/* teaser */
.teaser {
	position: relative;
	overflow: visible;
}

.teaser[class*="_bg_color"], .teaser[class*="with_"] {
	padding: 20px;
}

@media (min-width: 400px) {
	.teaser[class*="_bg_color"], .teaser[class*="with_"] {
		padding: 25px;
	}
}

.teaser [class*="_bg_color"] {
	width: 2em;
	height: 2em;
	text-align: center;
	vertical-align: bottom;
}

.teaser [class*="_bg_color"] + h3,
.teaser [class*="_bg_color"] + h4 {
	margin-top: 28px;
}

.teaser [class*="_bg_color"] i {
	line-height: 2em;
}

.teaser .border_icon {
	width: 2em;
	height: 2em;
	border-width: 3px;
	border-style: solid;
}

.teaser .border_icon + h3,
.teaser .border_icon + h4 {
	margin-top: 28px;
}

.teaser .border_icon i {
	line-height: 2em;
	display: block;
	margin: -3px 0 0 0;
}

.teaser i {
	text-align: center;
	vertical-align: middle;
	display: inline-block;
}

.teaser h2 {
	font-size: 50px;
	font-weight: 400;
	margin-bottom: 33px;
}

.teaser h3 {
	font-size: 20px;
}

.teaser h4 {
	font-size: 16px;
	font-family: 'Muli', sans-serif;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.teaser .media-left {
	padding-right: 20px;
}

.teaser .media-body h3 {
	margin-bottom: 10px;
}

.teaser .media-body h4 {
	margin-bottom: 6px;
}

@media (max-width: 500px) {
	.teaser h2, .teaser h3, .teaser h4 {
		font-size: 1.4em;
	}

	.teaser h2 span, .teaser h3 span, .teaser h4 span {
		font-size: 1.2em;
	}
}

.teaser h3[class*="count"] {
	font-size: 60px;
	line-height: 0.8;
	font-weight: 300;
	margin: 0 0 20px;
}

.teaser h3[class*="count"] + p {
	margin: 0;
}

.teaser .teaser_icon + h3[class*="count"] {
	margin-top: 20px;
}

.teaser .theme_button {
	margin: 22px 0 0;
}

.section_padding_bottom_0 .teaser {
	margin-bottom: 0;
}

.teaser .counter-background {
	position: absolute;
	right: 0;
	left: 0;
	top: 48%;
	font-size: 180px;
	font-weight: 700;
	opacity: 0.1;
}

.teaser .icon-background {
	position: absolute;
	opacity: 0.1;
	margin: 30px auto;
	top: 0;
	left: 0;
	right: 0;
	font-size: 300px;
	line-height: 0.6;
}

.teaser i.icon-background {
	margin-top: 0;
	margin-bottom: 0;
}

.teaser.font-icon-background-teaser {
	padding: 48px 25px 48px;
	overflow: hidden;
}

.teaser.icon-background-teaser, .teaser.counter-background-teaser {
	overflow: hidden;
	padding: 98px 25px 98px;
}

.teaser.icon-background-teaser .small-text, .teaser.counter-background-teaser .small-text {
	line-height: 1.4em;
}

.teaser.inner-border:before {
	content: '';
	position: absolute;
	top: 10px;
	right: 10px;
	bottom: 10px;
	left: 10px;
	border: 1px solid rgba(255, 255, 255, 0.5);
}

.teaser_icon {
	display: inline-block;
	line-height: 1;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.teaser_icon[class*='label-'] {
	display: inline-block;
	width: 30px;
	height: 30px;
	line-height: 32px;
	vertical-align: bottom;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	color: #fff;
}

.teaser_icon + h2 {
	margin-top: 40px;
}

.teaser_icon + h3 {
	margin-top: 10px;
}

.teaser_icon + p {
	margin-top: 27px;
}

.teaser:hover .teaser_icon {
	-webkit-animation: scaleDisappear 0.5s;
	animation: scaleDisappear 0.5s;
}

.size_big {
	font-size: 64px;
}

.size_normal {
	font-size: 42px;
}

.size_small {
	font-size: 30px;
}

/* background teaser */
.bg_teaser {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
	position: relative;
	color: #ffffff;
	overflow: hidden;
}

.bg_teaser:after {
	position: absolute;
	width: 100%;
	height: 1000px;
}

.bg_teaser .highlight {
	color: #ffffff;
}

.bg_teaser h4 + h3 {
	margin-top: -14px;
	font-size: 50px;
}

.bg_teaser.rectangular .teaser_content {
	padding: 200px 30px 50px;
}

.bg_teaser.rectangular .teaser_content h3 {
	font-size: 40px;
}

.bg_teaser > img {
	display: none;
	visibility: hidden;
}

.bg_teaser .teaser_content {
	margin: 0;
	padding: 40px 40px 35px;
	z-index: 2;
}

.bg_teaser .teaser_content > .media-left {
	padding-right: 35px;
}

.bg_teaser .teaser_content.media {
	padding: 75px 40px 60px;
}

.color_bg_1:after {
	background-color: rgba(145, 208, 204, 0.92);
}

.color_bg_2:after {
	background-color: rgba(217, 190, 147, 0.92);
}

.color_bg_3:after {
	background-color: rgba(231, 0, 62, 0.92);
}

.color_bg_4:after {
	background-color: rgba(244, 161, 21, 0.92);
}

.teaser.media.after_cover {
	padding: 25px 30px;
}

.teaser.media.after_cover i {
	opacity: 0.3;
}

.teaser.media.after_cover p {
	text-transform: uppercase;
}

.darkgrey_bg:after {
	background-color: rgba(68, 68, 68, 0.8);
}

/*
** Testimonials
*/
/* testimonials-carousel */
.testimonials-carousel {
	padding: 0 0 20px;
}

.testimonials-carousel .item > p {
	font-style: italic;
	padding-left: 40px;
	margin-bottom: 40px;
	position: relative;
}

.testimonials-carousel .item > p:before {
	font-family: 'PT Sans', sans-serif;
	font-size: 100px;
	font-style: normal;
	content: '\201c';
	position: absolute;
	left: -3px;
	top: 30px;
	z-index: 10;
}

.testimonials-carousel h4 {
	margin: 0;
}

.testimonials-carousel h4 + p {
	margin-bottom: 0;
}

.testimonials-carousel .carousel-indicators {
	bottom: -16px;
	display: none;
}

.testimonials-carousel .carousel-control {
	display: none;
}

.testimonials-carousel .media + p {
	position: relative;
	padding: 10px 16px;
	background-color: #f5f5f5;
}

.testimonials-carousel .media:after {
	content: '';
	display: block;
	clear: both;
}

.testimonials-carousel .media + p:before,
.testimonials-carousel .media + p:after {
	content: '';
	position: absolute;
	left: 0;
	top: auto;
	bottom: -18px;
	border-style: solid;
	border-width: 18px 0 0 18px;
	border-color: rgba(0, 0, 0, 0.1) transparent transparent transparent;
}

.testimonials-carousel .media + p:after {
	left: auto;
	right: 0;
	border-style: solid;
	border-width: 18px 18px 0 0;
}

.testimonials-carousel .media-object {
	max-width: 50px;
	border-radius: 50%;
	margin-bottom: 10px;
}

.testimonials-carousel .carousel-indicators li {
	border-color: #91d0cc;
}

.testimonials-carousel .carousel-indicators li.active {
	background-color: #91d0cc;
}

.testimonials-control {
	font-size: 22px;
}

.owl-carousel.testimonials-carousel {
	padding-bottom: 0;
}

.owl-carousel.testimonials-carousel blockquote {
	margin: 0;
}

/* thumbnail */
.thumbnail {
	position: relative;
	text-align: center;
	border-radius: 0;
	padding: 0;
	border: none;
	overflow: hidden;
	background-color: #ffffff;
}

.isotope-item .thumbnail {
	margin-bottom: 30px;
}

.thumbnail h3 {
	font-size: 20px;
	margin-bottom: 2px;
}

.thumbnail h3 a {
	color: inherit;
}

.thumbnail h3 + p {
	color: #91d0cc;
	margin-bottom: 10px;
}

.thumbnail .caption {
	color: #787878;
	background-color: #ffffff;
	padding: 26px 20px 26px;
}

/* price tables */
.price-table,
.price-table ul {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
}

.price-table {
	margin: 0;
}

.price-table .small-text {
	font-size: 12px;
}

.plan-name {
	font-size: 35px;
	font-weight: 700;
	font-family: 'Playfair Display', serif;
	line-height: 1.2;
	background-color: #444444;
	padding: 25px 0;
}

.plan-name.color2 {
	background-color: #d9be93;
}

.plan-name.color3 {
	background-color: #e7003e;
}

.plan-name h3 {
	color: #ffffff;
	text-transform: uppercase;
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 0.2em;
	margin: 0;
}

.plan-price {
	margin: 40px 0 0;
	position: relative;
	line-height: 1;
	font-size: 40px;
  /* dollar sign */
  /* price */
  /* per month */
}

.plan-price .small-text {
	margin-top: 5px;
	line-height: 1.7;
	font-weight: 300;
	opacity: 0.8;
}

.features-list {
	margin-top: 20px;
	font-family: 'Playfair Display', serif;
	font-weight: 400;
}

.features-list li {
	padding: 12px 0;
	margin: 0 30px;
}

.features-list li + li {
	border-top: 1px solid #ebebeb;
}

.features-list .enabled:before,
.features-list .disabled:before {
	font-family: 'rt-icons-2';
	font-size: 24px;
	line-height: 1;
	position: relative;
	top: 5px;
	right: 4px;
	content: "\e116";
	padding-right: 8px;
	color: #91d0cc;
}

.features-list .disabled:before {
	color: #444444;
	content: "\e117";
}

ul.features-list {
	margin-top: 50px;
}

.call-to-action {
	padding: 40px 30px 50px;
}

.call-to-action.bottom-button {
	padding-top: 20px;
	padding-bottom: 0;
}

.call-to-action.bottom-button a {
	top: 33px;
}

.price-table.style1 {
	border: 2px solid #444444;
}

.price-table.style1.with_background {
	border: none;
}

.price-table.style2 {
	border: 2px solid #91d0cc;
}

@media (min-width: 992px) {
	.price-table.style2 {
		margin-top: -30px;
	}
}

.price-table.style2.with_background {
	border: none;
}

.price-table.style2 .plan-name {
	background-color: #91d0cc;
}

/* price table with background */
.bg_teaser .plan-name {
	background-color: #91d0cc;
	color: #ffffff;
}

.bg_teaser .features-list li,
.bg_teaser .features-list li:first-child {
	border-color: rgba(255, 255, 255, 0.3);
}

.price-row.flex-row > [class*='col-'] {
	min-width: 250px;
}

.price-row > * {
	position: relative;
}

.price-row > *:before, .price-row > *:after {
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	width: 130px;
	height: 1px;
	margin-left: -65px;
	background-color: rgba(255, 255, 255, 0.5);
}

.price-row > *:after {
	top: auto;
	bottom: -1px;
}

@media (min-width: 992px) {
	.price-row > *:before, .price-row > *:after {
		content: "";
		position: absolute;
		top: 50%;
		bottom: auto;
		left: 0;
		width: 1px;
		height: 130px;
		margin-top: -65px;
		margin-left: 0;
		background-color: rgba(255, 255, 255, 0.5);
	}

	.price-row > *:after {
		left: auto;
		right: -1px;
	}
}

.price-hover {
	position: relative;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}

.price-hover .price-media {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.price-hover .price-content {
	position: relative;
	z-index: 1;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	opacity: 0;
	-webkit-transform: scale(1, 0);
	transform: scale(1, 0);
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}

.price-hover .plan-name {
	padding-top: 0;
	background-color: transparent;
}

.price-hover:hover .price-content {
	opacity: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.fw-package.price-table hr {
	margin-left: 30px;
	margin-right: 30px;
	opacity: 0.15;
}

.fw-switch-row {
	display: inline-block;
	font-size: 24px;
	position: relative;
	top: 4px;
	margin-right: 4px;
}

.fw-switch-row + .fw-default-row {
	display: inline-block;
}

.desc-col .fw-default-row {
	margin: 20px 0;
}

/* contact forms */
.contact-form {
	position: relative;
	z-index: 3;
}

.contact-form.ls {
	background-color: #fafafa;
}

.contact-form.ls.ms {
	background-color: #efefef;
}

.contact-form label[for] {
	display: none;
}

.contact-form.parallax {
	padding: 50px 50px 35px;
	background-image: url(../img/parallax/progress.jpg);
}

.contact-form-respond {
	display: block;
	font-weight: 800;
}

.contact-form-respond:before {
	font-family: 'rt-icons-2';
	content: "\e71a";
	padding-right: 10px;
}

.contact-form p {
	margin-bottom: 10px;
	position: relative;
}

.contact-form-submit .theme_button {
	margin-right: 6px;
}

.contact-form.transparent-background .form-control {
	background-color: transparent;
}

.contact-email {
	font-size: 40px;
	text-transform: uppercase;
}

@media (max-width: 767px) {
	.contact-email {
		font-size: 20px;
		margin-bottom: 6px;
	}

	.contact-form.parallax {
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 40px;
	}
}

.wrap-forms .form-group {
	margin: 0;
}

.wrap-forms .form-group p {
	margin: 10px 0 0;
}

.wrap-forms .form-group textarea {
	min-height: 150px;
}

.has-placeholder label {
	display: none;
}

#messages_modal .fw-messages-wrap {
	position: absolute;
	left: 50%;
	top: 10%;
	margin-left: -150px;
	width: 300px;
}

#messages_modal ul {
	margin-bottom: 0;
}

/* partners carousel */
.owl-carousel.partners-carousel {
	margin-bottom: 0;
}

.owl-carousel.partners-carousel a {
	opacity: 0.5;
}

.owl-carousel.partners-carousel a:hover {
	opacity: 1;
}

.owl-carousel.partners-carousel .center a {
	opacity: 1;
}

.star-rating, p.stars a {
	font-weight: 400;
	height: 1em;
}

.star-rating span:before, .star-rating:before {
	content: "\e7aa\e7aa\e7aa\e7aa\e7aa";
	top: 0;
	left: 0;
}

.star-rating {
	overflow: hidden;
	position: relative;
	line-height: 1;
	width: 5.4em;
	font-family: "rt-icons-2";
}

.comments-area .star-rating {
	display: inline-block;
}

.star-rating:before {
	opacity: .5;
	float: left;
	letter-spacing: 0.1em;
	position: absolute;
}

.star-rating span {
	overflow: hidden;
	float: left;
	top: 0;
	left: 0;
	position: absolute;
	padding-top: 1.5em;
	letter-spacing: 0.1em;
}

.star-rating span:before {
	position: absolute;
	color: #91d0cc;
}

p.stars a:before, p.stars a:hover ~ a:before {
	color: #787878;
	content: "\e7aa";
}

p.stars {
	display: inline-block;
	margin: 0 0 30px;
	line-height: 1;
}

p.stars a {
	display: inline-block;
	position: relative;
	width: 1em;
	text-indent: -999em;
	margin-right: 1px;
}

p.stars a:before {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 1em;
	height: 1em;
	line-height: 1;
	font-family: "rt-icons-2";
	text-indent: 0;
}

p.stars.selected a.active:before, p.stars:hover a:before {
	content: "\e7aa";
	color: #91d0cc;
	opacity: 1;
}

p.stars.selected a.active ~ a:before {
	content: "\e7aa";
	color: #787878;
	opacity: .75;
}

p.stars.selected a:not(.active):before {
	content: "\e7aa";
	color: #91d0cc;
	opacity: 1;
}

/*
** Social Icons
*/
/* layout */
a[class*='soc-']:before {
	font-family: 'socicon';
	font-size: 14px;
	line-height: 1;
}

a.social-icon {
	font-size: 0;
	line-height: 0;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
}

a.social-icon span {
	opacity: 0.7;
	display: block;
	font-size: 14px;
	text-transform: uppercase;
}

a.social-icon span:first-child {
	font-size: 24px;
	margin-top: 8px;
	font-weight: bold;
}

a.social-icon [class*="rt-icon"] + span {
	font-size: 24px;
	margin-top: 8px;
	font-weight: bold;
}

a.social-icon + a.social-icon,
.tooltip + a.social-icon {
	margin-left: 16px;
}

a.social-icon.bg-icon + a.social-icon.bg-icon,
.tooltip + a.social-icon.bg-icon,
a.social-icon.color-bg-icon + a.social-icon.color-bg-icon,
.tooltip + a.social-icon.color-bg-icon,
a.social-icon.border-icon + a.social-icon.border-icon,
.tooltip + a.social-icon.border-icon {
	margin-left: 3px;
}

a.social-icon.block-icon {
	width: 100%;
}

a[class*='soc-'].rounded-icon {
	border-radius: 50%;
}

a.big-icon {
	width: 112px;
	height: 112px;
	padding-top: 20px;
	padding-bottom: 20px;
	overflow: hidden;
}

a.big-icon[class*='soc-']:before {
	font-size: 32px;
}

a.big-icon [class*='rt-icon']:before {
	font-size: 32px;
}

/* corporate colors */
a.color-icon.soc-twitter {
	color: #00abef;
}

a.color-icon.soc-facebook {
	color: #507cbe;
}

a.color-icon.soc-google {
	color: #d93e2d;
}

a.color-icon.soc-pinterest {
	color: #c92619;
}

a.color-icon.soc-foursquare {
	color: #23b7e0;
}

a.color-icon.soc-yahoo {
	color: #6e2a85;
}

a.color-icon.soc-skype {
	color: #28abe3;
}

a.color-icon.soc-yelp {
	color: #c83218;
}

a.color-icon.soc-feedburner {
	color: #ffcc00;
}

a.color-icon.soc-linkedin {
	color: #3371b7;
}

a.color-icon.soc-viadeo {
	color: #e4a000;
}

a.color-icon.soc-xing {
	color: #005a60;
}

a.color-icon.soc-myspace {
	color: #323232;
}

a.color-icon.soc-soundcloud {
	color: #fe3801;
}

a.color-icon.soc-spotify {
	color: #7bb342;
}

a.color-icon.soc-grooveshark {
	color: #f67e03;
}

a.color-icon.soc-lastfm {
	color: #d41316;
}

a.color-icon.soc-youtube {
	color: #d12121;
}

a.color-icon.soc-vimeo {
	color: #51b5e7;
}

a.color-icon.soc-dailymotion {
	color: #004e72;
}

a.color-icon.soc-vine {
	color: #00b389;
}

a.color-icon.soc-flickr {
	color: #0062dd;
}

a.color-icon.soc-500px {
	color: #58a9de;
}

a.color-icon.soc-instagram {
	color: #9c7c6e;
}

a.color-icon.soc-wordpress {
	color: #464646;
}

a.color-icon.soc-tumblr {
	color: #45556c;
}

a.color-icon.soc-blogger {
	color: #ec661c;
}

a.color-icon.soc-technorati {
	color: #5cb030;
}

a.color-icon.soc-reddit {
	color: #e74a1e;
}

a.color-icon.soc-dribbble {
	color: #e84d88;
}

a.color-icon.soc-stumbleupon {
	color: #e64011;
}

a.color-icon.soc-digg {
	color: #1d1d1b;
}

a.color-icon.soc-envato {
	color: #597c3a;
}

a.color-icon.soc-behance {
	color: #007eff;
}

a.color-icon.soc-delicious {
	color: #31a9ff;
}

a.color-icon.soc-deviantart {
	color: #c5d200;
}

a.color-icon.soc-forrst {
	color: #5b9a68;
}

a.color-icon.soc-play {
	color: #5acdbd;
}

a.color-icon.soc-zerply {
	color: #9dbc7a;
}

a.color-icon.soc-wikipedia {
	color: #000000;
}

a.color-icon.soc-apple {
	color: #b9bfc1;
}

a.color-icon.soc-flattr {
	color: #f67c1a;
}

a.color-icon.soc-github {
	color: #221e1b;
}

a.color-icon.soc-chimein {
	color: #888688;
}

a.color-icon.soc-friendfeed {
	color: #2f72c4;
}

a.color-icon.soc-newsvine {
	color: #075b2f;
}

a.color-icon.soc-identica {
	color: #000000;
}

a.color-icon.soc-bebo {
	color: #ef1011;
}

a.color-icon.soc-zynga {
	color: #dc0606;
}

a.color-icon.soc-steam {
	color: #8f8d8a;
}

a.color-icon.soc-xbox {
	color: #92c83e;
}

a.color-icon.soc-windows {
	color: #00bdf6;
}

a.color-icon.soc-outlook {
	color: #0072c6;
}

a.color-icon.soc-coderwall {
	color: #3e8dcc;
}

a.color-icon.soc-tripadvisor {
	color: #000000;
}

a.color-icon.soc-appnet {
	color: #494949;
}

a.color-icon.soc-goodreads {
	color: #1abc9c;
}

a.color-icon.soc-tripit {
	color: #1982c3;
}

a.color-icon.soc-lanyrd {
	color: #3c80c9;
}

a.color-icon.soc-slideshare {
	color: #4ba3a6;
}

a.color-icon.soc-buffer {
	color: #000000;
}

a.color-icon.soc-rss {
	color: #f26109;
}

a.color-icon.soc-vkontakte {
	color: #5a7fa6;
}

a.color-icon.soc-disqus {
	color: #2e9fff;
}

a.color-icon.soc-houzz {
	color: #7cc04b;
}

a.color-icon.soc-mail {
	color: #000000;
}

a.color-icon.soc-patreon {
	color: #e44727;
}

a.color-icon.soc-paypal {
	color: #009cde;
}

a.color-icon.soc-playstation {
	color: #000000;
}

a.color-icon.soc-smugmug {
	color: #acfd32;
}

a.color-icon.soc-swarm {
	color: #fc9d3c;
}

a.color-icon.soc-triplej {
	color: #e53531;
}

a.color-icon.soc-yammer {
	color: #1175c4;
}

a.color-icon.soc-stackoverflow {
	color: #fd9827;
}

a.color-icon.soc-drupal {
	color: #00598e;
}

a.color-icon.soc-odnoklassniki {
	color: #f48420;
}

a.color-icon.soc-android {
	color: #8ec047;
}

a.color-icon.soc-meetup {
	color: #e2373c;
}

a.color-icon.soc-persona {
	color: #e6753d;
}

a.color-icon.soc-amazon {
	color: #ff9900;
}

a.color-icon.soc-ello {
	color: #000000;
}

a.color-icon.soc-mixcloud {
	color: #314359;
}

a.color-icon.soc-8tracks {
	color: #122c4b;
}

a.color-icon.soc-twitch {
	color: #6441a5;
}

a.color-icon.soc-airbnb {
	color: #ff5a5f;
}

a.color-icon.soc-pocket {
	color: #ed4055;
}

a[class*="soc-"].soc-twitter:hover {
	color: #00abef;
	border-color: #00abef;
}

a[class*="soc-"].soc-facebook:hover {
	color: #507cbe;
	border-color: #507cbe;
}

a[class*="soc-"].soc-google:hover {
	color: #d93e2d;
	border-color: #d93e2d;
}

a[class*="soc-"].soc-pinterest:hover {
	color: #c92619;
	border-color: #c92619;
}

a[class*="soc-"].soc-foursquare:hover {
	color: #23b7e0;
	border-color: #23b7e0;
}

a[class*="soc-"].soc-yahoo:hover {
	color: #6e2a85;
	border-color: #6e2a85;
}

a[class*="soc-"].soc-skype:hover {
	color: #28abe3;
	border-color: #28abe3;
}

a[class*="soc-"].soc-yelp:hover {
	color: #c83218;
	border-color: #c83218;
}

a[class*="soc-"].soc-feedburner:hover {
	color: #ffcc00;
	border-color: #ffcc00;
}

a[class*="soc-"].soc-linkedin:hover {
	color: #3371b7;
	border-color: #3371b7;
}

a[class*="soc-"].soc-viadeo:hover {
	color: #e4a000;
	border-color: #e4a000;
}

a[class*="soc-"].soc-xing:hover {
	color: #005a60;
	border-color: #005a60;
}

a[class*="soc-"].soc-myspace:hover {
	color: #323232;
	border-color: #323232;
}

a[class*="soc-"].soc-soundcloud:hover {
	color: #fe3801;
	border-color: #fe3801;
}

a[class*="soc-"].soc-spotify:hover {
	color: #7bb342;
	border-color: #7bb342;
}

a[class*="soc-"].soc-grooveshark:hover {
	color: #f67e03;
	border-color: #f67e03;
}

a[class*="soc-"].soc-lastfm:hover {
	color: #d41316;
	border-color: #d41316;
}

a[class*="soc-"].soc-youtube:hover {
	color: #d12121;
	border-color: #d12121;
}

a[class*="soc-"].soc-vimeo:hover {
	color: #51b5e7;
	border-color: #51b5e7;
}

a[class*="soc-"].soc-dailymotion:hover {
	color: #004e72;
	border-color: #004e72;
}

a[class*="soc-"].soc-vine:hover {
	color: #00b389;
	border-color: #00b389;
}

a[class*="soc-"].soc-flickr:hover {
	color: #0062dd;
	border-color: #0062dd;
}

a[class*="soc-"].soc-500px:hover {
	color: #58a9de;
	border-color: #58a9de;
}

a[class*="soc-"].soc-instagram:hover {
	color: #9c7c6e;
	border-color: #9c7c6e;
}

a[class*="soc-"].soc-wordpress:hover {
	color: #464646;
	border-color: #464646;
}

a[class*="soc-"].soc-tumblr:hover {
	color: #45556c;
	border-color: #45556c;
}

a[class*="soc-"].soc-blogger:hover {
	color: #ec661c;
	border-color: #ec661c;
}

a[class*="soc-"].soc-technorati:hover {
	color: #5cb030;
	border-color: #5cb030;
}

a[class*="soc-"].soc-reddit:hover {
	color: #e74a1e;
	border-color: #e74a1e;
}

a[class*="soc-"].soc-dribbble:hover {
	color: #e84d88;
	border-color: #e84d88;
}

a[class*="soc-"].soc-stumbleupon:hover {
	color: #e64011;
	border-color: #e64011;
}

a[class*="soc-"].soc-digg:hover {
	color: #1d1d1b;
	border-color: #1d1d1b;
}

a[class*="soc-"].soc-envato:hover {
	color: #597c3a;
	border-color: #597c3a;
}

a[class*="soc-"].soc-behance:hover {
	color: #007eff;
	border-color: #007eff;
}

a[class*="soc-"].soc-delicious:hover {
	color: #31a9ff;
	border-color: #31a9ff;
}

a[class*="soc-"].soc-deviantart:hover {
	color: #c5d200;
	border-color: #c5d200;
}

a[class*="soc-"].soc-forrst:hover {
	color: #5b9a68;
	border-color: #5b9a68;
}

a[class*="soc-"].soc-play:hover {
	color: #5acdbd;
	border-color: #5acdbd;
}

a[class*="soc-"].soc-zerply:hover {
	color: #9dbc7a;
	border-color: #9dbc7a;
}

a[class*="soc-"].soc-wikipedia:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="soc-"].soc-apple:hover {
	color: #b9bfc1;
	border-color: #b9bfc1;
}

a[class*="soc-"].soc-flattr:hover {
	color: #f67c1a;
	border-color: #f67c1a;
}

a[class*="soc-"].soc-github:hover {
	color: #221e1b;
	border-color: #221e1b;
}

a[class*="soc-"].soc-chimein:hover {
	color: #888688;
	border-color: #888688;
}

a[class*="soc-"].soc-friendfeed:hover {
	color: #2f72c4;
	border-color: #2f72c4;
}

a[class*="soc-"].soc-newsvine:hover {
	color: #075b2f;
	border-color: #075b2f;
}

a[class*="soc-"].soc-identica:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="soc-"].soc-bebo:hover {
	color: #ef1011;
	border-color: #ef1011;
}

a[class*="soc-"].soc-zynga:hover {
	color: #dc0606;
	border-color: #dc0606;
}

a[class*="soc-"].soc-steam:hover {
	color: #8f8d8a;
	border-color: #8f8d8a;
}

a[class*="soc-"].soc-xbox:hover {
	color: #92c83e;
	border-color: #92c83e;
}

a[class*="soc-"].soc-windows:hover {
	color: #00bdf6;
	border-color: #00bdf6;
}

a[class*="soc-"].soc-outlook:hover {
	color: #0072c6;
	border-color: #0072c6;
}

a[class*="soc-"].soc-coderwall:hover {
	color: #3e8dcc;
	border-color: #3e8dcc;
}

a[class*="soc-"].soc-tripadvisor:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="soc-"].soc-appnet:hover {
	color: #494949;
	border-color: #494949;
}

a[class*="soc-"].soc-goodreads:hover {
	color: #1abc9c;
	border-color: #1abc9c;
}

a[class*="soc-"].soc-tripit:hover {
	color: #1982c3;
	border-color: #1982c3;
}

a[class*="soc-"].soc-lanyrd:hover {
	color: #3c80c9;
	border-color: #3c80c9;
}

a[class*="soc-"].soc-slideshare:hover {
	color: #4ba3a6;
	border-color: #4ba3a6;
}

a[class*="soc-"].soc-buffer:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="soc-"].soc-rss:hover {
	color: #f26109;
	border-color: #f26109;
}

a[class*="soc-"].soc-vkontakte:hover {
	color: #5a7fa6;
	border-color: #5a7fa6;
}

a[class*="soc-"].soc-disqus:hover {
	color: #2e9fff;
	border-color: #2e9fff;
}

a[class*="soc-"].soc-houzz:hover {
	color: #7cc04b;
	border-color: #7cc04b;
}

a[class*="soc-"].soc-mail:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="soc-"].soc-patreon:hover {
	color: #e44727;
	border-color: #e44727;
}

a[class*="soc-"].soc-paypal:hover {
	color: #009cde;
	border-color: #009cde;
}

a[class*="soc-"].soc-playstation:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="soc-"].soc-smugmug:hover {
	color: #acfd32;
	border-color: #acfd32;
}

a[class*="soc-"].soc-swarm:hover {
	color: #fc9d3c;
	border-color: #fc9d3c;
}

a[class*="soc-"].soc-triplej:hover {
	color: #e53531;
	border-color: #e53531;
}

a[class*="soc-"].soc-yammer:hover {
	color: #1175c4;
	border-color: #1175c4;
}

a[class*="soc-"].soc-stackoverflow:hover {
	color: #fd9827;
	border-color: #fd9827;
}

a[class*="soc-"].soc-drupal:hover {
	color: #00598e;
	border-color: #00598e;
}

a[class*="soc-"].soc-odnoklassniki:hover {
	color: #f48420;
	border-color: #f48420;
}

a[class*="soc-"].soc-android:hover {
	color: #8ec047;
	border-color: #8ec047;
}

a[class*="soc-"].soc-meetup:hover {
	color: #e2373c;
	border-color: #e2373c;
}

a[class*="soc-"].soc-persona:hover {
	color: #e6753d;
	border-color: #e6753d;
}

a[class*="soc-"].soc-amazon:hover {
	color: #ff9900;
	border-color: #ff9900;
}

a[class*="soc-"].soc-ello:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="soc-"].soc-mixcloud:hover {
	color: #314359;
	border-color: #314359;
}

a[class*="soc-"].soc-8tracks:hover {
	color: #122c4b;
	border-color: #122c4b;
}

a[class*="soc-"].soc-twitch:hover {
	color: #6441a5;
	border-color: #6441a5;
}

a[class*="soc-"].soc-airbnb:hover {
	color: #ff5a5f;
	border-color: #ff5a5f;
}

a[class*="soc-"].soc-pocket:hover {
	color: #ed4055;
	border-color: #ed4055;
}

/*corporate background colors*/
.color-bg-icon {
	color: #ffffff;
}

.color-bg-icon.soc-twitter, a.soc-twitter.bg-icon:hover, .soc-twitter.color-icon.border-icon:hover {
	background-color: #00abef;
}

.color-bg-icon.soc-facebook, a.soc-facebook.bg-icon:hover, .soc-facebook.color-icon.border-icon:hover {
	background-color: #507cbe;
}

.color-bg-icon.soc-google, a.soc-google.bg-icon:hover, .soc-google.color-icon.border-icon:hover {
	background-color: #d93e2d;
}

.color-bg-icon.soc-pinterest, a.soc-pinterest.bg-icon:hover, .soc-pinterest.color-icon.border-icon:hover {
	background-color: #c92619;
}

.color-bg-icon.soc-foursquare, a.soc-foursquare.bg-icon:hover, .soc-foursquare.color-icon.border-icon:hover {
	background-color: #23b7e0;
}

.color-bg-icon.soc-yahoo, a.soc-yahoo.bg-icon:hover, .soc-yahoo.color-icon.border-icon:hover {
	background-color: #6e2a85;
}

.color-bg-icon.soc-skype, a.soc-skype.bg-icon:hover, .soc-skype.color-icon.border-icon:hover {
	background-color: #28abe3;
}

.color-bg-icon.soc-yelp, a.soc-yelp.bg-icon:hover, .soc-yelp.color-icon.border-icon:hover {
	background-color: #c83218;
}

.color-bg-icon.soc-feedburner, a.soc-feedburner.bg-icon:hover, .soc-feedburner.color-icon.border-icon:hover {
	background-color: #ffcc00;
}

.color-bg-icon.soc-linkedin, a.soc-linkedin.bg-icon:hover, .soc-linkedin.color-icon.border-icon:hover {
	background-color: #3371b7;
}

.color-bg-icon.soc-viadeo, a.soc-viadeo.bg-icon:hover, .soc-viadeo.color-icon.border-icon:hover {
	background-color: #e4a000;
}

.color-bg-icon.soc-xing, a.soc-xing.bg-icon:hover, .soc-xing.color-icon.border-icon:hover {
	background-color: #005a60;
}

.color-bg-icon.soc-myspace, a.soc-myspace.bg-icon:hover, .soc-myspace.color-icon.border-icon:hover {
	background-color: #323232;
}

.color-bg-icon.soc-soundcloud, a.soc-soundcloud.bg-icon:hover, .soc-soundcloud.color-icon.border-icon:hover {
	background-color: #fe3801;
}

.color-bg-icon.soc-spotify, a.soc-spotify.bg-icon:hover, .soc-spotify.color-icon.border-icon:hover {
	background-color: #7bb342;
}

.color-bg-icon.soc-grooveshark, a.soc-grooveshark.bg-icon:hover, .soc-grooveshark.color-icon.border-icon:hover {
	background-color: #f67e03;
}

.color-bg-icon.soc-lastfm, a.soc-lastfm.bg-icon:hover, .soc-lastfm.color-icon.border-icon:hover {
	background-color: #d41316;
}

.color-bg-icon.soc-youtube, a.soc-youtube.bg-icon:hover, .soc-youtube.color-icon.border-icon:hover {
	background-color: #d12121;
}

.color-bg-icon.soc-vimeo, a.soc-vimeo.bg-icon:hover, .soc-vimeo.color-icon.border-icon:hover {
	background-color: #51b5e7;
}

.color-bg-icon.soc-dailymotion, a.soc-dailymotion.bg-icon:hover, .soc-dailymotion.color-icon.border-icon:hover {
	background-color: #004e72;
}

.color-bg-icon.soc-vine, a.soc-vine.bg-icon:hover, .soc-vine.color-icon.border-icon:hover {
	background-color: #00b389;
}

.color-bg-icon.soc-flickr, a.soc-flickr.bg-icon:hover, .soc-flickr.color-icon.border-icon:hover {
	background-color: #0062dd;
}

.color-bg-icon.soc-500px, a.soc-500px.bg-icon:hover, .soc-500px.color-icon.border-icon:hover {
	background-color: #58a9de;
}

.color-bg-icon.soc-instagram, a.soc-instagram.bg-icon:hover, .soc-instagram.color-icon.border-icon:hover {
	background-color: #9c7c6e;
}

.color-bg-icon.soc-wordpress, a.soc-wordpress.bg-icon:hover, .soc-wordpress.color-icon.border-icon:hover {
	background-color: #464646;
}

.color-bg-icon.soc-tumblr, a.soc-tumblr.bg-icon:hover, .soc-tumblr.color-icon.border-icon:hover {
	background-color: #45556c;
}

.color-bg-icon.soc-blogger, a.soc-blogger.bg-icon:hover, .soc-blogger.color-icon.border-icon:hover {
	background-color: #ec661c;
}

.color-bg-icon.soc-technorati, a.soc-technorati.bg-icon:hover, .soc-technorati.color-icon.border-icon:hover {
	background-color: #5cb030;
}

.color-bg-icon.soc-reddit, a.soc-reddit.bg-icon:hover, .soc-reddit.color-icon.border-icon:hover {
	background-color: #e74a1e;
}

.color-bg-icon.soc-dribbble, a.soc-dribbble.bg-icon:hover, .soc-dribbble.color-icon.border-icon:hover {
	background-color: #e84d88;
}

.color-bg-icon.soc-stumbleupon, a.soc-stumbleupon.bg-icon:hover, .soc-stumbleupon.color-icon.border-icon:hover {
	background-color: #e64011;
}

.color-bg-icon.soc-digg, a.soc-digg.bg-icon:hover, .soc-digg.color-icon.border-icon:hover {
	background-color: #1d1d1b;
}

.color-bg-icon.soc-envato, a.soc-envato.bg-icon:hover, .soc-envato.color-icon.border-icon:hover {
	background-color: #597c3a;
}

.color-bg-icon.soc-behance, a.soc-behance.bg-icon:hover, .soc-behance.color-icon.border-icon:hover {
	background-color: #007eff;
}

.color-bg-icon.soc-delicious, a.soc-delicious.bg-icon:hover, .soc-delicious.color-icon.border-icon:hover {
	background-color: #31a9ff;
}

.color-bg-icon.soc-deviantart, a.soc-deviantart.bg-icon:hover, .soc-deviantart.color-icon.border-icon:hover {
	background-color: #c5d200;
}

.color-bg-icon.soc-forrst, a.soc-forrst.bg-icon:hover, .soc-forrst.color-icon.border-icon:hover {
	background-color: #5b9a68;
}

.color-bg-icon.soc-play, a.soc-play.bg-icon:hover, .soc-play.color-icon.border-icon:hover {
	background-color: #5acdbd;
}

.color-bg-icon.soc-zerply, a.soc-zerply.bg-icon:hover, .soc-zerply.color-icon.border-icon:hover {
	background-color: #9dbc7a;
}

.color-bg-icon.soc-wikipedia, a.soc-wikipedia.bg-icon:hover, .soc-wikipedia.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.soc-apple, a.soc-apple.bg-icon:hover, .soc-apple.color-icon.border-icon:hover {
	background-color: #b9bfc1;
}

.color-bg-icon.soc-flattr, a.soc-flattr.bg-icon:hover, .soc-flattr.color-icon.border-icon:hover {
	background-color: #f67c1a;
}

.color-bg-icon.soc-github, a.soc-github.bg-icon:hover, .soc-github.color-icon.border-icon:hover {
	background-color: #221e1b;
}

.color-bg-icon.soc-chimein, a.soc-chimein.bg-icon:hover, .soc-chimein.color-icon.border-icon:hover {
	background-color: #888688;
}

.color-bg-icon.soc-friendfeed, a.soc-friendfeed.bg-icon:hover, .soc-friendfeed.color-icon.border-icon:hover {
	background-color: #2f72c4;
}

.color-bg-icon.soc-newsvine, a.soc-newsvine.bg-icon:hover, .soc-newsvine.color-icon.border-icon:hover {
	background-color: #075b2f;
}

.color-bg-icon.soc-identica, a.soc-identica.bg-icon:hover, .soc-identica.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.soc-bebo, a.soc-bebo.bg-icon:hover, .soc-bebo.color-icon.border-icon:hover {
	background-color: #ef1011;
}

.color-bg-icon.soc-zynga, a.soc-zynga.bg-icon:hover, .soc-zynga.color-icon.border-icon:hover {
	background-color: #dc0606;
}

.color-bg-icon.soc-steam, a.soc-steam.bg-icon:hover, .soc-steam.color-icon.border-icon:hover {
	background-color: #8f8d8a;
}

.color-bg-icon.soc-xbox, a.soc-xbox.bg-icon:hover, .soc-xbox.color-icon.border-icon:hover {
	background-color: #92c83e;
}

.color-bg-icon.soc-windows, a.soc-windows.bg-icon:hover, .soc-windows.color-icon.border-icon:hover {
	background-color: #00bdf6;
}

.color-bg-icon.soc-outlook, a.soc-outlook.bg-icon:hover, .soc-outlook.color-icon.border-icon:hover {
	background-color: #0072c6;
}

.color-bg-icon.soc-coderwall, a.soc-coderwall.bg-icon:hover, .soc-coderwall.color-icon.border-icon:hover {
	background-color: #3e8dcc;
}

.color-bg-icon.soc-tripadvisor, a.soc-tripadvisor.bg-icon:hover, .soc-tripadvisor.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.soc-appnet, a.soc-appnet.bg-icon:hover, .soc-appnet.color-icon.border-icon:hover {
	background-color: #494949;
}

.color-bg-icon.soc-goodreads, a.soc-goodreads.bg-icon:hover, .soc-goodreads.color-icon.border-icon:hover {
	background-color: #1abc9c;
}

.color-bg-icon.soc-tripit, a.soc-tripit.bg-icon:hover, .soc-tripit.color-icon.border-icon:hover {
	background-color: #1982c3;
}

.color-bg-icon.soc-lanyrd, a.soc-lanyrd.bg-icon:hover, .soc-lanyrd.color-icon.border-icon:hover {
	background-color: #3c80c9;
}

.color-bg-icon.soc-slideshare, a.soc-slideshare.bg-icon:hover, .soc-slideshare.color-icon.border-icon:hover {
	background-color: #4ba3a6;
}

.color-bg-icon.soc-buffer, a.soc-buffer.bg-icon:hover, .soc-buffer.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.soc-rss, a.soc-rss.bg-icon:hover, .soc-rss.color-icon.border-icon:hover {
	background-color: #f26109;
}

.color-bg-icon.soc-vkontakte, a.soc-vkontakte.bg-icon:hover, .soc-vkontakte.color-icon.border-icon:hover {
	background-color: #5a7fa6;
}

.color-bg-icon.soc-disqus, a.soc-disqus.bg-icon:hover, .soc-disqus.color-icon.border-icon:hover {
	background-color: #2e9fff;
}

.color-bg-icon.soc-houzz, a.soc-houzz.bg-icon:hover, .soc-houzz.color-icon.border-icon:hover {
	background-color: #7cc04b;
}

.color-bg-icon.soc-mail, a.soc-mail.bg-icon:hover, .soc-mail.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.soc-patreon, a.soc-patreon.bg-icon:hover, .soc-patreon.color-icon.border-icon:hover {
	background-color: #e44727;
}

.color-bg-icon.soc-paypal, a.soc-paypal.bg-icon:hover, .soc-paypal.color-icon.border-icon:hover {
	background-color: #009cde;
}

.color-bg-icon.soc-playstation, a.soc-playstation.bg-icon:hover, .soc-playstation.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.soc-smugmug, a.soc-smugmug.bg-icon:hover, .soc-smugmug.color-icon.border-icon:hover {
	background-color: #acfd32;
}

.color-bg-icon.soc-swarm, a.soc-swarm.bg-icon:hover, .soc-swarm.color-icon.border-icon:hover {
	background-color: #fc9d3c;
}

.color-bg-icon.soc-triplej, a.soc-triplej.bg-icon:hover, .soc-triplej.color-icon.border-icon:hover {
	background-color: #e53531;
}

.color-bg-icon.soc-yammer, a.soc-yammer.bg-icon:hover, .soc-yammer.color-icon.border-icon:hover {
	background-color: #1175c4;
}

.color-bg-icon.soc-stackoverflow, a.soc-stackoverflow.bg-icon:hover, .soc-stackoverflow.color-icon.border-icon:hover {
	background-color: #fd9827;
}

.color-bg-icon.soc-drupal, a.soc-drupal.bg-icon:hover, .soc-drupal.color-icon.border-icon:hover {
	background-color: #00598e;
}

.color-bg-icon.soc-odnoklassniki, a.soc-odnoklassniki.bg-icon:hover, .soc-odnoklassniki.color-icon.border-icon:hover {
	background-color: #f48420;
}

.color-bg-icon.soc-android, a.soc-android.bg-icon:hover, .soc-android.color-icon.border-icon:hover {
	background-color: #8ec047;
}

.color-bg-icon.soc-meetup, a.soc-meetup.bg-icon:hover, .soc-meetup.color-icon.border-icon:hover {
	background-color: #e2373c;
}

.color-bg-icon.soc-persona, a.soc-persona.bg-icon:hover, .soc-persona.color-icon.border-icon:hover {
	background-color: #e6753d;
}

.color-bg-icon.soc-amazon, a.soc-amazon.bg-icon:hover, .soc-amazon.color-icon.border-icon:hover {
	background-color: #ff9900;
}

.color-bg-icon.soc-ello, a.soc-ello.bg-icon:hover, .soc-ello.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.soc-mixcloud, a.soc-mixcloud.bg-icon:hover, .soc-mixcloud.color-icon.border-icon:hover {
	background-color: #314359;
}

.color-bg-icon.soc-8tracks, a.soc-8tracks.bg-icon:hover, .soc-8tracks.color-icon.border-icon:hover {
	background-color: #122c4b;
}

.color-bg-icon.soc-twitch, a.soc-twitch.bg-icon:hover, .soc-twitch.color-icon.border-icon:hover {
	background-color: #6441a5;
}

.color-bg-icon.soc-airbnb, a.soc-airbnb.bg-icon:hover, .soc-airbnb.color-icon.border-icon:hover {
	background-color: #ff5a5f;
}

.color-bg-icon.soc-pocket, a.soc-pocket.bg-icon:hover, .soc-pocket.color-icon.border-icon:hover {
	background-color: #ed4055;
}

a.social-icon {
	color: #787878;
}

a.border-icon {
	border: 2px solid rgba(0, 0, 0, 0.1);
	padding: 10px 12px;
	margin-bottom: 4px;
}

a.border-icon:before {
	font-size: 12px;
	line-height: 16px;
}

a.bg-icon {
	color: #ffffff;
	background-color: #787878;
	padding: 11px 13px;
	margin-bottom: 4px;
}

a.bg-icon:before {
	font-size: 12px;
	line-height: 16px;
}

a.social-icon.color-icon:hover {
	color: #444444;
}

a.social-icon.bg-icon:hover {
	color: #ffffff;
}

a.color-bg-icon {
	color: #ffffff;
	background-color: #444444;
	margin-bottom: 4px;
	padding: 11px 13px;
}

a.color-bg-icon:before {
	font-size: 12px;
	line-height: 16px;
}

a.social-icon.color-bg-icon:hover {
	background-color: #91d0cc;
	color: #ffffff;
}

a.color-icon:hover {
	color: #444444;
}

a.color-icon.border-icon:hover {
	color: #ffffff;
	background-color: #91d0cc;
	border-color: transparent;
}

.demo-icon {
	display: inline-block;
	text-align: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 15px 20px 30px;
	margin-bottom: 30px;
}

.demo-icon span {
	display: block;
}

.demo-icon .name {
	font-size: 1.01em;
	font-weight: 700;
}

.demo-icon a[class*="soc-"] {
	margin: 10px 0 0;
}

.demo-icon a[class*="soc-"]:before {
	font-size: 24px;
}
